<template>
  <q-dialog v-model="dialogState" position="bottom" maximized>
    <q-card class="mobileCardDialogue">
      <q-item-label class="mobileSortFilter">
        <h5 class="text-h5">Sort by</h5>
        <q-icon
          class="close cursor-pointer"
          name="close"
          @click="
            changeDialogState({
              dialog: 'categorySortDialog',
              val: false,
            })
          "
        />
      </q-item-label>
      <q-option-group
        class="q-pa-md"
        :options="sortOptionsMob"
        type="radio"
        v-model="sortGroup"
      />
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'CategorySortBy',
  data() {
    return {
      sortGroup: null,
      sortOptionsMob: [
        { label: 'Featured', value: 'featured' },
        { label: 'New', value: 'new' },
        { label: 'Best Seller', value: 'bestseller' },
      ],
    }
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState('categorySortDialog')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'categorySortDialog',
          val,
        })
      },
    },
  },
}
</script>
