<template>
  <div class="page-content" v-if="productsBy">
    <template v-if="isMobile">
      <FilterButtons
        :showFilterBtn="Object.keys(filters).length > 0"
        :filterBtnClick="
          () => {
            this.changeDialogState({
              dialog: 'categoryFilterSidebar',
              val: true,
            })
          }
        "
        :showSortBtn="Object.keys(products).length > 0"
        :sortBtnClick="
          () => {
            this.changeDialogState({
              dialog: 'priceSortDialog',
              val: true,
              properties: { productsBy },
            })
          }
        "
        :showPagination="loading || Object.keys(products).length > 0"
        :filterAplliedCount="selectedFilters.length"
        @product-list-pagination="fetchNext"
        :showDiscountButton="productsBy === 'DT'"
      />
      <MobileFilterSidebar
        v-if="Object.keys(filters).length"
        @filter-modified="filterModified"
        :searchOptions="searchOptions"
        :filterInit="filters"
        :filterOptions="filteredOptions"
        :priceRange="priceRange"
        @hook:mounted="filterBarMounted"
      />
      <CategorySortBy />
      <PriceSortBy @sorby-modified="(val) => $emit('sorby-modified', val)" />
      <ExploreDiscount />
    </template>
    <template v-else>
      <FilterBar
        @filter-modified="filterModified"
        @hook:mounted="filterBarMounted"
        :category="category"
        :filterOptions="filteredOptions"
        :searchOptions="searchOptions"
        :filterInit="filters"
        :priceRange="priceRange"
        :blnShowCategoryTree="blnShowCategoryTree && !!categoryId"
        :showDiscounts="showDiscounts"
        :discount="discount"
        :loadingFilters="loadingFilters"
        :productsBy="productsBy"
      />
    </template>

    <div class="sidebarRight" ref="filterSidebar">
      <template
        v-if="
          productsBy === 'DT' &&
          [1, 3, 4].includes(discount.useTriggerList) === true &&
          discount?.triggerQty
        "
      >
        <div class="flex q-py-xs q-mb-sm">
          <div class="q-px-md r-4 di-inner">
            <div class="w100p" v-if="discount.descriptionText">
              {{ discount.descriptionText }}
            </div>

            <div class="w100p flex">
              <div class="di-content">
                To receive this discount, you must purchase at least
                {{ discount.triggerQty }} items from the list
              </div>
              <q-btn
                unelevated
                no-caps
                flat
                color="secondary"
                padding="0"
                class="no-hover text-underline text-weight-bold"
                @click="
                  changeDialogState({
                    dialog: 'TriggerListDialog',
                    val: true,
                    properties: {
                      discount,
                      applyCoupon: false,
                      addToCartButton: '',
                    },
                  })
                "
              >
                Items List
              </q-btn>
              <div
                class="di-content-left"
                v-if="discount?.useTriggerList == 3 && discount?.couponCode"
              >
                and apply the coupon [<q-btn
                  flat
                  color="secondary"
                  padding="0"
                  class="no-hover text-underline text-weight-bold"
                  >{{ discount?.couponCode }}</q-btn
                >
                ]<q-btn
                  unelevated
                  no-caps
                  size="sm"
                  color="secondaryOnBody"
                  class="no-hover text-weight-bold q-ml-sm"
                  @click="applyDiscountCoupons"
                  >Apply Coupon</q-btn
                ></div
              >
              <div
                class="di-content-left"
                v-if="discount?.useTriggerList == 4 && discount?.couponCode"
              >
                or apply the coupon [<q-btn
                  flat
                  color="secondary"
                  padding="0"
                  class="no-hover text-underline text-weight-bold"
                  >{{ discount?.couponCode }}</q-btn
                >]<q-btn
                  unelevated
                  no-caps
                  size="sm"
                  color="secondaryOnBody"
                  class="no-hover text-weight-bold q-ml-sm"
                  @click="applyDiscountCoupons"
                  >Apply Coupon</q-btn
                ></div
              >
            </div>
          </div>
        </div>
      </template>
      <template v-if="productsBy === 'DT' && discount.useTriggerList === 2">
        <div class="flex q-py-xs q-mb-sm">
          <div class="q-px-md r-4 di-inner">
            <div class="w100p" v-if="discount.descriptionText">
              {{ discount.descriptionText }}
            </div>
            <div class="di-content">
              To receive the discount, click apply coupon
              <q-btn
                unelevated
                no-caps
                size="sm"
                color="secondaryOnBody"
                class="no-hover text-weight-bold q-ml-sm"
                @click="applyDiscountCoupons"
                >Apply Coupon</q-btn
              >
            </div>
          </div>
        </div>
      </template>
      <template
        v-if="
          productsBy === 'DT' &&
          [1, 2, 3, 4].includes(discount.useTriggerList) === false &&
          discount?.triggerQty <= 0 &&
          discount.descriptionText
        "
      >
        <div class="flex q-py-xs q-mb-sm">
          <div class="q-px-md r-4 di-inner">
            <div class="di-content">
              {{ discount.descriptionText }}
            </div>
          </div>
        </div>
      </template>

      <q-infinite-scroll
        debounce="300"
        ref="infiniteScroll"
        class="product-items see-all-product"
        @load="fetchNext"
        :offset="scrollOffset"
      >
        <!-- <div class="product-items see-all-product" ref="productListing"> -->
        <ProductCard
          v-for="(product, key) in products"
          :key="key"
          :product="product"
          :class="{ 'list-view': isListView }"
          :isListView="isListView"
        />
        <!-- </div> -->
        <template v-slot:loading>
          <LoadMoreSpinner v-show="loading && !showSkeletonLoader" />
          <ProductSkeleton
            :isListView="isListView"
            v-show="showSkeletonLoader"
            :size="20"
          />
        </template>
      </q-infinite-scroll>

      <EmptyComponent
        v-if="!loading && Object.keys(products).length == 0"
        :image="`images/empty-${productsBy == 'SK' ? 'search' : 'product'}.png`"
        btnText="Go To Home"
        to="/"
      >
        <p>No products found</p>
      </EmptyComponent>
    </div>
  </div>
</template>

<script>
import MobileFilterSidebar from 'components/category/MobileFilterSidebar.vue'
import CategorySortBy from 'components/category/CategorySortBy'
import PriceSortBy from 'components/common/dialog/PriceSortBy'
import ProductCard from 'components/ProductCard'
import { mapGetters } from 'vuex'
import { convertArrayToObject, scrollToElement } from 'src/utils/index'
import FilterBar from './FilterBar'
import ExploreDiscount from './ExploreDiscount'
import { hideShowFullSpinner } from 'src/utils'

export default {
  name: 'Listing',
  props: {
    collectionId: {
      type: String,
      default: '',
    },
    discountId: {
      type: String,
      default: '',
    },
    category: {
      type: Object,
      default: () => {
        return {}
      },
    },
    discount: {
      type: Object,
      default: () => {
        return {}
      },
    },
    searchKeyword: {
      type: [Array, String],
      default: '',
    },
    blnShowCategoryTree: {
      type: Boolean,
      default: false,
    },
    showDiscounts: {
      type: Boolean,
      default: false,
    },
    productsBy: {
      type: String,
      default: '',
    },
    sortBy: {
      type: String,
      default: '',
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    isListView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MobileFilterSidebar,
    ProductCard,
    CategorySortBy,
    PriceSortBy,
    FilterBar,
    ExploreDiscount,
    ProductSkeleton: () => import('components/category/ProductSkeleton'),
    FilterButtons: () => import('components/common/FilterButtons'),
  },
  data() {
    return {
      timer: null,
      products: {},
      loading: true,
      showSkeletonLoader: false,
      mounted: false,
      infiniteScrollInitialHeight: 0,
      scrollOffset: 2000,
      filterOptions: {},
      priceRange: {
        min: 0,
        max: 0,
      },
      searchOptions: {},
      filters: {},
      ModifiedFilterCodes: ['PRIC'],
      created: false,
      // filtersLoaded: false,
    }
  },
  computed: {
    selectedFilters: {
      get() {
        let selectedFilters = []

        for (let key in this.filters) {
          if (
            (!this.ModifiedFilterCodes.includes(key) &&
              this.filters[key].length) ||
            (key == 'PRIC' &&
              (this.filters.PRIC.min != this.priceRange.min ||
                this.filters.PRIC.max != this.priceRange.max))
          ) {
            selectedFilters.push({
              label: this.filters[key].filterName,
              value: key,
            })
          }
        }

        return selectedFilters
      },
    },
    infiniteScrollOffset() {
      return this.infiniteScrollInitialHeight + this.scrollOffset
    },
    categoryId() {
      return (this.category && this.category.categoryID) || ''
    },
    currentPage: {
      get() {
        return this.pagination.currentPage
      },
      set(val) {
        if (val != this.currentPage)
          this.$store.commit('product/UPDATE_PAGINATION', {
            ...this.pagination,
            currentPage: val,
          })
      },
    },
    objRequest() {
      let params = {
        PageSize: this.pagination.pageSize,
        // DisablePaging: this.pagination.pageSize == 'All',
        locationID: this.currentLocationId,
        Sorts: this.sortBy,
        productsBy: this.productsBy,
        ...this.currentDeliveryModes,
      }

      if (this.collectionId !== '') {
        params.collectionID = this.collectionId
      }

      if (this.discountId !== '') {
        params.discountId = this.discountId
      }

      if (this.searchKeyword.constructor === Array)
        params.searchKeyword = this.searchKeyword.join(',')
      else if (this.searchKeyword !== '')
        params.searchKeyword = this.searchKeyword

      if (this.categoryId) {
        params.categoryID = this.categoryId
      }

      let filter = Object.assign({}, this.filters)

      if (filter.hasOwnProperty('PRIC')) {
        if (filter.PRIC.min >= 0) params.minPrice = filter.PRIC.min
        if (filter.PRIC.max >= 0) params.maxPrice = filter.PRIC.max
        delete filter.PRIC
      }

      let count = 0
      for (let key in filter) {
        if (filter[key].length) {
          params[`ProductFilters[${count}]`] =
            key + '@=' + filter[key].toString()
          count++
        }
      }

      return params
    },
    filteredOptions() {
      let filters = JSON.parse(JSON.stringify(this.filterOptions))
      for (let key in filters) {
        if (filters[key].filterValues)
          filters[key].filterValues = filters[key].filterValues.filter(
            (item) => item.filterValue && item.filterValue.length > 0
          )
      }

      return filters
    },
    ...mapGetters('category', ['loadingFilters']),
    ...mapGetters('common', ['currentDeliveryModes']),
    ...mapGetters('product', ['pagination']),
  },

  methods: {
    loadFilters() {
      // return new Promise(async (resolve, reject) => {

      if (this.productsBy !== 'DT') {
        let params = {
          locationID: this.currentLocationId,
          filterBy: this.productsBy,
          ...this.currentDeliveryModes,
        }

        if (this.collectionId !== '') {
          params.collectionID = this.collectionId
        }

        if (this.discountId !== '') {
          params.discountId = this.discountId
        }

        if (typeof this.searchKeyword === 'object')
          params.searchKeyword = this.searchKeyword.join(',')
        else if (this.searchKeyword !== '')
          params.searchKeyword = this.searchKeyword

        if (this.categoryId) {
          params.categoryIds = this.categoryId
        }

        this.$store.dispatch('category/getFilters', params).then((data) => {
          this.filterOptions = data

          let filter = {},
            search = {}

          for (let key in data) {
            if (!this.ModifiedFilterCodes.includes(key)) {
              filter[key] = []
            }
            search[key] = ''
          }

          if (data && data.PRIC) {
            let range = []

            for (let price of data.PRIC.filterValues) {
              range.push(price.filterValue)
            }

            range = range.sort(function (element1, element2) {
              return element1 - element2
            })

            if (range.length) {
              this.priceRange = {
                min: Math.floor(range[0]),
                max: Math.ceil(range[1]),
              }
              filter['PRIC'] = {
                ...this.priceRange,
              }
            }
          }

          this.filters = Object.assign({}, filter)
          this.searchOptions = Object.assign({}, search)
        })
      }
      // this.created = true

      /* this.created = true
        if (!Object.keys(this.filters).length) {
          this.mounted = true
        } */

      this.$store.commit('product/UPDATE_PAGINATION', {
        ...this.pagination,
        pageSize: this.pageSize,
      })
      // this.refreshListing()

      //   resolve()
      // })
    },
    async fetchNext(index, done = null) {
      if (index == 1) {
        this.showSkeletonLoader = true
        this.products = {}

        // if (!this.filtersLoaded) {
        //   await this.loadFilters()
        //   this.filtersLoaded = true
        // }
      }

      let params = {
        Page: index,
        ...this.objRequest,
      }

      this.loading = true

      this.$store
        .dispatch('product/getProducts', params)
        .then((data) => {
          if (!data) return
          this.$store.commit('product/UPDATE_PAGINATION', {
            ...this.pagination,
            totalCount: data.count,
            currentPage: this.currentPage,
          })
          if (data.items.length) {
            let items = Object.assign(
              {},
              convertArrayToObject(data.items, 'productID')
            )
            if (index > 1) {
              this.products = Object.assign({}, this.products, items)
            } else {
              this.products = Object.assign({}, items)
            }
            /* setTimeout(() => {
              if (this.$refs.productListing)
                scrollToElement(this.$refs.productListing)
            }, 1000) */
          }
          if (done) done(!data.hasNext)

          this.loading = false
          this.showSkeletonLoader = false
        })
        .catch((error) => {
          console.log('error', error)
          if (done) done(true)
        })
    },
    refreshListing() {
      // this.currentPage = 1
      // this.fetchNext(1)
      if (this.$refs && this.$refs.infiniteScroll) {
        this.$refs.infiniteScroll.stop()
        this.$refs.infiniteScroll.reset()
        this.$refs.infiniteScroll.resume()
        this.$refs.infiniteScroll.trigger()
      }
    },
    filterModified(filters, modifiedKey) {
      this.filters = Object.assign({}, filters)
      this.refreshListing()
    },
    filterBarMounted() {
      this.infiniteScrollInitialHeight = this.$refs.filterSidebar.clientHeight
      // this.mounted = true
      // this.fetchNext(1)
    },
    async applyDiscountCoupons() {
      if (this.discount?.couponCode) {
        try {
          hideShowFullSpinner(true)
          let requestObj = {
            discountCouponCode: this.discount?.couponCode,
          }
          let guestCartNumber = this.$store.getters['guest/guestCartNumber']
          if (!this.isLoggedIn && guestCartNumber)
            requestObj.OrderNumber = guestCartNumber
          let res = await this.$store.dispatch(
            'cart/applyDiscountCoupons',
            requestObj
          )
          if (
            res.success &&
            res.data.errorMessage &&
            res.data.errorMessage.length
          ) {
            this.showMultipleCouponsError(
              this.formatErrorMessage(res.data.errorMessage)
            )
          } else if (
            res.success &&
            res.data.errorMessage &&
            !res.data.errorMessage.length
          ) {
            this.ftxConfirmCoupon(
              '',
              'Yay! Coupon is applied',
              'Cancel',
              'Ok',
              true,
              false,
              '',
              '',
              false,
              true,
              false,
              false
            )
          } else if (!res.success && res.message) {
            if (res.errors[0].errorCode === 'TRIGGERLIST_ITEM')
              this.ftxConfirmCoupon(
                '',
                'To receive this discount, you must purchase at least ' +
                  this.discount?.triggerQty +
                  ' item from the list',
                'Cancel',
                'Ok',
                true,
                false,
                'info-filled',
                '3em',
                true,
                false,
                true,
                true
              ).onOk(() => {
                this.changeDialogState({
                  dialog: 'TriggerListDialog',
                  val: true,
                  properties: {
                    discount: this.discount,
                    applyCoupon: true,
                    addToCartButton: '',
                  },
                })
              })
            else
              this.ftxConfirmCoupon(
                '',
                res.message,
                'Cancel',
                'Ok',
                true,
                false,
                'info-filled',
                '3em',
                true,
                false,
                false,
                true
              )
          }
          hideShowFullSpinner(false)
        } catch (error) {
          console.log(error)
          if (error && error.message) this.showError(error.message)
        }
      }
    },
  },
  watch: {
    sortBy: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.refreshListing()
      }
    },
    pagination: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal.pageSize != oldVal.pageSize) this.refreshListing()
      },
    },
  },
  created() {
    this.loadFilters()
  },
  /* async created() {
    if (this.productsBy !== 'DT') {
      let params = {
        locationID: this.currentLocationId,
        filterBy: this.productsBy,
        ...this.currentDeliveryModes,
      }
      if (this.collectionId !== '') {
        params.collectionID = this.collectionId
      }
      if (this.discountId !== '') {
        params.discountId = this.discountId
      }
      if (typeof this.searchKeyword === 'object')
        params.searchKeyword = this.searchKeyword.join(',')
      else if (this.searchKeyword !== '')
        params.searchKeyword = this.searchKeyword
      if (this.categoryId) {
        params.categoryIds = this.categoryId
      }
      let data = await this.$store.dispatch('category/getFilters', params)
      this.filterOptions = data
      let filter = {},
        search = {}
      for (let key in data) {
        if (!this.ModifiedFilterCodes.includes(key)) {
          filter[key] = []
        }
        search[key] = ''
      }
      if (data && data.PRIC) {
        let range = []
        for (let price of data.PRIC.filterValues) {
          range.push(price.filterValue)
        }
        range = range.sort(function (element1, element2) {
          return element1 - element2
        })
        if (range.length) {
          this.priceRange = {
            min: Math.floor(range[0]),
            max: Math.ceil(range[1]),
          }
          filter['PRIC'] = {
            ...this.priceRange,
          }
        }
      }
      this.filters = Object.assign({}, filter)
      this.searchOptions = Object.assign({}, search)
    }
    this.created = true
    if (!Object.keys(this.filters).length) {
      this.mounted = true
    }
    this.$store.commit('product/UPDATE_PAGINATION', {
      ...this.pagination,
      pageSize: this.pageSize,
    })
    // this.refreshListing()
  }, */
}
</script>

<style lang="scss"></style>
