<template>
  <div>
    <q-dialog v-model="dialogState" position="right" maximized>
      <q-card
        class="mobileSidebarFilter"
        style="height: 100%; width: calc(100vw)"
      >
        <div class="mobfilterBar">
          <q-item-label class="text-h5">
            <q-btn flat dense @click="closeFilter()">
              <SvgIcon
                color="white"
                className="q-mr-xs"
                size="1rem"
                icon="back-arrow-line"
              />
            </q-btn>
            Filters
            <a
              class="btn-clear"
              href="javascript:void(0)"
              :class="{ invisible: !this.selectedFilters.length }"
              @click="clearFilter"
            >
              Clear Filter
            </a>
            <!-- <a
              class="btn-clear"
              href="javascript:void(0)"
              :class="{ invisible: !this.selectedFilters.length }"
              @click="
                () => {
                  for (let key in this.selectedFilters) {
                    if (
                      !this.activeFilters.includes(
                        this.selectedFilters[key].value
                      )
                    ) {
                      this.activeFilters.push(this.selectedFilters[key].value)
                    }
                  }
                  this.clearDialog = true
                }
              "
            >
              Clear Filter
            </a> -->
          </q-item-label>
          <q-splitter v-model="splitterModel"
            ><!-- style="height: calc(100vh - 125px)" -->
            <template v-slot:before>
              <q-tabs v-model="tab" vertical class="bg-grey-2">
                <q-tab
                  v-for="(filter, key) of filterOptions"
                  :key="key"
                  :name="key"
                  :label="filter.filterName"
                />
              </q-tabs>
            </template>

            <template v-slot:after>
              <q-tab-panels
                v-model="tab"
                animated
                swipeable
                vertical
                transition-prev="jump-up"
                transition-next="jump-up"
              >
                <q-tab-panel
                  v-for="(filter, key) of filterOptions"
                  :key="key"
                  :name="key"
                >
                  <template v-if="!ModifiedFilterCodes.includes(key)">
                    <q-input
                      dense
                      :ref="key + 'Search'"
                      filled
                      v-model="search[key]"
                      class="q-mb-sm q-mx-md"
                      :placeholder="'Search ' + filter.filterName"
                    >
                      <template v-slot:append>
                        <q-icon
                          v-if="search[key] !== ''"
                          name="clear"
                          class="cursor-pointer"
                          @click="resetFilter(key)"
                        />
                      </template>
                    </q-input>
                    <q-scroll-area
                      :thumb-style="thumbStyle"
                      :bar-style="barStyle"
                      style="height: 250px; max-width: 300px"
                      class="q-mb-md q-mx-md"
                    >
                      <q-tree
                        :no-results-label="`No ${filter.filterName} available matching &quot;${search[key]}&quot;`"
                        :no-nodes-label="`No ${filter.filterName} available.`"
                        :nodes="filter.filterValues"
                        label-key="filterValue"
                        node-key="filterValue"
                        tick-strategy="strict"
                        :ticked.sync="filters[key]"
                        accordion
                        :filter="search[key]"
                        :selected.sync="selected[key]"
                        @update:selected="selectedFilter(key)"
                        :ref="'Tree' + key"
                      />
                    </q-scroll-area>
                  </template>
                  <template v-else-if="isPriceFilterAvailable(key)">
                    <q-range
                      v-model="filters[key]"
                      :min="priceRange.min"
                      :max="priceRange.max"
                      label-always
                    />
                  </template>
                </q-tab-panel>
              </q-tab-panels>
            </template>
          </q-splitter>
          <div class="filterAction">
            <q-btn
              unelevated
              @click="closeFilter()"
              outline
              color="primary"
              label="Cancel"
            />
            <q-btn
              unelevated
              color="primary"
              @click="applyFilter()"
              label="Apply"
              class=""
            />
          </div>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="clearDialog" position="bottom">
      <q-card>
        <q-card-section>
          <div class="text-h6">Clear Filters</div>
        </q-card-section>

        <q-separator></q-separator>

        <q-card-section style="max-height: 50vh" class="scroll">
          <p> Would you like to clear following filters? </p>
          <q-option-group
            :options="selectedFilters"
            type="checkbox"
            v-model="activeFilters"
          />
        </q-card-section>

        <q-separator></q-separator>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup></q-btn>
          <q-btn
            flat
            label="Clear"
            color="primary"
            @click="clearSelectedFilters()"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  props: {
    priceRange: {
      type: Object,
      default: () => {
        return {}
      },
    },
    filterOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
    filterInit: {
      type: Object,
      default: () => {
        return {}
      },
    },
    searchOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      filters: Object.assign({}, this.filterInit),
      ModifiedFilterCodes: ['PRIC'],
      tab: '',
      selected: { ...this.searchOptions },
      search: { ...this.searchOptions },
      firstTab: '',
      clearDialog: false,
      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#027be3',
        width: '5px',
        opacity: 0.75,
      },
      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#027be3',
        width: '9px',
        opacity: 0.2,
      },
      activeFilters: [],

      splitterModel: 35,
      priceSlider: {
        min: 10,
        max: 35,
      },
    }
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState('categoryFilterSidebar')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'categoryFilterSidebar',
          val,
        })
      },
    },
    selectedFilters: {
      get() {
        let selectedFilters = []

        for (let key in this.filterOptions) {
          if (
            (!this.ModifiedFilterCodes.includes(key) &&
              this.filters[key].length) ||
            (key == 'PRIC' &&
              (this.filters.PRIC.min != this.priceRange.min ||
                this.filters.PRIC.max != this.priceRange.max))
          ) {
            selectedFilters.push({
              label: this.filterOptions[key].filterName,
              value: key,
            })
          }
        }

        return selectedFilters
      },
    },
  },
  methods: {
    clearFilter() {
      for (let key in this.selectedFilters) {
        if (!this.activeFilters.includes(this.selectedFilters[key].value)) {
          this.activeFilters.push(this.selectedFilters[key].value)
        }
      }
      this.clearDialog = true
    },
    resetFilter(key) {
      let focusElement = key + 'Search'

      this.search[key] = ''
      this.$refs[focusElement][0].focus()
    },
    closeFilter() {
      this.filters = Object.assign({}, this.filterInit)
      this.changeDialogState({
        dialog: 'categoryFilterSidebar',
        val: false,
      })
      this.tab = this.firstTab
    },
    applyFilter(key) {
      this.tab = this.firstTab
      this.$emit('filter-modified', this.filters, key)
      this.changeDialogState({
        dialog: 'categoryFilterSidebar',
        val: false,
      })
    },
    selectedFilter(key) {
      let refElement = 'Tree' + key
      let isTicked = this.$refs[refElement][0].isTicked(this.selected[key])
      this.$refs[refElement][0].setTicked(this.selected[key], !isTicked)
      this.selected[key] = ''
    },
    clearSelectedFilters() {
      for (let key of this.activeFilters) {
        if (!this.ModifiedFilterCodes.includes(key)) {
          this.filters[key] = []
        } else if (key == 'PRIC') {
          this.filters[key].min = this.priceRange.min
          this.filters[key].max = this.priceRange.max
        }
      }
    },
    isPriceFilterAvailable(key) {
      return (
        key == 'PRIC' &&
        this.filters[key] &&
        this.filters[key]?.hasOwnProperty('min') &&
        this.filters[key]?.hasOwnProperty('max') &&
        this.filters[key].min >= 0 &&
        this.filters[key].max >= this.filters[key].min
      )
    },
  },
  created() {
    for (let key in this.filters) {
      this.firstTab = key
      this.tab = key
      break
    }
  },
}
</script>
<style lang="scss">
.mobileSidebarFilter {
  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    width: 70px;
    height: 70px;
    font-size: 1.5rem;
    color: #fff;
  }
  .mobfilterBar {
    display: flex;
    flex-direction: column;
    height: 100%;
    > div {
      flex: 1 0 0;
      overflow: hidden;
    }
    .text-h5 {
      flex: 0 0 60px;
    }
    .filterAction {
      flex: 0 0 70px;
    }
  }
  .text-h5 {
    min-height: 60px;
    margin: 0;
    padding: 0 20px;
    line-height: 60px !important;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    background: $primary;
    background: var(--q-color-primary) !important;
    color: #fff;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  }
  .btn-clear {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    float: right;
  }
  .q-tab {
    &.q-tab--active {
      background: #fff;
      color: $primary;
      color: var(--q-color-primary) !important;
    }
    @media (max-width: 374px) {
      .q-tab__label {
        font-size: 11px;
      }
    }
  }
  .q-tab-panel {
    .q-mx-md {
      margin-left: 0;
      margin-right: 0;
    }
    .q-slider {
      width: 80%;
      margin: 35px auto;
    }
    .q-tree {
      .q-checkbox {
        margin-right: 10px;
      }
      .q-tree__node-header {
        padding: 8px;
      }
    }
    .q-field__control {
      background: #fff;
      border: 1px solid #e2e2e2;
      border-radius: 4px;
    }
    .q-field--filled .q-field__control:before,
    .q-field--filled .q-field__control:after {
      opacity: 0 !important;
    }
  }
  .q-scrollarea {
    height: calc(100vh - 240px) !important;
  }
}
.filterAction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 13px 15px;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  .q-btn {
    width: 48%;
  }
}
</style>
