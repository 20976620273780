<template>
  <div class="sectionRight">
    <q-dialog v-if="isMobile" v-model="showSummary" position="bottom" maximized>
      <OrderSummaryContent
        :toggleOrderSummary="toggleOrderSummary"
        :checkout="checkout"
        :onDialog="true"
        v-bind="$props"
      />
    </q-dialog>
    <OrderSummaryContent
      :onDialog="true"
      :toggleOrderSummary="toggleOrderSummary"
      :checkout="checkout"
      v-bind="$props"
    />
  </div>
</template>
<script>
import OrderSummaryContent from './OrderSummaryContent';
export default {
  name: 'OrderSummary',
  components: { OrderSummaryContent },
  props: {
    currentCartDetails: {
      type: Object,
      default: null,
    },
    showEmptyCart: {
      type: Boolean,
      default: false,
    },

    emptyCartAction: {
      type: Function,
      default: () => {},
    },
    proceedCheckout: {
      type: Function,
      default: () => {},
    },
    checkMinCheckoutAmount: {
      type: Function,
      default: null,
    },
    currentCardId: {
      type: String,
      default: '',
    },
    checkoutBtnName: {
      type: String,
      default: 'Proceed to Checkout',
    },
    cancelCheckoutBtnName: {
      type: String,
      default: 'Continue Shopping',
    },
    cancelCheckoutBtnUrl: {
      type: String,
      default: 'Home',
    },
    showAdditionalText: {
      type: Boolean,
      default: false,
    },
    disableCheckout: {
      type: Boolean,
      default: false,
    },
    placingOrder: {
      type: Boolean,
      default: false,
    },
    showLnsOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSummary: false,
    }
  },
  methods: {
    toggleOrderSummary(value) {
      this.showSummary = value || !this.showSummary
    },
    checkout() {
      if (this.isMobile && this.showSummary) {
        if (
          typeof this.checkMinCheckoutAmount == 'function' &&
          !this.checkMinCheckoutAmount()
        )
          this.toggleOrderSummary(false)
        else if (!this.checkMinCheckoutAmount) this.toggleOrderSummary(false)
      }
      this.proceedCheckout()
    },
  },
}
</script>
<style lang="scss">
.sectionRight {
  width: 360px;
  padding: 0 15px;
  position: relative;
  .oc-xs,
  .oc-total {
    display: none;
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    border-radius: 10px 10px 0 0;
    .orderSummary {
      margin: 30px 0 15px;
      box-shadow: none;
      h3 {
        display: none;
      }
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      .oc-xs {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        padding: 5px;
        position: absolute;
        top: 0px;
        left: 50%;
        margin-left: -20px;
        z-index: 1;

        &.r-ar{
          text-align: center;
        }
      }
      .orderSummaryInner {
        padding: 0;
      }
      .orderSummaryPromocode,
      .orderSummaryTotal,
      .orderSummaryDiscount {
        display: none;
        padding: 0 15px;
      }
      .orderSummaryAction {
        p {
          display: none;
        }
        .form-action {
          display: block;
          width: calc(35% - 30px);
          margin: 0 15px;
          float: left;
          padding-bottom: 0;
          & + .form-action {
            width: calc(65% - 30px);
          }
          &.continue {
            display: none;
          }
          .total {
            font-size: 22px;
            font-weight: 600;
            color: $tertiary;
            text-align: left;
            span {
              color: #888888;
              display: block;
              font-size: 12px;
            }
          }
          .q-btn__wrapper {
            padding: 0 10px;
            min-height: 44px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .q-dialog {
    .orderSummary {
      margin: 0;
      box-shadow: none;
      border-radius: 15px 15px 0 0 !important;
      max-height: calc(100dvh - 48px);
      .orderSummaryInner {
        padding: 25px 15px 15px;
      }
      h3 {
        display: none;
      }
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      .oc-xs {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 10px;
        position: absolute;
        left: 50%;
        margin-left: -20px;
        z-index: 1;

        &.r-ar{
          text-align: center;
          img {
            transform: rotate(180deg);
          }
        }
      }
      .oc-total {
        display: none;
      }
      .form-action.continue,
      .orderSummaryPromocode,
      .orderSummaryTotal,
      .orderSummaryAction p {
        display: block;
      }
      .orderSummaryTotal {
        padding-bottom: 20px;
      }
      .orderSummaryAction {
        margin-left: -5px;
        margin-right: -5px;
      }
      .form-action {
        float: right;
        margin: 0 5px;
        & + .form-action {
          width: calc(50% - 10px);
        }
        .q-btn-lg .q-btn__wrapper {
          padding: 0 10px;
          min-height: 44px;
          font-size: 15px;
        }
      }
      .orderSummaryAction p {
        clear: both;
        a {
          padding: 8px;
        }
      }
    }
  }
}

@media (max-width: 374px) {
  .q-dialog .orderSummary {
    .form-action .q-btn-lg .q-btn__wrapper {
      font-size: 13px;
    }
  }
}

.orderSummary {
  margin: 20px 0;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @media (min-width: 1024px) {
    position: sticky;
    top: 10px;
  }
  h3 {
    margin: 0;
    padding: 10px 0;
    background: #314154;
    color: #fff;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    border-radius: 10px 10px 0 0;
    @media (min-width: 1681px) {
      font-size: 20px;
    }
  }
  .orderSummaryInner {
    padding: 20px;
  }
  .orderSummaryTotal {
    padding-bottom: 15px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding: 6px 0;
        font-size: 16px;
        font-weight: normal;
        color: #0f141a;
        position: relative;
        text-align: right;
        @media (max-width: 1023px) {
          min-height: 34px;
          line-height: 1;
          font-size: 14px;
        }

        > span {
          font-weight: normal;
          width: 200px;
          position: absolute;
          left: 0;
          top: 6px;
          text-align: left;
          color: #333;
        }
        &.total {
          // margin-top: 8px;
          padding: 15px 0 5px;
          // border-top: 1px solid rgba(112, 112, 112, 0.25);
          font-size: 18px;
          font-weight: 700;
          color: #314154;
          span {
            top: 15px;
            font-weight: 700;
          }
        }
        &.tax {
          padding-bottom: 15px;
        }
      }
    }
  }
  .orderSummaryAction {
    p {
      color: #888888;
      font-size: 14px;
      a {
        font-size: 16px;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  .orderSummaryDiscount {
    margin-bottom: 20px;
    background: #fafff5;
    border: 1px solid #b3e59e;
    .q-item {
      min-height: 64px;
      .q-item__section--avatar {
        min-width: 45px;
        padding-right: 10px;
      }
      .q-item__section--main {
        font-size: 16px;
        color: #0f141a;
        span {
          font-size: 10px;
          display: block;
        }
      }
      .q-item__section--side {
        font-size: 18px;
        font-weight: bold;
        color: #488f2a;
      }
      .q-focusable {
        display: none;
      }
    }
    .q-card {
      background: #fafff5;
      .q-card__section {
        padding: 0 20px;
      }
      ul {
        border-top: 1px solid #b3e59e;
        margin: 0;
        padding: 10px;
        list-style: none;
        li {
          padding: 5px 0;
          font-size: 12px;
          font-weight: bold;
          color: #333333;
          position: relative;
          text-align: right;
          span {
            width: 140px;
            position: absolute;
            left: 0;
            top: 6px;
            text-align: left;
          }
        }
      }
    }
  }
}
.orderSummaryPromocode {
  padding-bottom: 20px;
  // margin-bottom: 10px;
  h4 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: #0f141a;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .promocodeForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .q-field {
      width: 65%;
    }
    .q-btn {
      width: 30%;
      height: 34px;
    }
  }
}
</style>
