<template>
  <div class="orderSummary">
    <a
      href="javascript:void(0);"
      class="oc-xs r-ar"
      v-if="isMobile"
      @click="toggleOrderSummary()"
    >
      <img src="images/arrow-up.gif" />
    </a>
    <h3>Order Summary</h3>
    <div class="orderSummaryInner" v-if="currentCartDetails.orderSummary">
      <div class="orderSummaryTotal">
        <ul>
          <!-- <li>
            <span>Cart Total</span>
            {{ currentCartDetails.orderSummary.subTotal | currency }}
          </li> -->
          <li
            v-for="(fulfillment, index) in currentCartDetails.fulfillments"
            :key="`${fulfillment.fulfillmentMethodID}-${index}`"
          >
            <span>
              <!-- <label> {{ fulfillment.displayName }}</label> Total -->
              Sub Total
            </span>
            {{ fulfillment.subTotal | currency }}
          </li>

          <template
            v-if="
              showTip ||
              (showTipViewMode && currentCartDetails.orderSummary.tipAmount > 0)
            "
          >
            <li>
              <span> Tip </span>
              <template v-if="currentCartDetails.orderSummary.tipAmount">
                <q-btn
                  unelevated
                  flat
                  dense
                  round
                  size="sm"
                  color="red"
                  class="no-hover"
                  @click="removeTip"
                  v-if="showTip"
                >
                  <SvgIcon size="1rem" icon="close-circle" />
                </q-btn>
                <q-btn
                  unelevated
                  flat
                  dense
                  round
                  size="sm"
                  color="tertiary"
                  class="no-hover"
                  v-if="showTip"
                  @click="
                    changeDialogState({
                      dialog: 'TipDialog',
                      val: true,
                      properties: {
                        action: 'edit',
                        tipDoller: currentCartDetails.orderSummary.tipAmount,
                      },
                    })
                  "
                >
                  <SvgIcon size="1rem" icon="edit-rect" />
                </q-btn>
                {{
                  currentCartDetails.orderSummary.tipAmount
                    | currency(false, true)
                }}
              </template>
              <q-btn
                v-else
                flat
                dense
                no-caps
                size="14px"
                padding="0 5px"
                color="primary"
                @click="openTipDialog"
                class="no-hover text-underline"
                >Add Tip</q-btn
              >
            </li>
          </template>
          <template
            v-if="
              deliveryCharge !== null &&
              selectedFulfillmentCodeName == 'sd' &&
              $route.name == 'Checkout'
            "
          >
            <li>
              <span>Shipping Fee</span>
              {{
                deliveryCharge == 0
                  ? 'Free'
                  : $options.filters.currency(deliveryCharge, false, true)
              }}
            </li>
          </template>
          <template v-if="currentCartDetails.orderSummary.discount">
            <li>
              <span>Discount Applied</span>
              {{ currentCartDetails.orderSummary.discount | currency }}
            </li>
          </template>

          <template
            v-if="
              currentCartDetails.orderSummary.additionalCharges &&
              currentCartDetails.orderSummary.additionalCharges.length
            "
          >
            <li
              v-for="(additionalCharge, index) of additionalCharges.charges"
              :key="`${additionalCharge.chargeName}-${index}`"
            >
              <span v-html="additionalCharge.chargeName" />
              {{ additionalCharge.chargeAmount | currency(false, true) }}
            </li>
          </template>

          <!-- <li v-if="currentCartDetails.orderSummary.taxes">
            <span>Tax</span>
            {{ currentCartDetails.orderSummary.taxes | currency(false, true) }}
          </li> -->
          <template v-if="currentCartDetails.orderSummary.otherTax">
            <li>
              <span>Tax</span>
              {{
                currentCartDetails.orderSummary.otherTax | currency(false, true)
              }}
            </li>
          </template>
          <template v-if="currentCartDetails.orderSummary.occupationalTax">
            <li>
              <span>Occupational Tax</span>
              {{
                currentCartDetails.orderSummary.occupationalTax
                  | currency(false, true)
              }}
            </li>
          </template>
          <template
            v-if="
              selectedFulfillmentCodeName &&
              selectedFulfillmentCodeName === 'scd' &&
              currentCartDetails.orderSummary.shippingRate
            "
          >
            <li>
              <span>Delivery Fee</span>
              {{
                currentCartDetails.orderSummary.shippingRate
                  | currency(false, true)
              }}
            </li>
          </template>

          <li v-if="currentCartDetails.orderSummary.buydown">
            <span>Other Discount</span>
            - {{ currentCartDetails.orderSummary.buydown | currency(false) }}
          </li>

          <transition
            appear
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <li v-if="currentCartDetails.orderSummary.appliedLnsDollars">
              <div class="text-left">
                <span>
                  <!-- <strong> -->
                  Loyalty Dollars Applied
                  <!-- </strong> -->
                </span>
                <q-btn
                  v-if="showLnsOption"
                  flat
                  unelevated
                  dense
                  @click="removePoints"
                  :loading="removingPoints"
                >
                  <SvgIcon class="text-negative" icon="close" size="12px" />
                </q-btn>

                <span class="float-right">
                  <span class="vertical-middle">
                    -
                    {{
                      currentCartDetails.orderSummary.appliedLnsDollars
                        | currency
                    }}
                  </span>
                </span>
              </div>
            </li>
          </transition>

          <!-- <li v-if="currentCartDetails.subTotal">
            <span> Sub Total</span>

            {{
              (currentCartDetails.subTotal -
                (currentCartDetails.orderSummary &&
                currentCartDetails.orderSummary.taxes
                  ? currentCartDetails.orderSummary.taxes
                  : 0) -
                (currentCartDetails.orderSummary &&
                currentCartDetails.orderSummary.appliedLnsDollars
                  ? currentCartDetails.orderSummary.appliedLnsDollars
                  : 0))
                | currency
            }}
          </li> -->

          <template
            v-if="
              currentCartDetails.appliedDiscountCoupons &&
              currentCartDetails.appliedDiscountCoupons.length
            "
          >
            <p class="q-my-sm applied-coupons">Applied Discount Coupons</p>
            <q-separator />
            <li
              v-for="appliedCoupon in currentCartDetails.appliedDiscountCoupons"
              :key="appliedCoupon.id"
            >
              <span>
                {{ appliedCoupon.couponCode }}
                <q-tooltip max-width="180px" content-class="bg-dark">
                  {{ appliedCoupon.couponCode }}
                </q-tooltip>
              </span>
              <q-btn
                v-if="showDiscountCoupon"
                unelevated
                flat
                dense
                round
                size="sm"
                color="red"
                class="coupon-remove-btn no-hover"
                @click="removeDiscountCoupon(appliedCoupon.id)"
              >
                <SvgIcon size="1rem" icon="close-circle" />
              </q-btn>
              {{ appliedCoupon.amount | currency }}
            </li>
            <div
              v-if="
                isMobile &&
                showLnsOption &&
                currentCartDetails.appliedCoupon &&
                currentCartDetails.appliedCoupon.length
              "
              class="col-12 col-md-12"
            >
              <div class="row">
                <div class="col-12 col-md-8">
                  <q-banner
                    inline-actions
                    dense
                    rounded
                    class="alert-warning alert-sm q-mb-md"
                  >
                    <strong>Note: </strong>Coupons may be removed from the order
                    if the applicable items are removed either by editing the
                    order or being out of stock.
                  </q-banner>
                </div>
              </div>
            </div>
          </template>

          <template
            v-if="
              currentCartDetails.appliedLnsCoupons &&
              currentCartDetails.appliedLnsCoupons.length
            "
          >
            <p class="q-my-sm applied-coupons">Applied Loyalty Coupon(s)</p>
            <q-separator />
            <li
              v-for="appliedLnsCoupon in currentCartDetails.appliedLnsCoupons"
              :key="appliedLnsCoupon.id"
            >
              <span>
                {{ appliedCouponName(appliedLnsCoupon.name) }}
                <q-tooltip max-width="180px" content-class="bg-dark">
                  {{ appliedLnsCoupon.name }}
                </q-tooltip>
              </span>
              <q-btn
                unelevated
                flat
                dense
                round
                size="sm"
                color="red"
                class="coupon-remove-btn no-hover"
                @click="removeCoupons(appliedLnsCoupon.id)"
                v-if="showLnsOption && isLnsSubscribed"
              >
                <SvgIcon size="1rem" icon="close-circle" />
              </q-btn>
              - {{ appliedLnsCoupon.amount | currency }}
            </li>
            <div
              v-if="
                isMobile &&
                showLnsOption &&
                currentCartDetails.appliedLnsCoupons &&
                currentCartDetails.appliedLnsCoupons.length
              "
              class="col-12 col-md-12"
            >
              <div class="row">
                <div class="col-12 col-md-8">
                  <q-banner
                    inline-actions
                    dense
                    rounded
                    class="alert-warning alert-sm q-mb-md"
                  >
                    <strong>Note: </strong>Coupons may be removed from the order
                    if the applicable items are removed either by editing the
                    order or being out of stock.
                  </q-banner>
                </div>
              </div>
            </div>
          </template>

          <transition
            appear
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <Alert
              show
              variant="warning"
              v-show="!currentCartDetails.orderSummary.finalPayAmount"
              className="q-mt-sm"
            >
              We will not charge your payment method for any orders totaling out
              to $0.00. However, all orders must have a temporary authorization
              hold of at least $0.01 to verify the payment method in case
              additional items are added
            </Alert>
          </transition>

          <q-separator class="q-mt-xs" />

          <li class="total">
            <span>Order Total</span>
            {{
              (currentCartDetails.orderSummary.finalPayAmount +
                deliveryCharge -
                additionalCharges.minOrderFixedCharge)
                | currency
            }}

            <div
              class="orderSummaryCouponcode"
              v-if="showLnsOption && isLnsSubscribed && isLoggedIn"
            >
              <a
                href="javascript:void(0);"
                class="promo-link float-right"
                @click="couponsDialog"
              >
                Browse Loyalty Coupons
              </a>
            </div>
          </li>
          <template v-if="showDiscountCoupon">
            <div class="q-mt-sm" />
            <li class="q-mt-md">
              <div class="orderSummaryPromocode redeem-code">
                <q-form @submit="applyDiscountCoupons">
                  <h4 class="lda-point">
                    <span class="lda-point-title">Redeem Coupon</span>
                  </h4>
                  <div class="promocodeForm">
                    <q-input
                      outlined
                      class="promo-input"
                      v-model="couponCode"
                      placeholder="Enter Coupon Code"
                    />
                    <q-btn
                      class="promo-btn text-capitalize"
                      type="submit"
                      label="Apply"
                      :disabled="submitCoupon"
                      :loading="submitCoupon"
                    />
                  </div>
                </q-form>
              </div>
            </li>
          </template>
          <q-separator class="q-mt-xs" />

          <template v-if="showLnsOption && isLnsSubscribed && isLoggedIn">
            <div class="q-mt-sm" />

            <li class="q-mt-md">
              <div class="orderSummaryPromocode redeem-code">
                <q-form @submit="applyPoints">
                  <h4 class="lda-point">
                    <span class="lda-point-title">Redeem Dollars</span>
                    <!-- <q-btn flat size="sm" padding="5px" type="button">
                      <SvgIcon icon="info-outline" size="13px" class="" />
                      <q-tooltip
                        anchor="top middle"
                        self="bottom middle"
                        :offset="[10, 10]"
                      >
                        Demo Text
                      </q-tooltip>
                    </q-btn> -->
                    <span class="float-right">
                      <SvgIcon icon="token" size="18px" class="q-mr-xs" />
                      <strong> {{ totalRedeemedDollars | currency }} </strong>
                      <span
                        v-if="currentCartDetails.orderSummary.appliedLnsDollars"
                      >
                        <strong>
                          -
                          {{
                            currentCartDetails.orderSummary.appliedLnsDollars
                              | currency
                          }}
                        </strong>
                      </span>
                    </span>
                  </h4>
                  <div class="promocodeForm" v-if="totalRedeemedDollars > 0">
                    <q-currency-input
                      class="promo-input"
                      v-model="redeemDolors"
                      :options="{
                        distractionFree: false,
                        currency: 'USD',
                        locale: 'en',
                      }"
                      placeholder="Redeem Loyalty Dollars"
                    />
                    <q-btn
                      class="promo-btn text-capitalize"
                      type="submit"
                      label="Redeem"
                      :disabled="submitredeem"
                      :loading="submitredeem"
                    />
                  </div>
                </q-form>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div
        class="orderSummartysubscription"
        v-if="
          showLnsOption &&
          businessConfigurations.lnsSubscriptionType == 1 &&
          customer?.isLoyalty === false &&
          isLoggedIn
        "
      >
        <div class="row items-center">
          <div class="col">
            <q-checkbox
              dense
              v-model="subscribeToLNS"
              label="By clicking you will subscribe to LNS"
            />
          </div>
          <div class="col-auto">
            <q-btn
              unelevated
              dense
              no-caps
              color="primaryOnBody"
              label="More Info"
              size="sm"
              padding="4px 8px"
              class="full-width"
              @click="openURL(loyalNSaveLink)"
            />
          </div>
        </div>
      </div>
      <q-list
        bordered
        class="rounded-borders orderSummaryDiscount"
        v-if="calculateTotalSavings"
      >
        <q-expansion-item dense-toggle expand-separator>
          <template v-slot:header>
            <q-item-section avatar>
              <SvgIcon size="2.5rem" icon="cart-savings" />
            </q-item-section>

            <q-item-section>
              <span>Your total</span>
              Savings!
            </q-item-section>

            <q-item-section
              side
              v-html="$options.filters.currency(calculateTotalSavings, false)"
            />
          </template>
          <!-- <q-card>
            <q-card-section>
              <ul>
                <li><span>Great Value Pack</span> -$78.56</li>
                <li><span>Great Value Pack</span> -$78.56</li>
              </ul>
            </q-card-section>
          </q-card> -->
        </q-expansion-item>
      </q-list>
      <div class="orderSummaryAction text-center">
        <p>Cart number: {{ currentCardId }}</p>
        <div class="form-action oc-total" v-if="isMobile">
          <div class="total">
            {{
              (currentCartDetails.subTotal +
                deliveryCharge -
                additionalCharges.minOrderFixedCharge)
                | currency
            }}
            <span>Order Total</span>
          </div>
        </div>
        <div class="form-action">
          <q-btn
            :disable="disableCheckout"
            :rounded="!isMobile"
            unelevated
            :label="`${checkoutBtnName}`"
            no-caps
            color="secondaryOnBody"
            :class="['full-width q-btn-lg', isMobile ? '' : 'radius-lg']"
            @click="proceedToCheckout"
            :loading="placingOrder"
          />
        </div>
        <div class="form-action continue">
          <q-btn
            :to="{ name: cancelCheckoutBtnUrl }"
            unelevated
            no-caps
            :rounded="!isMobile"
            outline
            color="tertiary"
            :label="cancelCheckoutBtnName"
            :class="['full-width q-btn-lg', isMobile ? '' : 'radius-lg']"
          />
        </div>
        <div class="form-action continue">
          <q-btn
            v-if="$route.name == 'Checkout' && !isMobile"
            unelevated
            no-caps
            :rounded="!isMobile"
            outline
            color="tertiary"
            label="Continue Shopping"
            :class="['full-width q-btn-lg', isMobile ? '' : 'radius-lg']"
            to="/"
          />
        </div>
        <p v-if="showEmptyCart">
          <a
            href="javascript:void('0');"
            class="block q-pa-md link-dark"
            @click="emptyCartAction"
          >
            <SvgIcon color="tertiary" icon="basket" class="q-mr-sm" />
            <span class="text-underline">Empty Cart</span>
          </a>
        </p>
        <!-- <p v-if="showAdditionalText">
          <small>
            Prices shown here may change between now and your pickup or delivery
            date.
             butwe’ll always give you the lowest price.
            Final cost is determined at the time we pack your items.
          </small>
        </p> -->
      </div>
    </div>
    <TipDialog :currentCartDetails="currentCartDetails" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { openURL } from 'quasar'
import { CurrencyDirective } from 'vue-currency-input'
import { loyalNSaveLink } from 'src/constants'
import { hideShowFullSpinner, appliedCouponName } from 'src/utils'
import TipDialog from 'src/components/cart/TipDialog.vue'

export default {
  name: 'OrderSummaryContent',
  props: {
    currentCartDetails: {
      type: Object,
      default: null,
    },
    showEmptyCart: {
      type: Boolean,
      default: false,
    },
    emptyCartAction: {
      type: Function,
      default: () => {},
    },
    checkout: {
      type: Function,
      default: () => {},
    },
    checkMinCheckoutAmount: {
      type: Function,
      default: null,
    },
    currentCardId: {
      type: String,
      default: '',
    },
    checkoutBtnName: {
      type: String,
      default: 'Proceed to Checkout',
    },
    cancelCheckoutBtnName: {
      type: String,
      default: 'Continue Shopping',
    },
    cancelCheckoutBtnUrl: {
      type: String,
      default: 'Home',
    },
    showAdditionalText: {
      type: Boolean,
      default: false,
    },
    disableCheckout: {
      type: Boolean,
      default: false,
    },
    placingOrder: {
      type: Boolean,
      default: false,
    },
    toggleOrderSummary: {
      type: Function,
      default: () => {},
    },
    onDialog: {
      type: Boolean,
      default: false,
    },
    showLnsOption: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    currency: CurrencyDirective,
  },
  data() {
    return {
      loyalNSaveLink,
      promoText: '',
      redeemDolors: null,
      removingPoints: false,
      submitredeem: false,
      orderSubscription: false,
      couponCode: '',
      submitCoupon: false,
    }
  },
  components: {
    TipDialog,
  },
  methods: {
    openURL,
    removeTip() {
      const title = 'Remove Tip?'
      const desc =
        'A person will not get a tip. Are you sure you want to remove the tip?'
      this.ftxConfirm(title, desc).onOk(() => {
        const body = {
          orderID: this.currentCartDetails.orderID,
          tipOptionValue: 0,
        }

        /* Start: Guest Checkout Changes */
        if (!this.isLoggedIn && this.currentCartNumber)
          body.orderNumber = this.currentCartNumber
        /* End: Guest Checkout Changes */

        this.$store
          .dispatch('cart/applyTip', body)
          .then((res) => {
            if (res.success) {
              this.showSuccess('Tip Removed Successfully.')
            }
          })
          .catch((er) => {
            console.log('error', er)
          })
      })
    },
    openTipDialog() {
      this.changeDialogState({
        dialog: 'TipDialog',
        val: true,
      })
    },
    proceedToCheckout() {
      if (this.isMobile) {
        if (!this.onDialog) {
          if (
            typeof this.checkMinCheckoutAmount == 'function' &&
            this.checkMinCheckoutAmount()
          ) {
            this.toggleOrderSummary(true)
          } else if (!this.checkMinCheckoutAmount) {
            this.toggleOrderSummary(true)
          }
        } else this.checkout()
      } else this.checkout()
    },
    applyPoints() {
      if (this.redeemDolors) {
        // check if redeem doller greater then final payment amount
        if (
          this.redeemDolors >
          this.currentCartDetails.orderSummary.finalPayAmount
        ) {
          this.showError('You can not redeem dollars more than order amount')
          return
        }

        let redeemDolors = this.redeemDolors
        // parseFloat(String(redeemDolors).replace(/\$|,/g, ''))
        this.submitredeem = true
        this.$store
          .dispatch('cart/applyLnsPoints', {
            dollars: redeemDolors,
          })
          .then((res) => {
            if (!res.success && res.message) this.showError(res.message)
          })
          .finally(() => {
            this.submitredeem = false
            this.redeemDolors = null
          })
      }
    },
    removePoints() {
      this.removingPoints = true
      this.$store
        .dispatch('cart/applyLnsPoints', {
          dollars: 0,
        })
        .then((res) => {
          if (!res.success && res.message) this.showError(res.message)
        })
        .finally(() => {
          this.removingPoints = false
        })
    },
    couponsDialog() {
      this.changeDialogState({
        dialog: 'LNSCouponsDialog',
        val: true,
        properties: {
          loading: true,
        },
      })
      this.$store
        .dispatch('customer/GetCoupons', {
          locationID: this.currentLocationId,
        })
        .then((res) => {
          if (res.success)
            this.$store.commit('showcase/SET_DIALOG_PROPERTIES', {
              dialog: 'LNSCouponsDialog',
              properties: {
                loading: false,
                coupons: res.data,
                applyCoupons: this.applyCoupons,
              },
              refresh: false,
            })
          if (!res.success && res.message) this.showError(res.message)
        })
    },
    async applyCoupons(selectedCoupons) {
      try {
        hideShowFullSpinner(true)
        let customerCouponId = selectedCoupons.map((coupon) => ({
          customerCouponId: coupon.id,
        }))
        let res = await this.$store.dispatch(
          'cart/applyCoupons',
          customerCouponId
        )
        if (
          res.success &&
          res.data.errorMessage &&
          res.data.errorMessage.length
        ) {
          this.showMultipleCouponsError(
            this.formatErrorMessage(res.data.errorMessage)
          )
          this.changeDialogState({
            dialog: 'LNSCouponsDialog',
            val: false,
          })
        } else if (
          res.success &&
          res.data.errorMessage &&
          !res.data.errorMessage.length
        ) {
          this.changeDialogState({
            dialog: 'LNSCouponsDialog',
            val: false,
          })
          this.showSuccess('Coupon Applied Successfully.')
        } else if (!res.success && res.message) this.showError(res.message)
        hideShowFullSpinner(false)
      } catch (error) {
        console.log(error)
        if (error && error.message) this.showError(error.message)
      }
    },
    removeCoupons(couponId) {
      hideShowFullSpinner(true)
      this.$store
        .dispatch('cart/removeCoupons', couponId)
        .then((res) => {
          if (res.success) {
            this.showSuccess('Coupon Removed Successfully.')
          }
          if (!res.success && res.message) this.showError(res.message)
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    removeDiscountCoupon(couponId) {
      hideShowFullSpinner(true)
      let requestObj = {
        couponID: couponId,
      }
      let guestCartNumber = this.$store.getters['guest/guestCartNumber']
      if (!this.isLoggedIn && guestCartNumber)
        requestObj.orderNumber = guestCartNumber
      this.$store
        .dispatch('cart/removeDiscountCoupons', requestObj)
        .then((res) => {
          if (res.success) {
            this.showSuccess('Coupon Removed Successfully.')
          }
          if (!res.success && res.message) this.showError(res.message)
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    async applyDiscountCoupons() {
      if (this.couponCode) {
        try {
          hideShowFullSpinner(true)
          let requestObj = {
            discountCouponCode: this.couponCode,
          }
          let guestCartNumber = this.$store.getters['guest/guestCartNumber']
          if (!this.isLoggedIn && guestCartNumber)
            requestObj.OrderNumber = guestCartNumber

          this.submitCoupon = true
          let res = await this.$store.dispatch(
            'cart/applyDiscountCoupons',
            requestObj
          )
          if (
            res.success &&
            res.data.errorMessage &&
            res.data.errorMessage.length
          ) {
            this.showMultipleCouponsError(
              this.formatErrorMessage(res.data.errorMessage)
            )
          } else if (
            res.success &&
            res.data.errorMessage &&
            !res.data.errorMessage.length
          ) {
            this.ftxConfirmCoupon(
              '',
              'Yay! Coupon is applied',
              'Cancel',
              'Ok',
              true,
              false,
              '',
              '',
              false,
              true,
              false,
              false
            )
          } else if (!res.success && res.message) {
            if (res.errors[0].errorCode === 'TRIGGERLIST_ITEM') {
              let seoname = res.message
              this.$store
                .dispatch('discounts/getDiscountDetail', seoname)
                .then((data) => {
                  if (data && this.currentDiscount) {
                  }
                })
                .catch((error) => {
                  console.log('error', error)
                })
              this.ftxConfirmCoupon(
                '',
                'To receive this discount, you must purchase at least ' +
                  this.currentDiscount?.triggerQty +
                  ' item from the list',
                'Cancel',
                'Ok',
                true,
                false,
                'info-filled',
                '3em',
                true,
                false,
                true,
                true
              ).onOk(() => {
                this.changeDialogState({
                  dialog: 'TriggerListDialog',
                  val: true,
                  properties: {
                    discount: this.currentDiscount,
                    applyCoupon: true,
                    addToCartButton: '',
                  },
                })
              })
            } else
              this.ftxConfirmCoupon(
                '',
                res.message,
                'Cancel',
                'Ok',
                true,
                false,
                'info-filled',
                '3em',
                true,
                false,
                false,
                true
              )
          }
          hideShowFullSpinner(false)
        } catch (error) {
          console.log(error)
          if (error && error.message) this.showError(error.message)
        }
        this.couponCode = ''
        this.submitCoupon = false
      }
    },
    appliedCouponName: appliedCouponName,
    formatErrorMessage(messages) {
      let result = `<span>`
      for (const message of messages) {
        result += `<i aria-hidden="true" role="presentation" class="material-icons q-icon notranslate text-negative q-ma-xs">error</i>${message}<br />`
      }
      result += `</span>`
      return result
    },
  },
  computed: {
    ...mapGetters('customer', ['totalRedeemedDollars', 'customer', 'coupons']),
    ...mapGetters('persisted', ['selectedFulfillmentCodeName']),
    ...mapGetters('cart', [
      'currentCartNumber',
      'shippingRatesById',
      'checkoutSelection',
    ]),
    ...mapGetters('discounts', ['currentDiscount']),
    subscribeToLNS: {
      get() {
        return this.$store.getters['persistedLocal/subscribeToLNS']
      },
      set(val) {
        this.$store.commit('persistedLocal/SET_SUBSCRIBE_TO_LNS', val)
      },
    },
    deliveryCharge() {
      let rateObj = {}

      if (
        this.selectedFulfillmentCodeName === 'sd' &&
        this.$route.name == 'Checkout'
      ) {
        let fulfillment = Object.values(
          this.currentCartDetails?.fulfillments || {}
        ).find((x) => x.codeName == 'sd')

        rateObj = this.shippingRatesById(
          this.checkoutSelection?.rateID || fulfillment.shippingRateID
        )
      }

      return rateObj?.finalAmount || 0
    },
    showTip() {
      const selectedFulfillment =
        this.businessFulfillments[this.selectedFulfillmentCodeName]
      if (
        this.$route.name == 'Checkout' &&
        this.selectedFulfillmentCodeName !== 'sd' &&
        selectedFulfillment.isTipEnabled
      ) {
        return true
      }
      return false
    },
    showDiscountCoupon() {
      if (this.$route.name == 'Cart') {
        return true
      }
      return false
    },
    showTipViewMode() {
      const selectedFulfillment =
        this.businessFulfillments[this.selectedFulfillmentCodeName]
      if (
        this.$route.name == 'Cart' &&
        this.selectedFulfillmentCodeName !== 'sd' &&
        selectedFulfillment.isTipEnabled
      ) {
        return true
      }
      return false
    },
    appliedLnsCouponsAmount() {
      let amount = 0
      if (
        this.currentCartDetails &&
        this.currentCartDetails?.appliedLnsCoupons?.length
      ) {
        for (let coupon of this.currentCartDetails.appliedLnsCoupons) {
          amount += Math.abs(coupon.amount)
        }
      }
      return amount
    },
    appliedDiscountCouponsAmount() {
      let amount = 0
      if (
        this.currentCartDetails &&
        this.currentCartDetails?.appliedDiscountCoupons?.length
      ) {
        for (let coupon of this.currentCartDetails.appliedDiscountCoupons) {
          amount += Math.abs(coupon.amount)
        }
      }
      return amount
    },
    calculateTotalSavings() {
      let amount = 0
      if (this.currentCartDetails && this.currentCartDetails?.orderSummary) {
        if (this.currentCartDetails.orderSummary?.discount)
          amount += Math.abs(this.currentCartDetails.orderSummary.discount)
        if (this.currentCartDetails.orderSummary?.buydown)
          amount += Math.abs(this.currentCartDetails.orderSummary.buydown)
        if (this.currentCartDetails.orderSummary?.appliedLnsDollars)
          amount += Math.abs(
            this.currentCartDetails.orderSummary.appliedLnsDollars
          )
      }
      if (this.appliedLnsCouponsAmount) amount += this.appliedLnsCouponsAmount
      if (this.appliedDiscountCouponsAmount)
        amount += this.appliedDiscountCouponsAmount
      return amount
    },
    isLnsSubscribed() {
      return (
        this.businessConfigurations.lnsSubscriptionType == 2 ||
        (this.businessConfigurations.lnsSubscriptionType == 1 &&
          this.customer?.isLoyalty === true)
      )
    },
    additionalCharges() {
      let responseObj = {}
      if (
        this.selectedFulfillmentCodeName === 'sd' &&
        this.$route.name == 'Checkout'
      ) {
        let fulfillment = Object.values(
          this.currentCartDetails?.fulfillments || {}
        ).find((x) => x.codeName == 'sd')

        let rate = this.shippingRatesById(
          this.checkoutSelection?.rateID || fulfillment.shippingRateID
        )

        let minOrderAdditionalCharge =
          this.currentCartDetails?.orderSummary?.additionalCharges.find(
            (x) => x.addChargeType == 1
          )
        if (
          this.currentCartDetails?.orderSummary?.shippingRate !=
            rate?.finalAmount &&
          this.currentCartDetails?.orderSummary?.finalPayAmount +
            rate?.finalAmount -
            minOrderAdditionalCharge?.chargeAmount >
            fulfillment.minCheckoutAmount
        ) {
          responseObj.charges =
            this.currentCartDetails.orderSummary.additionalCharges.filter(
              (x) => x.addChargeType != 1
            )
          responseObj.minOrderFixedCharge = fulfillment.fixedCharge
          return responseObj
        }
      }
      responseObj.charges =
        this.currentCartDetails.orderSummary.additionalCharges
      responseObj.minOrderFixedCharge = 0
      return responseObj
    },
  },
}
</script>
<style lang="scss">
p.applied-coupons {
  font-weight: 700;
  text-align: left;
  color: #333;
}

.border-bottom {
  border-bottom: 1px solid rgba(112, 112, 112, 0.25);
}

.orderSummaryCouponcode {
  text-align: left;
  display: block;
  padding-bottom: 10px;

  // border-bottom: 1px solid rgba(112, 112, 112, 0.25);
  .promo-link {
    color: grey;
    font-size: 11px;
    // text-decoration: none;
    // border-bottom: 1px solid grey;
    padding-bottom: 2px;
    margin-top: 5px;
    display: inline-block;
  }
}

.orderSummaryPromocode {
  text-align: left;
  border-bottom: 1px solid rgba(112, 112, 112, 0.25);

  .promo-input {
    .q-field__control {
      height: 34px;
    }
  }

  .promo-link {
    color: grey;
    font-size: 11px;
    text-decoration: none;
    // border-bottom: 1px solid grey;
    padding-bottom: 2px;
    margin-top: 5px;
    display: inline-block;
  }

  &.redeem-code {
    margin-bottom: 0;
    padding-bottom: 0px;
    border-bottom: none;
  }
}

.promo-btn {
  background-color: $primaryOnBody;
  color: $primaryText;
}

.orderSummartysubscription {
  padding: 6px;
  margin-bottom: 15px;
  font-size: 11px;
  background: #f2fcff;
  border: 1px solid #b4edff;
  -webkit-border-radius: 4px;
  border-radius: 4px;

  @media (max-width: 767px) {
    .sectionRight & {
      display: none;
    }
  }
}

.lda-point {
  display: flex;
  align-items: center;

  .lda-point-title {
    flex: 1;
    padding-right: 15px;
  }
}
</style>
