<template>
  <ftx-dialog
    :dialog="dialog"
    position="right"
    maximized
    :onShow="showDialog"
    :onHide="reset"
    persistent
  >
    <template v-slot:DialogContent>
      <q-card class="addPaymentDialogue">
        <q-card-section>
          <div class="topHeader">
            <h3
              v-text="
                isViewMode ? 'Select Billing Address' : 'Add new payment method'
              "
            ></h3>
            <q-btn
              unelevated
              flat
              round
              icon="close"
              @click="closeDialog"
              class="close"
            />
          </div>
          <q-form ref="paymentForm" @submit="requestToken" :id="formId">
            <div class="middle">
              <q-scroll-area
                class="addPaymentHeight"
                :thumb-style="scrollAreaThumbStyle"
                :bar-style="scrollAreaBarStyle"
                :visible="scrollAreaVisible"
                ref="paymentScrollArea"
                :style="[{ maxWidth: '100%' }]"
              >
                <Alert
                  variant="danger"
                  dismissible
                  :show="paymentMethodCountDown"
                  @dismissed="paymentMethodCountDown = 0"
                  @dismiss-count-down="
                    (countDown) => {
                      countDownChanged(countDown, 'paymentMethodCountDown')
                    }
                  "
                >
                  {{ paymentMethodError }}
                </Alert>
                <div class="row">
                  <div class="col-xs-12 col-md-12 q-px-sm">
                    <div class="row q-col-gutter-x-sm" v-if="!isViewMode">
                      <template v-if="dataCapOnIframe == 'true'">
                        <div class="col-xs-12">
                          <div class="form-field q-pr-md">
                            <div class="col-xs-6"> Card Holder Name </div>
                            <div class="col-xs-6">
                              <q-input
                                v-model="cardDetail.cardName"
                                filled
                                placeholder="Card Holder Name"
                                :disable="loadingIframe"
                              />
                            </div>
                          </div>
                          <div style="position: relative; height: 180px">
                            <InnerLoadingSpinner
                              size="2em"
                              :showing="loadingIframe"
                            />
                            <iframe
                              v-show="!loadingIframe"
                              :id="ifreameId"
                              width="100%"
                              height="180"
                              frameborder="0"
                              :src="DATA_CAP_PAY_URI"
                              @load="loadingIframe = false"
                            />
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col-xs-12 col-md-12">
                          <p><small>*Required Fields</small></p>
                        </div>

                        <div class="col-xs-12 col-md-12">
                          <div class="form-field">
                            <q-input
                              outlined
                              label="Card Holder Name*"
                              v-model="cardDetail.cardName"
                              :rules="[
                                (val) => validateRequired(val),
                                validatePersonName(
                                  cardDetail.cardName,
                                  'Please enter a valid name'
                                ),
                              ]"
                              :lazy-rules="lazyValidationRules"
                              maxlength="50"
                            >
                              <template v-slot:append>
                                <SvgIcon
                                  icon="avtar"
                                  class="input-append-icon"
                                />
                              </template>
                            </q-input>
                          </div>
                        </div>

                        <div class="col-xs-12 col-md-12">
                          <div class="form-field">
                            <q-input
                              outlined
                              label="Card Number*"
                              data-token="card_number"
                              v-model="cardDetail.cardNumber"
                              v-mask="'#### #### #### ####'"
                              :rules="[(val) => validateRequired(val)]"
                              :lazy-rules="lazyValidationRules"
                            >
                              <template v-slot:append>
                                <SvgIcon
                                  icon="card"
                                  class="input-append-icon"
                                />
                              </template>
                            </q-input>
                          </div>
                        </div>

                        <div class="col-xs-12 col-md-4">
                          <div class="form-field">
                            <q-input
                              outlined
                              label="Exp Month*"
                              data-token="exp_month"
                              v-model="cardDetail.expMonth"
                              :rules="[
                                (val) => validateRequired(val),
                                (val) => validateMonth(val),
                              ]"
                              :lazy-rules="lazyValidationRules"
                              v-mask="'##'"
                            >
                              <!-- <template v-slot:append>
                              <SvgIcon
                                icon="calendar"
                                class="input-append-icon"
                              />
                            </template> -->
                            </q-input>
                          </div>
                        </div>

                        <div class="col-xs-12 col-md-4">
                          <div class="form-field">
                            <q-input
                              outlined
                              label="Exp Year*"
                              data-token="exp_year"
                              v-model="cardDetail.expYear"
                              :rules="[
                                (val) => validateRequired(val),
                                (val) =>
                                  Number(val) >= currentYear ||
                                  'Please Enter Valid Year',
                              ]"
                              :lazy-rules="lazyValidationRules"
                              v-mask="'####'"
                            >
                              <!-- <template v-slot:append>
                              <SvgIcon
                                icon="calendar"
                                class="input-append-icon"
                              />
                            </template> -->
                            </q-input>
                          </div>
                        </div>

                        <div class="col-xs-12 col-md-4">
                          <div class="form-field">
                            <q-input
                              outlined
                              type="password"
                              label="CVV*"
                              data-token="cvv"
                              v-model="cardDetail.cvv"
                              v-mask="'####'"
                              :rules="[(val) => validateRequired(val)]"
                              :lazy-rules="lazyValidationRules"
                            >
                              <template v-slot:append>
                                <span class="material-icons"> password </span>
                                <!-- <SvgIcon icon="calendar" class="input-append-icon" /> -->
                              </template>
                            </q-input>
                          </div>
                        </div>
                      </template>
                      <div class="col-xs-12">
                        <div class="form-field">
                          <q-checkbox
                            dense
                            v-model="isDefault"
                            label="Set as Default payment method"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row q-col-gutter-x-sm" v-else>
                      <div class="col-12">
                        <q-card class="no-shadow q-pa-md q-mb-md r-8" bordered>
                          <q-card-section class="relative-position min-200">
                            <h6 class="text-body1 text-weight-bold q-ma-none"
                              >Payment Method</h6
                            >
                            <span class="absolute-top-right paymentIcon cc">
                              <img
                                :src="`images/payment/${
                                  getCardType(cardDetail.cardType).logo
                                }`"
                                :title="getCardType(cardDetail.cardType).title"
                              />
                            </span>
                            <div class="text-h5 text-center q-pt-xl">
                              <div class="row q-col-gutter-md justify-center">
                                <div class="col-auto text-h4">****</div>
                                <div class="col-auto text-h4">****</div>
                                <div class="col-auto text-h4">****</div>
                                <div class="col-auto">
                                  {{ cardDetail.cardNumber }}
                                </div>
                              </div>
                            </div>
                            <div class="absolute-bottom-left">
                              <span class="text-uppercase text-caption">
                                Card Holder
                              </span>
                              <br /><strong> {{ cardDetail.cardName }} </strong>
                            </div>
                            <div class="absolute-bottom-right text-right">
                              <span class="text-uppercase text-caption"
                                >Expires</span
                              ><br />{{ cardDetail.expMonth }}/{{
                                cardDetail.expYear
                              }}
                            </div>
                          </q-card-section>
                        </q-card>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-12 q-pt-sm q-px-sm">
                    <h4>Select a billing address</h4>
                    <Alert
                      class="q-mt-md"
                      variant="danger"
                      dismissible
                      :show="billingErrorCountDown"
                      @dismissed="billingErrorCountDown = 0"
                      @dismiss-count-down="countDownChanged"
                    >
                      {{ billingAddressError }}
                    </Alert>
                    <div class="manageBillingItems">
                      <transition-group
                        appear
                        enter-active-class="animated fadeIn"
                      >
                        <div
                          :class="[
                            'billingItem',
                            key == 'add-address' ? 'addbillingItem' : '',
                          ]"
                          v-for="(
                            billingAddresses, key
                          ) in billingAddressesList"
                          :key="`${key}`"
                          @click="selectBillingAddress(billingAddresses)"
                        >
                          <div
                            :class="[
                              'billingInner',
                              selectedBillingAddress &&
                              selectedBillingAddress.addressId == key
                                ? 'active'
                                : '',
                            ]"
                          >
                            <a
                              v-if="key == 'add-address'"
                              href="javascript:void('0');"
                              class="billingMiddle"
                              @click="addBillingAddress"
                            >
                              <SvgIcon
                                size="16px"
                                icon="plus"
                                class="q-mr-sm"
                              />
                              Add New Billing Address
                            </a>
                            <div v-else class="billingMiddle cursor-pointer">
                              <q-radio
                                v-model="selectedBillingAddress"
                                :val="billingAddresses"
                                size="sm"
                                class="billingSelect"
                              />
                              <h3 class="ellipsis">
                                {{ billingAddresses.firstName }}
                                {{ billingAddresses.lastName }}
                                <q-tooltip
                                  v-if="!isMobile"
                                  max-width="180px"
                                  content-class="bg-dark"
                                >
                                  {{ billingAddresses.firstName }}
                                  {{ billingAddresses.lastName }}
                                </q-tooltip>
                              </h3>
                              <p
                                v-html="
                                  $options.filters.formatAddress(
                                    billingAddresses
                                  )
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </transition-group>
                    </div>
                  </div>
                </div>
              </q-scroll-area>
            </div>
            <div class="bottom">
              <q-btn
                unelevated
                no-caps
                rounded
                outline
                color="tertiary"
                label="Cancel"
                @click="closeDialog"
                class="q-ml-sm q-mr-sm q-px-md q-py-xs"
              />
              <div class="right">
                <q-btn
                  unelevated
                  no-caps
                  rounded
                  color="secondaryOnBody"
                  label="Save"
                  class="q-ml-sm q-mr-sm q-px-md q-py-xs"
                  type="submit"
                  :loading="addingPayment"
                  :disable="addingPayment"
                />
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { mergeObjects, getClassForCardType, getCardType } from 'src/utils'
import { PaymentGateways } from 'src/constants'

function initialState() {
  return {
    dialog: 'AddPaymentMethod',
    addingPayment: false,
    selectedBillingAddress: null,
    billingErrorCountDown: 0,
    billingAddressError: '',
    paymentMethodCountDown: 0,
    paymentMethodError: '',
    currentYear: new Date().getFullYear(),

    // new
    cardDetail: {
      cardName: '',
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvv: '',
      cardType: '',
    },
    formId: 'payment_form',
    dataCapOnIframe: process.env.DATA_CAP_ON_IFRAME,
    ifreameId: 'token-frame',
    loadingIframe: false,
    isDefault: false,
    DATA_CAP_PAY_URI: process.env.DATA_CAP_BASE_URL + '/v1/view/i',
    isChangesUnsaved: false,
    resetCardDetailWatcher: () => {},
    resetBillingAddressWatcher: () => {},
  }
}
export default {
  name: 'AddPaymentMethod',
  computed: {
    ...mapGetters('address', ['billingAddresses']),
    ...mapGetters('common', ['currentPaymentGateway']),
    billingAddressesList() {
      let mergeAddressObj = { 'add-address': {} },
        cardDetails = this.properties.paymentMethod

      if (
        cardDetails?.address?.id &&
        this.billingAddresses &&
        this.billingAddresses[cardDetails.address.id]
      ) {
        mergeAddressObj[cardDetails?.address?.id] =
          this.billingAddresses[cardDetails.address.id]
      }

      return mergeObjects(mergeAddressObj, this.billingAddresses)
    },
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    isViewMode() {
      return this.properties?.action === 'ChooseBillingAddress'
    },
  },
  methods: {
    getClassForCardType: getClassForCardType,
    getCardType: getCardType,
    requestToken() {
      if (!this.selectedBillingAddress) {
        this.billingAddressError = 'Please Select Billing Address'
        this.billingErrorCountDown = 7
        return
      }
      if (this.isViewMode) {
        return this.updateBillingInfo()
      } else if (this.currentPaymentGateway == PaymentGateways.DATACAP) {
        if (typeof DatacapHostedWebToken !== 'undefined') {
          this.setLoading(true)
          DatacapHostedWebToken.requestToken()
        } else if (
          typeof DatacapWebToken !== 'undefined' &&
          this.currentLocation.tokenKey
        ) {
          this.setLoading(true)

          if (this.validateCardDetails()) {
            DatacapWebToken.requestToken(
              this.currentLocation.tokenKey,
              this.formId,
              this.tokenCallback
            )
          } else {
            this.setLoading(false)
          }
        }
      } else if (this.currentPaymentGateway == PaymentGateways.USAEPAY) {
        this.getUSAEPAYToken()
      }
    },
    getUSAEPAYToken() {
      let body = {
        locationID: this.currentLocation.locationId,
        cardInfo: {
          cardHolder: this.cardDetail.cardName,
          cardNumber: this.cardDetail.cardNumber,
          expiry: this.cardDetail.expMonth + this.cardDetail.expYear,
          cvv: this.cardDetail.cvv,
          street: this.selectedBillingAddress.line1,
          zip: this.selectedBillingAddress.zip,
        },
      }
      this.setLoading(true)
      this.$store
        .dispatch('payment/getUSAePayToken', body)
        .then((data) => {
          if (!data.success) {
            this.setError(
              (data.errors && data.errors[0].descreption) || data.message
            )
          } else if (data.success) {
            const result = data.data

            let resp = {
              isDefault: this.isDefault,
              ExpirationYear: this.cardDetail.expYear,
              ExpirationMonth: this.cardDetail.expMonth,
              Token: result.token,
              Last4: result.last4,
              cardholderName: this.cardDetail.cardName,
              Brand: result.cardType,
            }
            this.next(resp)
          }
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    validateCardDetails() {
      let validCard = DatacapWebToken.validateCardNumber(
        this.cardDetail.cardNumber.replace(/\s+/g, '')
      )

      if (!validCard) {
        let error = 'Please enter a valid card number'
        if (this.isMobile) this.showError(error)
        else this.setError(error)
        return false
      }

      return true
    },
    countDownChanged(
      dismissCountDown,
      countDownField = 'billingErrorCountDown'
    ) {
      this[countDownField] = dismissCountDown
    },
    scrollTop() {
      this.scrollToPosition(this.$refs.paymentScrollArea, 0, 100)
    },
    setLoading(val) {
      this.addingPayment = val
    },
    async next(resp) {
      if (!this.selectedBillingAddress) {
        this.billingAddressError = 'Please Select Billing Address'
        this.billingErrorCountDown = 7
        return
      }
      if (resp.ExpirationYear && resp.ExpirationMonth) {
        // if (!(resp.ExpirationMonth >= 1 && resp.ExpirationMonth <= 12)) {
        //   return this.setError('Please Enter Valid Expiry Month')
        // }
        if (
          !this.isDateGreaterThanToday(
            `${resp.ExpirationMonth}/${resp.ExpirationYear}`
          )
        )
          return this.setError(
            'Please Enter Card Valid Card Year. It Seems Your Card Has Been Expired!'
          )
      }

      this.setLoading(true)

      let reqData = {
        ...this.selectedBillingAddress,
        token: resp.Token,
        cardLast4: resp.Last4,
        cardHolderName: resp.cardholderName,
        cardExpiryDate: `${resp.ExpirationMonth}/${resp.ExpirationYear}`,
        cardType: resp.Brand,
        isDefault: resp.isDefault,
        locationId: this.currentLocation ? this.currentLocation.locationId : '',
      }
      if (!this.isLoggedIn && this.properties.customerID)
        reqData.customerID = this.properties.customerID
      let data = await this.$store.dispatch('payment/addPaymentMethod', reqData)

      this.setLoading(false)
      if (!data.success) {
        this.setError(
          (data.errors && data.errors[0].descreption) ||
            'Error occurred while adding payment method.'
        )
      } else {
        this.dialogState = false
        this.showSuccess('Payment method added successfully.')
        if (this.$root) {
          this.$root.$emit('payment-method-added', data)
        }
      }
    },
    selectBillingAddress(billingAddress) {
      if (billingAddress && billingAddress.addressId)
        this.selectedBillingAddress = billingAddress
    },
    reset() {
      this.resetCardDetailWatcher()
      this.resetBillingAddressWatcher()
      Object.assign(this.$data, initialState())

      setTimeout(() => {
        this.isChangesUnsaved = false
      }, 200)
    },
    addBillingAddress() {
      let properties = {}
      if (this.properties?.customerID)
        properties.customerID = this.properties.customerID
      this.changeDialogState({
        dialog: 'AddBillingAddress',
        val: true,
        parentDialog: 'AddPaymentMethod',
        properties,
      })
    },
    showDialog() {
      if (this.$refs.paymentForm && this.$refs.paymentForm.$el) {
        this.$refs.paymentForm.$el
          .querySelectorAll('input')
          .forEach((element) => {
            element.removeAttribute('id')
          })
      }
      if (this.properties.paymentMethod) {
        let cardDetails = this.properties.paymentMethod
        this.cardDetail = {
          cardName: cardDetails?.cardHolderName || '',
          cardNumber: cardDetails?.cardLast4Digits || '',
          expMonth: cardDetails.cardExpiry?.split('/')[0] || '',
          expYear: cardDetails.cardExpiry?.split('/')[1] || '',
          cardType: cardDetails?.cardType || '',
        }

        if (cardDetails?.address?.id && !this.selectedBillingAddress) {
          this.selectedBillingAddress =
            this.billingAddressesList[cardDetails.address.id]
        }
      } else if (
        !this.selectedBillingAddress &&
        Object.keys(this.billingAddressesList).length &&
        this.billingAddressesList[Object.keys(this.billingAddressesList)[0]]
          .addressId
      ) {
        this.selectedBillingAddress =
          this.billingAddressesList[Object.keys(this.billingAddressesList)[0]]
      }

      /* Set Watcher for Add Card Detail Form */
      this.resetCardDetailWatcher = this.$watch('cardDetail', watcherCallback, {
        deep: true,
      })
      /* Start: Set Watcher for change billing address */
      this.resetBillingAddressWatcher = this.$watch(
        'selectedBillingAddress',
        watcherCallback,
        {
          deep: true,
        }
      )
      function watcherCallback(newVal) {
        if (newVal) this.isChangesUnsaved = true
      }
      /* End: Set Watcher for change billing address */
    },
    setError(error) {
      this.paymentMethodError = error
      this.paymentMethodCountDown = 7
      this.scrollTop()
    },
    tokenCallback(resp) {
      if (this.cardDetail.cardName) {
        resp.cardholderName = this.cardDetail.cardName
      } else {
        resp.Error = 'Please Enter Card Holder Name'
      }

      if (resp.Error) {
        this.setLoading(false)
        // Handle tokenization errors
        let error = resp.Error
        if (error) {
          if (this.isMobile) this.showError(error)
          else this.setError(error)
        }
      } else {
        this.next({
          ...resp,
          isDefault: this.isDefault,
        })
        this.setLoading(false)
      }
    },
    isDateGreaterThanToday(b) {
      var a = b.split('/')
      if (parseInt(a[1]) > parseInt(new Date().getFullYear())) return true
      else if (
        parseInt(a[1]) == parseInt(new Date().getFullYear()) &&
        parseInt(a[0]) >= parseInt(new Date().getMonth()) + 1
      )
        return true
      else return false
    },
    openConfirmationPopup() {
      const confirmationDialogTitle = 'Save Before Closing ?'
      const confirmationDialogDescription = `It looks like you're trying to close the tab before saving your changes.<br />Would you like to save or discard these changes?`
      const cancelBtnLabel = 'Discard and Close'
      const confirmBtnLabel = 'Save and Close'
      const closeBtn = false
      const outsideClickCloseDisable = true

      this.ftxConfirm(
        confirmationDialogTitle,
        confirmationDialogDescription,
        cancelBtnLabel,
        confirmBtnLabel,
        closeBtn,
        outsideClickCloseDisable
      )
        .onOk(() => {
          this.$refs.paymentForm.validate().then((success) => {
            if (success) this.requestToken()
          })
        })
        .onCancel(() => {
          this.dialogState = false
        })
    },
    closeDialog() {
      if (!this.isChangesUnsaved) this.dialogState = false
      else this.openConfirmationPopup()
    },
    async updateBillingInfo() {
      if (!this.selectedBillingAddress) {
        this.billingAddressError = 'Please Select Billing Address'
        this.billingErrorCountDown = 7
        return
      }

      this.setLoading(true)
      let reqData = {
        paymentMethodId: this.properties.paymentMethod.paymentMethodId,
        address: { ...this.selectedBillingAddress },
      }
      if (!this.isLoggedIn && this.properties.customerID)
        reqData.customerID = this.properties.customerID
      let response = await this.$store.dispatch(
        'payment/updateBillingAddress',
        reqData
      )
      this.setLoading(false)
      if (response && response.success && response?.data === true) {
        this.dialogState = false
        this.showSuccess('Billing address modified successfully.')
      } else {
        this.setError('Error occurred while adding billing address.')
      }
    },
  },
  data: function () {
    return initialState()
  },
  created() {
    this.$root.$on('address-added-or-modified', async (data) => {
      if (data && data.addressId && this.billingAddressesList[data.addressId])
        this.selectedBillingAddress = this.billingAddressesList[data.addressId]
    })
  },
  mounted() {
    this.loadingIframe = true
    var customCSS =
      'body { margin: 0; padding: 0; } #payment_form { display: flex; flex-direction: row; flex-wrap: wrap; } #payment_form .row { flex: 1 0 100%; padding-bottom: 16px; } #payment_form .right-col { padding-right: 16px; } #payment_form .row-card-data-exp-month, #payment_form .row-card-data-exp-year, #payment_form .row-card-data-cvv { flex: 1 0 33.33%; width: 33.33%; } .left-col {padding-bottom: 4px; font-size: 14px; font-family: Lato, Arial, sans-serif; color: #666666;} .card-data { padding: 0 16px; height: 48px; background-color: rgba(0, 0, 0, 0.05); color: #666666; border: none; border-bottom: 1px solid rgba(0, 0, 0, 0.05); width: calc(100% - 32px); outline: none; transition: all 0.3s ease-in-out; } select.card-data { width: 100%; } .card-data:hover { border-bottom-color: rgba(0, 0, 0, 0.42); }'
    if (
      typeof DatacapHostedWebToken !== 'undefined' &&
      this.currentLocation.tokenKey
    )
      DatacapHostedWebToken.init(
        this.currentLocation.tokenKey,
        this.ifreameId,
        this.tokenCallback,
        customCSS
      )
  },
  watch: {
    /* cardDetail: {
      handler(newVal) {
        if (newVal) {
          this.isChangesUnsaved = true
        }
      },
      deep: true,
    }, */
    isDefault(newVal, oldVal) {
      if (newVal != oldVal) {
        this.isChangesUnsaved = true
      }
    },
  },
}
</script>

<style lang="scss">
/* Add Payment Dialogue */
.addPaymentDialogue {
  width: 100vw !important;
  @media (min-width: 481px) {
    width: 440px !important;
  }
  @media (min-width: 1024px) {
    width: 640px !important;
  }
  .form-field,
  .form-action {
    padding-bottom: 16px;
    .q-field--with-bottom {
      padding-bottom: 0px;
    }
    .q-field__bottom {
      width: 170px;
    }
    @media (max-width: 767px) {
      .q-field__bottom {
        position: relative;
        padding: 0;
        margin-bottom: 5px;
      }
    }
  }
  .q-field__control .q-field__append + .q-field__append {
    border-left: 1px solid #e4e4e4;
    margin-left: 8px;
    padding-left: 8px;
  }

  .q-card__section {
    padding: 0;
  }
  .topHeader {
    padding: 20px 30px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 767px) {
      padding: 20px 15px;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: normal;
      color: #333333;
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    .back {
      .q-focus-helper {
        display: none;
      }
      .q-btn__wrapper {
        padding: 0 0 4px;
      }
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .middle {
    padding: 30px 15px;
    @media (max-width: 767px) {
      padding: 15px 0;
    }
    .addPaymentHeight {
      padding-left: 15px;
      padding-right: 15px;
      height: calc(100dvh - 207px);
      @media (max-width: 767px) {
        height: calc(100dvh - 175px);
      }
    }
    .paymentIcon {
      display: block;
      float: right;
      width: 40px;
    }
  }
  .bottom {
    padding: 10px 30px;
    border-top: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 767px) {
      padding: 10px 15px;
    }
    .q-btn {
      width: 180px;
      margin: 7px 0;
      padding: 5px 0;
      @media (max-width: 479px) {
        width: auto;
        padding: 5px 8px;
      }
    }
    .q-btn:last-child {
      float: right;
    }
    .q-btn + div {
      .q-btn {
        margin: 0;
      }
    }
    .q-btn,
    .q-btn + div {
      width: 180px;
      margin: 5px 0;
      @media (max-width: 479px) {
        width: auto;
        &.right {
          width: 160px;
        }
      }
    }
    .right {
      float: right;
    }
  }

  h4 {
    margin: 0;
    padding: 0;
    font-size: 17px;
    font-weight: 600;
    line-height: normal;
    color: #333333;
    min-height: 21px;
    span {
      display: inline-block;
      vertical-align: middle;
      background: #91bf5b;
      margin-left: 5px;
      padding: 3px 15px;
      font-size: 10px;
      color: #fff;
      border-radius: 10px;
    }
    @media (min-width: 1681px) {
      font-size: 20px;
    }
  }

  .manageBillingItems {
    > span {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      margin-left: -8px;
      margin-right: -8px;
    }

    .billingItem {
      padding: 8px;
      flex: 0 0 100%;
      width: 100%;
      @media (min-width: 600px) {
        flex: 0 0 50%;
        width: 50%;
      }
      .billingInner {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 1px;
        border: 1px solid #cccccc;
        height: 100%;
        min-height: 90px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        &.active {
          background: #fff;
          border-color: $primary;
          border-color: var(--q-color-primary) !important;
          box-shadow: 0 0 0 1px $primary;
          box-shadow: 0 0 0 1px var(--q-color-primary);
        }
      }
      .billingMiddle {
        padding: 15px;
        flex: 1 0 0;
        .billingSelect {
          position: absolute;
          right: 5px;
          top: 5px;
        }
        h3 {
          margin: 0;
          padding: 0;
          padding-right: 24px;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          color: #314154;
          min-height: 21px;
          span {
            display: inline-block;
            vertical-align: middle;
            background: #91bf5b;
            margin-left: 5px;
            padding: 3px 15px;
            font-size: 8px;
            color: #fff;
            border-radius: 10px;
          }
          @media (min-width: 1681px) {
            font-size: 20px;
          }
        }
        h5 {
          font-size: 13px;
          font-weight: 600;
          color: #314154;
          margin: 5px 0 0;
          padding: 0;
        }
        p {
          margin: 5px 0 0;
          padding: 0;
          font-size: 13px;
          line-height: 1.5;
          color: #888888;
          @media (min-width: 1681px) {
            font-size: 16px;
          }
        }
        .toggle {
          margin-top: 15px;
          .q-toggle__label {
            font-size: 12px;
            color: #0f141a;
          }
        }
      }
      .quickAction {
        display: block;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 20px;
        color: #888888;
        background: #eeeeee;
        position: absolute;
        right: 20px;
        top: 20px;
        border-radius: 50%;
        svg {
          width: 10px;
          height: 20px;
          transition: transform 0.3s ease-in-out;
        }
        &.active {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
      .billingSwitch {
        margin-top: 10px;
        min-height: 15px;
        font-size: 13px;
        color: #0f141a;
      }
      .billingBottom {
        border-top: 1px solid #cccccc;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        a,
        button {
          flex: 1 0 50%;
          border-radius: 0;
        }
        a {
          padding: 10px;
          font-size: 14px;
          text-align: center;
          text-decoration: none;
          border-left: 1px solid #ccc;
          @media (min-width: 1681px) {
            padding: 15px;
          }
          &:first-child {
            border-left: none;
          }
          svg {
            width: 20px;
            height: 20px;
          }
          &.edit {
            color: $primary;
            color: var(--q-color-primary) !important;
          }

          &.disable {
            color: #888888;
            pointer-events: none;
          }
        }
      }
      &.addbillingItem {
        flex: 0 0 100%;
        width: 100%;
        .billingInner {
          text-align: center;
          background: #f2fbff;
          background: var(--q-color-light-primary);
          border-color: #99c2d2;
          border-color: var(--q-color-primary);
        }
        .billingMiddle {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $tertiary;
          font-weight: bold;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
