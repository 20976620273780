<template>
  <ftx-dialog
    :dialog="dialog"
    :beforeShow="beforeShow"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="false"
  >
    <template v-slot:DialogContent>
      <q-card
        style="width: 100%"
        class="simpleDialog no-bottom-radius tipDialog"
        v-if="currentCartDetails.orderSummary"
      >
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          class="absolute-top-right q-mt-md q-mr-md"
        />
        <!-- Tip Option Selection -->
        <div class="container">
          <div class="row q-col-gutter-md justify-center">
            <div class="items-center">
              <div class="row q-col-gutter-md">
                <div class="col-auto">
                  <SvgIcon size="64px" color="secondary" icon="tip" />
                </div>
              </div>
            </div>
            <div>
              <h2>Tips are welcome!</h2>
            </div>
            <div class="col-12">
              <div class="tipOption-items q-mt-lg">
                <div
                  class="tip-item"
                  v-for="(tipOption, index) of tipOptions"
                  :key="index"
                >
                  <q-btn
                    unelevated
                    :class="[{ active: selectedIndex == index }]"
                    no-caps
                    @click="
                      selectTip(index, tipOption.percentage, tipOption.doller)
                    "
                  >
                    <span class="full-width text-h6 text-weight-bold text-dark"
                      >{{ tipOption.percentage }}%</span
                    >
                    <small class="full-width">
                      {{ tipOption.doller | currency }}
                    </small>
                  </q-btn>
                </div>
                <div class="tip-item">
                  <q-currency-input
                    class="active"
                    v-model="customTip"
                    :options="{
                      distractionFree: false,
                      currency: 'USD',
                      locale: 'en',
                    }"
                    placeholder="0"
                    ref="customTipRef"
                    v-if="isCustomTipOpened"
                    borderless
                    @input="updateCustomTip"
                  />
                  <!-- <q-input
                    borderless
                    v-model.number="customTip"
                    placeholder="0"
                    @input="updateCustomTip"
                    class="active"
                    type="number"
                    ref="customTipRef"
                    v-if="isCustomTipOpened"
                  /> -->
                  <q-btn
                    unelevated
                    no-caps
                    class="tipCustom"
                    v-else
                    @click="openCustomTip"
                  >
                    {{ customTip | currency }}
                    <small class="full-width">Custom</small>
                  </q-btn>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="row justify-center item-start text-center q-mt-sm"
                :class="isMobile ? ' q-col-gutter-sm' : 'q-col-gutter-md'"
              >
                <div class="col-auto">
                  <div
                    class="text-weight-bold"
                    :class="isMobile ? 'text-h6' : 'text-h5'"
                  >
                    {{ currentCartDetails.orderSummary.subTotal | currency }}
                  </div>
                  <div class="text-body2">Sub Total</div>
                </div>
                <div class="col-auto">
                  <div
                    class="text-weight-bold"
                    :class="isMobile ? 'text-h6' : 'text-h5'"
                    >+</div
                  >
                  <div class="text-body2"></div>
                </div>
                <div class="col-auto">
                  <div
                    class="text-weight-bold"
                    :class="isMobile ? 'text-h6' : 'text-h5'"
                  >
                    {{ selectedTipDoller | currency }}
                  </div>
                  <div class="text-body2">Tip</div>
                </div>
                <div class="col-auto">
                  <div
                    class="text-weight-bold"
                    :class="isMobile ? 'text-h6' : 'text-h5'"
                    >=</div
                  >
                  <div class="text-body2"></div>
                </div>
                <div class="col-auto">
                  <div
                    class="text-weight-bold"
                    :class="isMobile ? 'text-h6' : 'text-h5'"
                    >{{ finalSubtotal | currency }}</div
                  >
                  <div class="text-body2">Total</div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row q-col-gutter-md q-mt-sm">
                <div class="col-12 col-md-12 text-center">
                  <div class="text-caption"
                    ><SvgIcon
                      size="16px"
                      icon="user-check"
                      class="q-mr-sm"
                    />100% of your tip will be transferred to the person working
                    on your order</div
                  >
                </div>
                <div class="col-6 col-md-6 text-right">
                  <div class="form-action pb-none">
                    <q-btn
                      unelevated
                      outline
                      no-caps
                      v-close-popup
                      :label="
                        properties.action == 'edit'
                          ? 'No Change in Tip'
                          : 'No Tip'
                      "
                      class="q-btn-lg full-width radius-lg"
                    />
                  </div>
                </div>
                <div class="col-6 col-md-6 text-left">
                  <div class="form-action pb-none">
                    <q-btn
                      unelevated
                      no-caps
                      :disable="!selectedTipPercentage"
                      color="secondary"
                      :label="
                        properties.action == 'edit' ? 'Update Tip' : 'Add Tip'
                      "
                      @click="addTip"
                      class="q-btn-lg full-width radius-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: 'TipDialog',
      tipOptions: [],
      isCustomTipOpened: false,
      selectedTipDoller: 0,
      selectedTipPercentage: 0,
      subTotal: 0,
      finalTotal: 0,
      customTip: null,
      selectedIndex: -1,
    }
  },

  computed: {
    ...mapGetters('common', ['businessFulfillments']),
    ...mapGetters('cart', ['currentCartDetails']),
    finalSubtotal() {
      return (
        this.selectedTipDoller + this.currentCartDetails.orderSummary.subTotal
      ).toFixed(2)
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },

  methods: {
    beforeShow() {
      this.selectedIndex = -1
      this.selectedTipDoller = 0
      this.selectedTipPercentage = 0
      this.customTip = null
      this.isCustomTipOpened = false
      this.tipOptions = []
      const selectedFulfillment =
        this.businessFulfillments[this.selectedFulfillmentCodeName]
      if (selectedFulfillment.isTipEnabled) {
        if (selectedFulfillment.tipOption1) {
          this.tipOptions.push({
            percentage: selectedFulfillment.tipOption1,
            doller: this.percentToDoller(selectedFulfillment.tipOption1),
          })
        }
        if (selectedFulfillment.tipOption2) {
          this.tipOptions.push({
            percentage: selectedFulfillment.tipOption2,
            doller: this.percentToDoller(selectedFulfillment.tipOption2),
          })
        }
        if (selectedFulfillment.tipOption3) {
          this.tipOptions.push({
            percentage: selectedFulfillment.tipOption3,
            doller: this.percentToDoller(selectedFulfillment.tipOption3),
          })
        }
        if (selectedFulfillment.tipOption4) {
          this.tipOptions.push({
            percentage: selectedFulfillment.tipOption4,
            doller: this.percentToDoller(selectedFulfillment.tipOption4),
          })
        }
      }
      // tip sort
      this.tipOptions.sort((a, b) => {
        return a.percentage > b.percentage ? 1 : -1
      })

      if (this.properties.tipDoller) {
        let tipIndex = -1
        for (let key in this.tipOptions) {
          if (this.tipOptions[key].doller == this.properties.tipDoller) {
            tipIndex = key
            this.isCustomTipOpened = false
            this.selectedTipPercentage = this.tipOptions[key].percentage
            this.selectedTipDoller = this.properties.tipDoller
          }
        }
        this.selectedIndex = tipIndex
        if (tipIndex == -1) {
          this.isCustomTipOpened = true
          this.customTip = this.properties.tipDoller
          this.selectedTipDoller = this.properties.tipDoller
        } else {
          this.customTip = null
        }
      }
    },
    openCustomTip() {
      setTimeout(() => {
        this.$refs.customTipRef.$refs.input.focus()
      }, 0)
      this.isCustomTipOpened = !this.isCustomTipOpened
      if (this.isCustomTipOpened) {
        this.selectedIndex = 5
        if (this.customTip) {
          this.selectedTipPercentage = this.customTip
          this.selectedTipDoller = this.customTip
        } else {
          this.selectedTipDoller = 0
          this.selectedTipPercentage = 0
        }
      }
    },
    selectTip(index, percentage, doller) {
      this.selectedTipDoller = doller
      this.selectedTipPercentage = percentage
      this.isCustomTipOpened = false
      this.selectedIndex = index
    },
    updateCustomTip() {
      if (this.customTip) {
        this.selectedTipDoller = this.customTip
        this.selectedTipPercentage = this.customTip
      } else {
        this.selectedTipDoller = 0
        this.selectedTipPercentage = 0
      }
    },
    percentToDoller(percentage) {
      const result = parseFloat(
        (
          (percentage / 100) *
          this.currentCartDetails?.orderSummary?.subTotal
        ).toFixed(2)
      )
      return result
    },
    addTip() {
      let body = {
        orderID: this.currentCartDetails.orderID,
        tipOptionValue: this.selectedTipPercentage,
        isCustomOption: this.isCustomTipOpened,
      }

      /* Start: Guest Checkout Changes */
      if (!this.isLoggedIn && this.currentCartDetails?.orderNumber)
        body.orderNumber = this.currentCartDetails.orderNumber
      /* End: Guest Checkout Changes */

      const sucessDesc = this.properties.action == 'edit' ? 'Updated' : 'Added'
      this.$store
        .dispatch('cart/applyTip', body)
        .then((res) => {
          if (res.success) {
            this.showSuccess(`Tip ${sucessDesc} Successfully.`)
          }
        })
        .catch((er) => {
          console.log('error', er)
        })
        .finally(() => {
          this.changeDialogState({
            dialog: this.dialog,
            val: false,
          })
        })
    },
  },
}
</script>
