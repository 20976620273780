<template>
  <q-dialog
    v-model="dialogState"
    @before-show="beforeShow"
    position="bottom"
    maximized
  >
    <q-card class="mobileCardDialogue">
      <q-item-label class="mobileSortFilter">
        <h5 class="text-h5">Sort by</h5>
        <q-icon
          class="close cursor-pointer"
          name="close"
          @click="
            changeDialogState({
              dialog: 'priceSortDialog',
              val: false,
            })
          "
        />
      </q-item-label>
      <q-option-group
        class="q-pa-md"
        :options="sortByOptions"
        type="radio"
        :value="sortBy"
        @input="(val) => sortByModified(val)"
      />
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PriceSortBy',
  data() {
    return {
      sortBy: null,
      pageName: '',
    }
  },
  computed: {
    ...mapGetters('product', ['sortByOptions']),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    dialogState: {
      get() {
        return this.getDialogState('priceSortDialog')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'priceSortDialog',
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties('priceSortDialog')
    },
  },
  methods: {
    sortByModified(value) {
      this.$emit('sorby-modified', value)
      this.dialogState = false
    },
    beforeShow() {
      if (this.properties.productsBy == 'CL') {
        this.pageName = 'collection'
      } else if (this.properties.productsBy == 'CT') {
        this.pageName = 'category'
      } else if (this.properties.productsBy == 'DT') {
        this.pageName = 'discount'
      } else if (this.properties.productsBy == 'SK') {
        this.pageName = 'search'
      }

      if (this.pageName) {
        let filters = this.getFilterByPage(this.pageName)
        this.sortBy = filters?.sortBy || 'Price Low to High'
      } else {
        this.sortBy = 'Price Low to High'
      }
    },
  },
}
</script>
