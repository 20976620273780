<template>
  <q-dialog v-model="dialogState" position="bottom" maximized>
    <q-card class="mobileCardDialogue">
      <q-item-label class="mobileSortFilter">
        <h5 class="text-h5">Discounts</h5>
        <q-icon class="close cursor-pointer" name="close" v-close-popup />
      </q-item-label>
      <div class="q-py-sm q-px-md filterBar">
        <DiscountList />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import DiscountList from './DiscountList'

export default {
  name: 'ExploreDiscount',
  components: { DiscountList },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState('ExploreDiscount')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'ExploreDiscount',
          val,
        })
      },
    },
  },
}
</script>
