<template>
  <div class="flex">
    <q-btn
      icon="grid_view"
      outline
      unelevated
      dense
      @click="toggleView('grid')"
      :color="!isListView ? 'primary' : ''"
      title="Grid View"
    />
    <q-btn
      icon="format_list_bulleted"
      outline
      unelevated
      dense
      class="q-ml-sm"
      @click="toggleView('list')"
      :color="isListView ? 'primary' : ''"
      title="List View"
    />
  </div>
</template>

<script>
export default {
  name: 'ProductLayoutIcons',
  props: {
    isListView: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleView(view) {
      this.$emit('changeView', view == 'list')
    },
  },
}
</script>
