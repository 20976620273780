<template>
  <!-- Product Listing Loader -->
  <!-- <div> -->
  <div class="q-product-loader product-items see-all-product full-width">
    <q-card
      flat
      bordered
      class="product-items--card q-width q-pa-sm"
      :class="{ 'list-view-skeleton': isListView }"
      v-for="n in size"
      :key="n"
    >
      <q-skeleton
        width="100%"
        height="180px"
        class="q-mb-md product-thumb-skeleton"
      />
      <div class="product-info-skeleton">
        <q-card-section
          class="row no-wrap q-pt-sm q-pl-none q-pr-none q-pb-none"
        >
          <div class="col">
            <q-skeleton
              width="60%"
              height="15px"
              class="product-skeleton-top"
            />
          </div>
          <div class="col-1 text-right">
            <q-skeleton
              width="15px"
              height="15px"
              class="product-skeleton-fav"
            />
          </div>
        </q-card-section>
        <q-card-section class="q-pt-sm q-pl-none q-pr-none q-pb-none">
          <q-skeleton
            type="text"
            width="100%"
            class="text-caption q-mt-sm product-skeleton-title"
          />
          <q-skeleton type="text" width="30%" class="text-caption" />
        </q-card-section>

        <q-card-section
          class="q-pt-none q-pl-none q-pr-none q-pb-none product-skeleton-desc"
        >
          <q-skeleton width="40%" height="15px" class="q-mt-md" />
        </q-card-section>

        <q-card-actions class="q-pt-md q-pl-none q-pr-none q-pb-none">
          <q-skeleton width="100%" type="QBtn" class="product-skeleton-btn" />
        </q-card-actions>
      </div>
    </q-card>
  </div>
  <!-- </div> -->
  <!-- Product Listing Loader -->
</template>
<script>
export default {
  name: "ProductSkeleton",
  props: {
    size: {
      type: Number,
      default: 8,
    },
    isListView: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
body {
  .product-items .product-items--card.list-view-skeleton {
    width: 100%;
    min-height: 200px;
    display: flex;
    .product-thumb-skeleton {
      width: 100%;
      min-width: 200px;
      max-width: 200px;
      @media (max-width: 767px) {
        min-width: 150px;
        max-width: 150px;
      }
      @media (max-width: 360px) {
        min-width: 100px;
        max-width: 100px;
      }
    }
    .product-info-skeleton {
      margin-left: 15px;
      width: 100%;
    }
    .product-skeleton-top {
      max-width: 100px;
    }
    .product-skeleton-fav {
      float: right;
    }
    .product-skeleton-title {
      max-width: 40%;
    }
    .product-skeleton-btn {
      max-width: 200px;
    }
  }
}
</style>
