import { render, staticRenderFns } from "./OrderSummaryContent.vue?vue&type=template&id=1569d4f0"
import script from "./OrderSummaryContent.vue?vue&type=script&lang=js"
export * from "./OrderSummaryContent.vue?vue&type=script&lang=js"
import style0 from "./OrderSummaryContent.vue?vue&type=style&index=0&id=1569d4f0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QSeparator,QTooltip,QBanner,QForm,QInput,QCheckbox,QList,QExpansionItem,QItemSection,QCard,QCardSection,QIcon,QField});
