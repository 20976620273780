<template>
  <q-input
    :readonly="readonly"
    :outlined="outlined"
    :clearable="clearable"
    :filled="filled"
    :placeholder="placeholder"
    :label="label"
    ref="AutoCompleteInput"
    autocomplete="off"
    :value="value"
    @input="(value) => this.addressFieldChange(this.modelField, value)"
    :rules="rules"
    :lazy-rules="lazyValidationRules"
    aria-label="ftxcommerce"
  />
</template>
<script>
export default {
  name: 'GoogleAutoComplete',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {},
    },
    placechanged: {
      type: Function,
      default: null,
    },
    disableGoogleAutoComplete: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modelField: {
      type: String,
      default: '',
    },
    addressFieldChange: {
      type: Function,
      default: () => {},
    },
    value: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (
      !this.disableGoogleAutoComplete &&
      typeof google !== 'undefined' &&
      google.hasOwnProperty('maps')
    ) {
      let addressInput = this.$refs.AutoCompleteInput.$refs.input

      var autocomplete = new google.maps.places.Autocomplete(addressInput, {
        types: ['geocode'],
        placeholder: '',
        componentRestrictions: { country: 'us' },
      })

      google.maps.event.addDomListener(addressInput, 'keydown', (event) => {
        if (event.keyCode == 13) {
          if (event.preventDefault) {
            event.preventDefault()
          } else {
            // Since the google event handler framework does not handle early IE versions, we have to do it by our self. : -(
            event.cancelBubble = true
            event.returnValue = false
          }
        }
      })

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      autocomplete.setFields([
        'address_component',
        'geometry',
        'formatted_address',
        // 'name',
        // 'place_id'
      ])

      autocomplete.addListener('place_changed', () => {
        var place = autocomplete.getPlace(),
          { address_components, geometry, formatted_address } = place

        if (!address_components) return

        if (formatted_address)
          this.addressFieldChange(
            this.modelField,
            formatted_address.replace(', usa', '').replace(', USA', '')
          )

        if (this.placechanged && typeof this.placechanged === 'function') {
          this.placechanged({
            addressData: address_components,
            geoLocation: geometry.location
              ? {
                  latitude: parseFloat(geometry.location.lat().toFixed(6)),
                  longitude: parseFloat(geometry.location.lng().toFixed(6)),
                }
              : null,
          })
        }
      })
    }
  },
}
</script>
