<template>
  <div class="locationList nearby-location-list" v-if="loading">
    <LocationSkeleton :skeletonSize="1" />
  </div>
  <div
    class="nearby-location-list"
    v-else-if="!loading && Object.keys(locations).length"
  >
    <div class="nearby-location-title text-subtitle text-weight-bold text-dark">
      In Stock at a Nearby Location!
    </div>
    <!-- <q-list class="locationList product-detail-location">
      <q-item v-for="(location, key) of locations" :key="key">
        <q-item-section>
          <div class="row wrap items-center">
            <div class="col-12 col-md-12 hlocationListDetail">
              <div class="row q-mb-sm">
                <div class="col-xs-8 title">
                  {{ location.name }}
                </div>
                <div class="col-xs-4 text-right" v-if="location.distance">
                  {{ location.distance.toFixed(2) }} mi
                </div>
              </div>

              <q-item-label caption class="q-mb-sm">
                <div class="row items-end full-width">
                  <div
                    class="col-8"
                    v-html="$options.filters.formatAddress(location.address)"
                  />
                  <div class="col-4 text-right">
                    <div class="product-price">
                      <span class="price">
                        {{ location.productLocationPrice | currency }}
                      </span>
                    </div>
                  </div>
                </div>
              </q-item-label>

              <div
                class="col-xs-12 col-lg-7 q-mt-xs-md q-mt-lg-none location-addtocart"
              >
                <q-btn
                  unelevated
                  dense
                  no-caps
                  flat
                  text-color="secondary"
                  label="Set as my store and add to cart"
                  padding="0"
                  size="15px"
                  class="no-hover text-underline text-weight-bold"
                  @click="setAsMyStoreAndAddToCart(location)"
                />
              </div>
            </div>
          </div>
        </q-item-section>
      </q-item>
    </q-list> -->
    <div class="storeDetailNearByCarousel">
      <q-carousel
        transition-prev="slide-right"
        transition-next="slide-left"
        v-model="slide"
        ref="carousel"
        height="300px"
      >
        <q-carousel-slide
          :name="index"
          v-for="(location, key, index) of locations"
          :key="index"
          class="q-pa-sm"
        >
          <q-card class="s-1 r-8 q-ma-sm">
            <q-card-section>
              <div class="row wrap items-center">
                <div class="col-12 col-md-12">
                  <div class="row q-mb-sm">
                    <div class="col-8 title text-dark text-weight-bold">
                      {{ location.name }}
                    </div>
                    <div class="col-4 text-right" v-if="location.distance">
                      {{ location.distance.toFixed(2) }} mi
                    </div>
                  </div>

                  <q-item-label caption>
                    <div class="row items-end full-width">
                      <div
                        class="col-8 col-sm-6 col-lg-4 text-dark text-subtitle2"
                      >
                        <div
                          v-html="
                            $options.filters.formatAddress(location.address)
                          "
                        />
                        <div class="hlocationListIcon">
                          <span
                            v-for="(
                              fulFillment, index
                            ) in location.fulFillments.filter(
                              (fulFillment) =>
                                fulFillment.available &&
                                !fulFillment.configuration.isComingSoon
                            )"
                            :key="`${fulFillment.fulfillmentMethodID}-${index}`"
                          >
                            <SvgIcon :icon="fulFillment.codeName" />
                            <q-tooltip
                              max-width="180px"
                              content-class="bg-dark"
                              :offset="[0, 10]"
                            >
                              {{ fulFillment.displayName }}
                            </q-tooltip>
                          </span></div
                        >
                      </div>
                      <div
                        class="col-4 col-sm-6 col-lg-8 text-right"
                        v-if="$q.screen.lt.sm"
                      >
                        <div class="product-price">
                          <span class="price">
                            {{ location.productLocationPrice | currency }}
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-lg-8 text-right">
                        <div class="product-price" v-if="!$q.screen.lt.sm">
                          <span class="price">
                            {{ location.productLocationPrice | currency }}
                          </span>
                        </div>
                        <div
                          :class="
                            $q.screen.lt.sm
                              ? 'text-center q-pt-md'
                              : 'text-right'
                          "
                        >
                          <q-btn
                            unelevated
                            dense
                            no-caps
                            flat
                            text-color="secondary"
                            label="Set as my store and add to cart"
                            padding="0"
                            size="15px"
                            class="no-hover text-underline text-weight-bold"
                            @click="setAsMyStoreAndAddToCart(location)"
                          />
                        </div>
                      </div>
                    </div>
                  </q-item-label>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-carousel-slide>
        <template v-slot:control v-if="Object.keys(locations).length != 1">
          <q-carousel-control
            position="top-right"
            :offset="[0, 0]"
            class="q-gutter-xs"
          >
            <q-btn
              unelevated
              round
              dense
              color="secondary"
              icon="chevron_left"
              class="q-mr-sm"
              :disabled="!hasPrevious"
              @click="$refs.carousel.previous()"
            />
            <q-btn
              unelevated
              round
              dense
              color="secondary"
              icon="chevron_right"
              :disabled="!hasNext"
              @click="$refs.carousel.next()"
            />
          </q-carousel-control>
        </template>
      </q-carousel>
    </div>
  </div>
</template>

<script>
import { hideShowFullSpinner, cartHasFulfillment, reloadPage } from 'src/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'NearbyLocationList',
  data() {
    return {
      slide: 0,
    }
  },
  components: {
    LocationSkeleton: () => import('components/location/LocationSkeleton'),
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    locations: {
      type: Object,
      default: () => {
        return new Object({})
      },
    },
    productID: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('cart', ['currentCartDetails', 'cartTotalItems']),
    ...mapGetters('persisted', [
      'selectedFulfillment',
      'selectedFulfillmentCodeName',
    ]),
    hasNext() {
      return !(this.count - 1 == this.slide)
    },
    hasPrevious() {
      return this.slide >= 1
    },
    count() {
      return Object.keys(this.locations).length
    },
  },
  methods: {
    getFulfillmentId(location) {
      let fulfillmentId = ''
      if (
        this.cartTotalItems &&
        cartHasFulfillment(this.currentCartDetails, 'is')
      ) {
        for (const fId in this.currentCartDetails.fulfillments) {
          const fulfillment = this.currentCartDetails.fulfillments[fId]
          if (fulfillment.codeName == 'is') {
            fulfillmentId = fulfillment.fulfillmentMethodID
            break
          }
        }
      } else if (
        this.cartTotalItems &&
        cartHasFulfillment(this.currentCartDetails, 'cs')
      ) {
        for (const fId in this.currentCartDetails.fulfillments) {
          const fulfillment = this.currentCartDetails.fulfillments[fId]
          if (fulfillment.codeName == 'cs') {
            fulfillmentId = fulfillment.fulfillmentMethodID
            break
          }
        }
      } else if (
        this.cartTotalItems &&
        cartHasFulfillment(this.currentCartDetails, 'scd')
      ) {
        for (const fId in this.currentCartDetails.fulfillments) {
          const fulfillment = this.currentCartDetails.fulfillments[fId]
          if (fulfillment.codeName == 'scd') {
            fulfillmentId = fulfillment.fulfillmentMethodID
            break
          }
        }
      } else if (
        this.cartTotalItems &&
        cartHasFulfillment(this.currentCartDetails, 'sd')
      ) {
        for (const fId in this.currentCartDetails.fulfillments) {
          const fulfillment = this.currentCartDetails.fulfillments[fId]
          if (fulfillment.codeName == 'sd') {
            fulfillmentId = fulfillment.fulfillmentMethodID
            break
          }
        }
      }

      let fulFillmentIdTemp = ''
      if (fulfillmentId) {
        fulFillmentIdTemp = fulfillmentId
      } else {
        location.fulFillments.forEach((fulFillment) => {
          if (fulFillment.codeName == this.selectedFulfillmentCodeName) {
            fulFillmentIdTemp = fulFillment && fulFillment?.fulfillmentMethodID
            return
          }
        })
      }

      return fulFillmentIdTemp
    },
    setAsMyStoreAndAddToCart: async function (location) {
      let dataFulfillment = null,
        fulFillments = []
      for (let fulFillment of location.fulFillments) {
        if (fulFillment.codeName == this.selectedFulfillmentCodeName)
          dataFulfillment = fulFillment
        if (fulFillment.available && fulFillment?.configuration?.isComingSoon)
          fulFillments.push(fulFillment)
      }

      if (
        (fulFillments &&
          fulFillments[0] &&
          fulFillments[0]?.configuration?.isComingSoon) ||
        (dataFulfillment && dataFulfillment?.available === false)
      ) {
        this.showError(
          'The fulfillment method is unavailable for your selected location (store) currently.'
        )
      } else {
        hideShowFullSpinner(true)
        let totalItems = this.cartTotalItems
        await this.$store.commit('location/SET_CHANGE_LOCATION_ADD_TO_CART', {
          fulfillmentMethodId: this.getFulfillmentId(location),
          products: [
            {
              productId: this.productID,
              qty: 1,
            },
          ],
        })
        await this.$store
          .dispatch('location/setAsMyStore', {
            location: location,
            fulfillment: this.selectedFulfillment,
            forceReload: false,
          })
          .then(() => {
            hideShowFullSpinner(false)
            /* if (!this.isLoggedIn) return this.loginWithIdentity()
            else if (!this.cartTotalItems) window.location.reload() */
            if (!totalItems) window.location.reload()
          })
      }
    },
  },
}
</script>

<style lang="scss">
.productDetail {
  .nearby-location-list {
    margin-top: 25px;
    @media (max-width: 767px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .nearby-location-title {
      margin-bottom: 10px;
    }
    .product-price {
      margin-bottom: 0;
    }
    .hlocationListIcon {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      > span {
        cursor: pointer;
        &.comingsoon {
          text-align: center;
          min-width: 80px;
          i {
            display: block;
            margin: 3px auto;
          }
        }
      }
      i {
        width: 16px;
        height: 16px;
        margin: 3px;
        padding: 5px;
        font-size: 10px;
        color: $primary;
        color: var(--q-color-primary) !important;
        border: 1px solid $primary;
        border: 1px solid var(--q-color-primary) !important;
        border-radius: 50%;
        @media (max-width: 1023px) {
          width: 12px;
          height: 12px;
          margin: 2px;
          padding: 5px;
          svg {
            width: 8px;
          }
        }
        svg {
          width: 12px;
          fill: currentColor;
        }
      }
    }
  }
}
.nearby-location-list {
  .locationList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    .location-badge {
      margin-right: 10px;
      padding: 5px 15px;
      font-size: 14px;
      line-height: normal;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      color: #008a03 !important;
      border: 1px solid currentColor;
      border-radius: 15px;
    }
    .q-item {
      border-radius: 24px;
      flex: 0 1 100%;
      width: 100%;
      padding: 15px;
      @media (min-width: 860px) {
        flex: 1 0 100%;
        width: 100%;
      }
      @media (min-width: 1366px) {
        flex: 1 0 100%;
        width: 100%;
      }

      .locationListInner {
        position: relative;
        flex-direction: row;
        padding: 15px;
        background: #fafafa;
        border: 1px solid #cccccc;
        border-radius: 5px;
        @media (min-width: 860px) {
          padding: 30px;
        }
        .locationListTitle {
          padding-bottom: 15px;
          font-size: 20px;
          font-weight: bold;
          color: #0f141a;
          @media (max-width: 850px) {
            padding-right: 45px;
            font-size: 18px;
          }
          @media (min-width: 1681px) {
            font-size: 24px;
          }
          span {
            margin: 0 10px;
            padding: 5px 15px;
            font-size: 14px;
            line-height: normal;
            font-weight: bold;
            display: inline-block;
            vertical-align: middle;
            color: #008a03;
            border: 1px solid currentColor;
            border-radius: 15px;
          }
        }
        .text-caption {
          padding-bottom: 15px;
          font-size: 14px;
          color: #0f141a;
          @media (min-width: 1681px) {
            font-size: 16px;
          }
        }
        .q-btn--flat {
          font-size: 16px;
        }
        .q-item__section--side {
          font-size: 16px;
          color: #0f141a;
          @media (min-width: 1366px) {
            font-size: 20px;
          }
          @media (min-width: 1681px) {
            font-size: 24px;
          }
          @media (max-width: 850px) {
            position: absolute;
            top: 20px;
            right: 10px;
            font-size: 12px;
          }
        }
      }
    }

    &.q-list {
      margin: 0 -1%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .q-item__section {
        justify-content: flex-start;
        height: 100%;
        position: relative;
        padding-bottom: 0;
        .hlocationListAction {
          position: absolute;
          bottom: 0;
        }
      }
    }
    .full {
      &.q-item {
        width: 98%;
      }
    }

    .q-item {
      margin: 10px 1%;
      margin-top: 0;
      padding: 6px 12px;
      flex: 0 1 98% !important;
      width: 98% !important;
      border: none;
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
      border-radius: 9px;
      @media (min-width: 600px) {
        width: 100%;
        flex: 0 1 100%;
      }
      @media (min-width: 1024px) {
        width: 100%;
        flex: 0 1 100%;
      }
      .q-item__section--avatar {
        min-width: 30px;
      }
      .readLocation {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
      @media (min-width: 1681px) {
        padding: 4px 16px;
      }
      &.disabled {
        .hlocationListDetail {
          .q-item__label {
            font-weight: normal;
            color: #ec7a7a;
          }
        }
      }
      .q-btn {
        font-size: 14px;
      }
    }
    .hlocationListDetail {
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #0f141a;
        @media (min-width: 1681px) {
          font-size: 16px;
        }
        span {
          margin: -3px 10px 0;
          padding: 3px 12px;
          font-size: 11px;
          line-height: normal;
          font-weight: bold;
          display: inline-block;
          vertical-align: middle;
          color: #008a03;
          border: 1px solid currentColor;
          border-radius: 15px;
        }
      }
      .text-right {
        font-size: 13px;
        color: #0f141a;
        @media (min-width: 1681px) {
          font-size: 15px;
        }
      }
      .text-caption {
        font-size: 12px;
        line-height: 1.5 !important;
        font-weight: normal;
        color: #0f141a;
        @media (min-width: 1681px) {
          font-size: 14px;
        }
      }
      .price {
        font-size: 18px;
      }
    }
    .hlocationListIcon {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      > span {
        cursor: pointer;
        &.comingsoon {
          text-align: center;
          min-width: 80px;
          i {
            display: block;
            margin: 3px auto;
          }
        }
      }
      i {
        width: 16px;
        height: 16px;
        margin: 3px;
        padding: 5px;
        font-size: 10px;
        color: $primary;
        color: var(--q-color-primary) !important;
        border: 1px solid $primary;
        border: 1px solid var(--q-color-primary) !important;
        border-radius: 50%;
        @media (max-width: 1023px) {
          width: 12px;
          height: 12px;
          margin: 2px;
          padding: 5px;
          svg {
            width: 8px;
          }
        }
        svg {
          width: 12px;
          fill: currentColor;
        }
      }
    }
  }
}
.storeDetailNearByCarousel {
  .q-carousel {
    height: auto;
    overflow: inherit;
    margin-left: -8px;
    margin-right: -8px;
    .q-carousel__slides-container {
      overflow: hidden;
      .q-panel.scroll {
        overflow: hidden !important;
      }
    }
    .q-carousel__slide {
      padding: 0;
    }
    .q-carousel__control {
      .q-btn {
        margin-top: -55px;
      }
    }
  }
}
</style>
