var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.businessConfigurations.enableSubstitution)?_c('div',{staticClass:"orderSubscription"},[_c('q-item',{attrs:{"manual-focus":"","tag":"label"},on:{"click":function($event){$event.preventDefault();}}},[_c('q-item-section',{attrs:{"avatar":"","top":""}},[_c('q-checkbox',{attrs:{"color":"secondaryOnBody","value":_vm.substitutionAllowed},on:{"input":_vm.substitutionToggle}})],1),_c('q-item-section',[_c('q-item-label',[_vm._v(" Allow Substitutions ")]),_c('q-item-label',{attrs:{"caption":""}},[_vm._v("\n        Want to make sure you'll have everything you need? Allow substitutions\n        and we'll do our best to replace any unavailable items.\n      ")])],1)],1),(
      !_vm.isMobile &&
      ((_vm.isFor == 'order' &&
        _vm.orderInfo.appliedLnsCoupons &&
        _vm.orderInfo.appliedLnsCoupons.length) ||
        (_vm.isFor == 'cart' && _vm.currentCartDetails.appliedLnsCoupons?.length))
    )?_c('p',{staticStyle:{"padding-top":"0 !important"}},[_vm._v("\n    *Coupons may be removed from the order if the applicable items are removed\n    either by editing the order or being out of stock.\n  ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }