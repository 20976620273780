<template>
  <div class="h-quick-location">
    <ul class="shpping-options" v-if="Object.keys(businessFulfillments).length">
      <li
        v-for="(deliveryOption, codeName) in businessFulfillments"
        :key="`${deliveryOption.fulfillmentID}-${codeName}`"
        @click="switchFulfillment(deliveryOption)"
        :class="deliveryOption.isComingSoon ? 'disabled' : ''"
      >
        <div
          class="shpping-options-thumb"
          :class="
            selectedFulfillment?.codeName === deliveryOption.codeName &&
            !deliveryOption.isComingSoon
              ? 'shpping-options-active'
              : ''
          "
        >
          <SvgIcon
            color="secondary"
            :icon="deliveryOption.codeName"
            size="20px"
          />
          <q-btn
            v-show="
              selectedFulfillment?.codeName === deliveryOption.codeName &&
              !deliveryOption.isComingSoon
            "
            color="secondary"
            size="6px"
            icon="check"
            dense
            rounded
            outline
            padding="4px"
            class="absolute-top-right bg-white"
          />
        </div>

        <span> {{ deliveryOption.fulfillmentName }} </span>
        <span class="block q-mt-sm q-mb-xs" v-if="deliveryOption.isComingSoon">
          <small class="bg-dark text-white q-py-xs q-px-sm r-8">
            Coming Soon!
          </small>
        </span>
      </li>
    </ul>

    <q-list
      class="bg-white shipping-options-store"
      v-if="selectedFulfillmentCodeName !== 'sp'"
    >
      <!-- -->
      <template v-if="selectedFulfillmentCodeName == 'scd'">
        <q-item
          class="q-my-sm items-center q-py-md"
          clickable
          v-if="askFullAddressForDelivery && selectedDeliveryAddress?.line1"
        >
          <q-item-section avatar>
            <q-avatar
              color=""
              class="shipping-options-store-thumb"
              text-color="primary"
            >
              <q-icon name="home"></q-icon>
            </q-avatar>
          </q-item-section>
          <q-item-section class="col">
            <!-- <q-item-label class="text-dark block text-bold">
            {{
              `${defaultAddress.firstName || ''} ${
                defaultAddress.lastName || ''
              }`
            }}
          </q-item-label> -->
            <q-item-label
              caption
              lines="1"
              class="text-grey-7"
              v-html="$options.filters.formatAddress(selectedDeliveryAddress)"
            />
          </q-item-section>
          <q-item-section side>
            <q-btn
              unelevated
              no-caps
              round
              dense
              color="primary"
              @click="switchFulfillment()"
            >
              <SvgIcon size=".8rem" icon="edit" />
            </q-btn>
          </q-item-section>

          <!-- <q-item-section side>
          <q-icon name="keyboard_arrow_right" color="grey" />
        </q-item-section> -->
        </q-item>
        <q-item
          v-else-if="selectedZipCode"
          class="q-my-sm items-center q-py-md"
          clickable
        >
          <q-item-section avatar>
            <q-avatar
              color=""
              class="shipping-options-store-thumb"
              text-color="primary"
            >
              <q-icon name="home"></q-icon>
            </q-avatar>
          </q-item-section>
          <q-item-section class="col">
            <!-- <q-item-label class="text-dark block text-bold">
            Add an address for delivery
          </q-item-label> -->
            <q-item-label caption lines="1" class="text-dark block text-bold">
              Your Location: {{ selectedZipCode }}
            </q-item-label>
            <q-item-label> </q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-btn
              unelevated
              no-caps
              round
              dense
              color="primary"
              @click="switchFulfillment()"
            >
              <SvgIcon size=".8rem" icon="edit" />
            </q-btn>
          </q-item-section>
        </q-item>
      </template>

      <q-item
        v-else-if="selectedFulfillmentCodeName !== 'scd' && selectedZipCode"
        class="q-my-sm items-center q-py-md"
        clickable
      >
        <q-item-section avatar>
          <q-avatar
            color=""
            class="shipping-options-store-thumb"
            text-color="primary"
          >
            <q-icon name="home"></q-icon>
          </q-avatar>
        </q-item-section>
        <q-item-section class="col">
          <!-- <q-item-label class="text-dark block text-bold">
            Add an address for delivery
          </q-item-label> -->
          <q-item-label caption lines="1" class="text-dark block text-bold">
            Your Location: {{ selectedZipCode }}
          </q-item-label>
          <q-item-label> </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            unelevated
            no-caps
            round
            dense
            color="primary"
            @click="switchFulfillment()"
          >
            <SvgIcon size=".8rem" icon="edit" />
          </q-btn>
        </q-item-section>
      </q-item>

      <q-item
        class="q-my-sm items-center q-py-md"
        clickable
        v-else-if="defaultAddress"
      >
        <q-item-section avatar>
          <q-avatar
            color=""
            class="shipping-options-store-thumb"
            text-color="primary"
          >
            <q-icon name="home"></q-icon>
          </q-avatar>
        </q-item-section>
        <q-item-section class="col">
          <q-item-label class="text-dark block text-bold">
            {{
              `${defaultAddress.firstName || ''} ${
                defaultAddress.lastName || ''
              }`
            }}
          </q-item-label>
          <q-item-label
            caption
            lines="1"
            class="text-grey-7"
            v-html="$options.filters.formatAddress(defaultAddress)"
          />
        </q-item-section>
        <q-item-section side>
          <q-btn
            unelevated
            no-caps
            round
            dense
            color="primary"
            @click="switchFulfillment()"
          >
            <SvgIcon size=".8rem" icon="edit" />
          </q-btn>
        </q-item-section>

        <!-- <q-item-section side>
          <q-icon name="keyboard_arrow_right" color="grey" />
        </q-item-section> -->
      </q-item>
      <!-- -->
    </q-list>
    <div
      class="row q-col-gutter-sm q-mt-sm"
      v-if="selectedFulfillmentCodeName != 'sd'"
    >
      <div class="col">
        <q-btn
          no-caps
          class="full-width"
          outline
          :color="isMobile ? 'primary' : 'white'"
          :to="{
            name: 'Location',
            params: { locationId: currentLocationId },
          }"
        >
          View Store
        </q-btn>
      </div>
      <div class="col">
        <q-btn
          no-caps
          flat
          :color="isMobile ? 'primary' : 'white'"
          class="full-width text-underline"
          @click="
            changeDialogState({
              dialog: 'changeLocation',
              val: true,
              parentDialog: parentDialog,
            })
          "
        >
          <SvgIcon class="q-mr-sm" size=".65rem" icon="edit" /> Change Store
        </q-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div
          class="row items-center"
          v-if="bookedSlot && selectedFulfillmentCodeName"
        >
          <div class="col-12 q-mt-md q-mb-md">
            <q-separator></q-separator>
          </div>
          <div class="col-9 col-md-9" :class="isMobile ? '' : 'text-white'">
            <SvgIcon
              className="q-mr-sm"
              :icon="selectedFulfillmentCodeName"
              size="1rem"
            />
            {{ bookedSlot }}
          </div>
          <div class="col-3 col-md-3 text-center">
            <q-btn
              unelevated
              no-caps
              flat
              dense
              padding="0"
              :color="isMobile ? 'primary' : 'white'"
              class="text-underline"
              @click="
                changeDialogState({
                  dialog: 'reserveTimeslot',
                  val: true,
                  properties: {
                    activeTab: selectedFulfillmentCodeName.toUpperCase(),
                  },
                })
              "
              >Change</q-btn
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <q-btn
      unelevated
      round
      flat
      dense
      @click="
        changeDialogState({
          dialog: 'deliveryInfo',
          val: true,
        })
      "
      class="infoDialogff"
      ><q-icon name="error_outline" size="1rem"
    /></q-btn> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    parentDialog: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('reserveSlot', ['formatBookedSlot']),
    ...mapGetters('common', ['askFullAddressForDelivery']),
    ...mapGetters('persisted', ['selectedZipCode']),
    ...mapGetters('persistedLocal', ['selectedDeliveryAddress']),
    ...mapGetters('address', ['defaultAddress']),
    bookedSlot() {
      return this.formatBookedSlot(this.selectedFulfillmentCodeName) || ''
    },
  },
  methods: {
    showAddressDialog() {
      this.changeDialogState({
        dialog: 'SelectAddressDialog',
        val: true,
      })
    },
    switchFulfillment(deliveryOption) {
      if (deliveryOption?.isComingSoon) return

      if (!deliveryOption) {
        this.changeDialogState({
          dialog: 'changeLocation',
          val: true,
          parentDialog: this.parentDialog,
        })
      }

      if (
        deliveryOption &&
        this.selectedFulfillmentCodeName !== deliveryOption.codeName
      ) {
        this.changeDialogState({
          dialog: 'changeLocation',
          val: true,
          parentDialog: this.parentDialog,
          properties: {
            targetFulfillment: deliveryOption.codeName,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
.h-quick-location {
  width: 300px;
  padding: 15px 0 0;
  font-size: 12px;
  @media (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 374px) {
    font-size: 12px;
  }
  .q-item {
    padding: 0;
    min-height: 30px;
    &:hover .q-focus-helper {
      background: none !important;
    }
  }
  .q-toolbar__title {
    font-size: 12px;
  }
  .shpping-options {
    list-style-type: none;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
    margin-bottom: 25px;
    margin-top: 0;
    color: #fff;
    li {
      flex: 1 0 0%;
      width: 100%;
      max-width: 90px;
      cursor: pointer;
    }
    &-thumb {
      width: 50px;
      height: 50px;
      background-color: $white;
      color: $primary;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 1px solid #fff;
      margin: 5px auto 10px auto;
      &::before {
        width: 56px;
        height: 56px;
        border: 1px solid #fff;
        content: '';
        position: absolute;
        border-radius: 100%;
        left: -4px;
        right: 0;
        bottom: 0;
        top: -4px;
      }
      &.shpping-options-active {
        background-color: $white;
        color: $primary;
        .absolute-top-right {
          top: -4px;
          right: -4px;
        }
        &::before {
          border: 1px solid #fff;
        }
      }
    }
  }
  .shipping-options-store {
    border-radius: 7px;
    margin-top: 10px;
    &-thumb {
      background-color: rgba($primary, 0.2);
    }
    .q-item {
      min-height: auto;
      padding: 10px;
    }
  }
  .infoDialogff {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
.mobile {
  .h-quick-location {
    background: $white;
    width: 100%;
    padding: 16px 16px 16px;
    padding-top: 50px;
    .infoDialogff {
      position: absolute;
      right: 55px;
      top: 13px;
    }
    .shpping-options {
      color: $textdark;
    }
    .shipping-options-store {
      border: 1px solid rgba($primary, 0.7);
      .q-item {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .shpping-options-thumb {
      background: rgba($primary, 0.2);
      color: $primary;
      &:before {
        border: 1px solid rgba($primary, 0.5);
      }
      &.shpping-options-active {
        background: $primary;
        color: $white;
        .q-icon {
          color: $white !important;
        }
        .q-btn {
          .q-icon {
            color: $primary !important;
          }
        }
        &:before {
          border-color: $primary;
        }
      }
    }
  }
}
.h-quick-location {
  .deliveryType {
    .q-item {
      .q-item__section--main {
        display: block;
        .q-item__label {
          display: inline-block;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .deliveryType {
      font-size: 16px;
      .q-item {
        min-height: 40px;
      }
      .q-item__label {
        i {
          font-size: 1.2rem !important;
        }
      }
    }
    .link-dark {
      font-size: 14px;
    }
    .link-primary {
      font-size: 15px;
    }
  }
}
</style>
