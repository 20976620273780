<template>
  <div class="r-8 q-mt-md shipProductOption">
    <div class="row q-col-gutter-md text-weight-bold">
      <div class="col-8 text-subtitle text-dark"
        >Check Product Availability</div
      >
      <div class="col-4 text-right">
        <template v-if="locationInfo.productLocationPrice">
          <div class="text-subtitle text-secondary"
            >${{ locationInfo.productLocationPrice }}</div
          >
        </template>
      </div>
      <div class="col-12">
        <q-form
          @submit="getProductAvailability"
          class="full-width bg-grey-3 q-pl-md q-pr-sm r-8"
        >
          <div class="row items-center">
            <div class="col">
              <div class="form-field pb-0">
                <q-input
                  borderless
                  v-model.trim="zipCode"
                  :lazy-rules="lazyValidationRules"
                  placeholder="Enter zip code"
                  mask="#####"
                  :rules="[(val) => validateRequired(val), this.validateZip]"
                  class="r-8"
                  autocomplete="off"
                >
                  <template v-slot:prepend>
                    <q-icon name="home" color="dark" />
                  </template>
                </q-input>
              </div>
            </div>
            <div class="col-auto">
              <q-btn
                unelevated
                no-caps
                round
                text-color="dark"
                icon="search"
                class="no-hover"
                type="submit"
                :loading="loading"
              >
              </q-btn>
            </div>
          </div>
        </q-form>
      </div>
      <div class="col-12" v-if="errors && errors.length">
        <Alert
          :variant="'danger'"
          dismissible
          v-for="(error, index) in errors"
          :key="index"
          :show="25"
          @dismissed="errors = []"
          className="q-mb-none"
          >{{ error }}</Alert
        >
      </div>
      <div class="col-12" v-if="Object.keys(locationInfo).length">
        <Alert :show="locationInfo.zip" className="q-mb-none"
          >The product is available to be delivered on the entered zip
          code.</Alert
        >
      </div>
      <div class="col-12 text-right">
        <q-btn
          unelevated
          dense
          no-caps
          flat
          text-color="secondary"
          label="Switch To New Zip Code"
          padding="0"
          size="15px"
          class="no-hover text-underline text-weight-bold"
          :disable="!Object.keys(locationInfo).length || isZipcodeChanged"
          @click="changeFulfilmentMethod()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '../common/Alert.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    productID: {},
  },
  data() {
    return {
      zipCode: '',
      errors: [],
      isZipcodeChanged: true,
      loading: false,
      locationInfo: {},
      locations: [],
    }
  },
  computed: {
    ...mapGetters('common', ['businessFulfillments']),
    ...mapGetters('persisted', ['selectedFulfillment']),
    ...mapGetters('cart', ['cartTotalItems']),
  },
  components: {
    Alert,
  },
  methods: {
    getProductAvailability() {
      let params = {
        zipcode: this.zipCode,
        productID: this.productID,
      }
      this.loading = true
      this.errors = []
      this.locationInfo = {}
      this.$store
        .dispatch('product/getProductAvailability', params)
        .then((res) => {
          if (!res.success) {
            if (res.errors && res.errors.length) {
              res.errors.forEach((element) => {
                this.errors.push(element.descreption)
              })
            }
          } else if (res.success) {
            // this.errors = []
            this.locationInfo = res.data
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeFulfilmentMethod() {
      let reqParam = {
        zipCode: this.zipCode,
      }

      this.$store
        .dispatch('location/verifyShipping', reqParam)
        .then((res) => {
          if (res.success && res.data) {
            this.locations = [{ ...res.data }]
            let totalItems = this.cartTotalItems

            //MARK:- Uncomment the code after guest checkout so when ever user can switch the zipcode also able to add product in cart.
            this.$store.commit('location/SET_CHANGE_LOCATION_ADD_TO_CART', {
              fulfillmentMethodId: this.selectedFulfillment.fulfillmentId,
              products: [
                {
                  productId: this.productID,
                  qty: 1,
                },
              ],
            })

            if (this.locations?.length && this.locations[0])
              this.$store
                .dispatch('location/setAsMyStore', {
                  location: this.locations[0],
                  fulfillment: this.selectedFulfillment,
                  selectedZipCode: this.zipCode,
                  forceReload: false,
                })
                .then(() => {
                  /* if (!this.isLoggedIn) return this.loginWithIdentity()
                  else if (!this.cartTotalItems) window.location.reload() */
                  if (!totalItems) window.location.reload()
                })
          } else {
            if (res.errors && res.errors.length) {
              res.errors.forEach((element) => {
                this.errors.push(element.descreption)
              })
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  watch: {
    zipCode: function (newVal, oldVal) {
      if ((newVal && newVal.toString().length == 5, newVal != oldVal)) {
        this.isZipcodeChanged = false
        this.locationInfo = {}
        this.errors = []
      } else {
        this.isZipcodeChanged = true
      }
    },
  },
}
</script>

<style lang="scss">
/* Ship To This Location */
/* .shipProductOption {
  border: 1px solid #c2c2c2;
  padding: 12px;
} */
/* Ship To This Location */
</style>
