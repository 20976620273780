<template>
  <q-list class="list-discount">
    <q-item
      v-for="(discount, index) in discounts"
      :to="
        !isMobile
          ? {
              name: 'DiscountDetail',
              params: { seoName: discount.discountID },
            }
          : null
      "
      :key="`${discount.discountID}-${index}`"
      @click="onDiscountClick(discount)"
      :clickable="isMobile"
      :class="{ active: $route?.params?.seoName === discount.discountID }"
    >
      <q-item-label>{{ discount.discountName }}</q-item-label>
    </q-item>
    <div class="text-center">
      <q-btn
        v-show="hasMoreDiscount"
        unelevated
        no-caps
        rounded
        text-color="grey-7"
        size="16px"
        class="no-hover text-weight-bold"
        :loading="loadingMore"
        @click="loadMoreDiscount"
      >
        <span class="text-underline">Load More</span>
        <q-icon name="keyboard_double_arrow_down" size="18px" class="q-ml-sm" />
      </q-btn>
    </div>
  </q-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DiscountList',
  computed: {
    ...mapGetters('discounts', ['discounts', 'hasMoreDiscount', 'loadingMore']),
  },
  methods: {
    loadMoreDiscount() {
      this.$store.dispatch('discounts/getDiscounts', {
        loadMore: true,
      })
    },
    onDiscountClick(discount) {
      if (this.$route?.params?.seoName !== discount?.discountID)
        this.$router
          .replace({
            name: 'DiscountDetail',
            params: { seoName: discount.discountID },
          })
          .catch(() => {})
      this.changeDialogState({
        dialog: 'ExploreDiscount',
        val: false,
      })
    },
  },
}
</script>
