import { render, staticRenderFns } from "./AgeVerificationInfo.vue?vue&type=template&id=563ef2c8"
import script from "./AgeVerificationInfo.vue?vue&type=script&lang=js"
export * from "./AgeVerificationInfo.vue?vue&type=script&lang=js"
import style0 from "./AgeVerificationInfo.vue?vue&type=style&index=0&id=563ef2c8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QColor from 'quasar/src/components/color/QColor.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QColor});qInstall(component, 'directives', {ClosePopup});
