<template>
  <ftx-dialog
    :dialog="dialog"
    :onShow="onShow"
    position="right"
    maximized
    persistent
    transition-show="scale"
    transition-hide="scale"
    :onHide="beforeHide"
  >
    <template v-slot:DialogContent>
      <q-card class="addWhishlistDialog">
        <q-card-section>
          <q-form @submit="createWishlist">
            <div class="topHeader">
              <h3> {{ dialogTitle }}</h3>

              <q-btn
                unelevated
                flat
                round
                icon="close"
                class="close"
                v-close-popup
              />
            </div>

            <div class="middle">
              <q-scroll-area
                class="scrollHeight"
                :thumb-style="scrollAreaThumbStyle"
                :bar-style="scrollAreaBarStyle"
                :visible="scrollAreaVisible"
                style="max-width: 100%"
              >
                <p class="text-caption q-mb-sm">*Required Field</p>
                <div class="form-field">
                  <q-input
                    label="Wishlist Name*"
                    outlined
                    clearable
                    v-model="wishlistName"
                    maxlength="40"
                    :rules="[
                      (val) => !!val || 'Field is required',
                      (val) =>
                        (val && val.trim().length > 0) || 'Field is required',
                    ]"
                  >
                    <!-- <template v-slot:append>
                      <q-icon
                        name="cancel"
                        class="cursor-pointer"
                        v-if="wishlistName"
                        @click.stop.prevent="wishlistName = ''"
                      />
                    </template> -->
                  </q-input>
                </div>
              </q-scroll-area>
            </div>

            <div class="bottom">
              <q-btn
                unelevated
                no-caps
                :rounded="!isMobile"
                outline
                color="tertiary"
                label="Cancel"
                class="q-ml-sm q-mr-sm q-px-md q-py-xs"
                v-close-popup
              />

              <q-btn
                unelevated
                no-caps
                :rounded="!isMobile"
                color="secondaryOnBody"
                :label="properties.action == 'add' ? 'Create' : 'Save'"
                class="q-ml-sm q-mr-sm q-px-md q-py-xs"
                type="submit"
                :loading="loading"
                :disabled="!wishlistName || wishlistName.trim().length <= 0"
              />
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
export default {
  name: 'CreateWishListDialog',
  data() {
    return {
      dialog: 'CreateWishListDialog',
      loading: false,
      wishlistName: '',
      showDialog: true,
    }
  },
  computed: {
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    dialogTitle() {
      return (
        (this.properties.action == 'add' ? 'Create New' : 'Edit') + ' Wishlist'
      )
    },
  },
  methods: {
    beforeHide() {
      this.wishlistName = ''
    },
    onShow() {
      if (
        this.properties &&
        this.properties.wishlistInfo &&
        this.properties.wishlistInfo.wishListID
      ) {
        this.wishlistName = this.properties.wishlistInfo.wishListName
      }
    },
    createWishlist() {
      this.loading = true
      if (this.properties && this.properties?.wishlistInfo?.wishListID) {
        this.$store
          .dispatch('wishlist/editWishlist', {
            wishListID: this.properties.wishlistInfo.wishListID,
            wishListName: this.wishlistName,
          })
          .then((res) => {
            this.loading = false
            if (res && res.success) {
              this.wishlistName = ''
              this.showSuccess('Wishlist updated successfully.')
              this.changeDialogState({
                dialog: this.dialog,
                val: false,
              })
            } else {
              this.showError(res.message)
            }
          })
      } else {
        this.$store
          .dispatch('wishlist/createNewWishlist', this.wishlistName)
          .then((res) => {
            this.loading = false
            if (res && res.success) {
              this.wishlistName = ''
              this.showSuccess('Wishlist created successfully.')
              this.changeDialogState({
                dialog: this.dialog,
                val: false,
              })
            } else {
              this.showError(res.message)
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
.addWhishlistDialog {
  width: 100vw !important;
  @media (min-width: 481px) {
    width: 440px !important;
  }
  @media (min-width: 1024px) {
    width: 500px !important;
  }

  .q-card__section {
    padding: 0;
  }
  .topHeader {
    padding: 20px 30px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 767px) {
      padding: 20px 15px;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: normal;
      color: #333333;
      @media (min-width: 1681px) {
        font-size: 20px;
      }
    }
    .back {
      .q-focus-helper {
        display: none;
      }
      .q-btn__wrapper {
        padding: 0 0 4px;
      }
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .middle {
    padding: 20px 15px;
    @media (max-width: 767px) {
      padding: 15px 0;
    }
    .scrollHeight {
      padding-left: 15px;
      padding-right: 15px;
      height: calc(100dvh - 191px);
      @media (max-width: 1023px) {
        height: calc(100dvh - 165px) !important;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      .q-checkbox {
        display: flex;
        margin: 8px 0;
        padding: 12px 12px;
        padding-right: 45px;
        font-size: 16px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        position: relative;
        border-radius: 25px;
        transition: all 0.3s ease-in-out;
        &[aria-checked='true'] {
          font-weight: 600;
          color: $primary;
          border-color: $primary;

          .no-outline {
            background: $primary;
            border-color: $primary;
          }
        }
        .no-outline {
          position: absolute;
          width: 24px;
          height: 24px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          right: 16px;
          border-radius: 25px;
          transition: all 0.1s ease-in-out;
          &:before {
            content: 'done';
            color: #fff;
            font-family: Material Icons;
            font-size: 18px;
            font-weight: 400;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            font-feature-settings: 'liga';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
      }
      .q-checkbox__inner {
        display: none;
      }
      .q-checkbox__label {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .bottom {
    padding: 10px 30px;
    border-top: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 1023px) {
      padding: 5px 15px;
    }
    .q-btn {
      width: 180px;
      margin: 7px 0;
      padding: 5px 0;
      @media (max-width: 479px) {
        width: auto;
        padding: 5px 8px;
      }
    }
    .q-btn:last-child {
      float: right;
    }
  }
}
</style>
