<template>
  <div
    class="searchStore hlocationList"
    v-if="Object.keys(businessFulfillments).length"
  >
    <div class="container">
      <q-form
        @submit="onSubmit"
        @reset="
          () => {
            this.searchObj = {
              searchKeyword: '',
              withinMiles: '0',
              selGSuggestion: null,
            }
            this.resetAddress()
            this.onReset()
          }
        "
        ref="addressForm"
        class="searchStoreForm"
        autocomplete="off"
      >
        <div class="row q-col-gutter-x-md">
          <div class="col-12">
            <q-list class="flex items-start justify-center delivery-options">
              <q-item
                v-for="(deliveryOption, codeName) in businessFulfillments"
                :key="`${deliveryOption.fulfillmentID}-${codeName}`"
                clickable
                @click="
                  !deliveryOption.isComingSoon &&
                    setActiveDeliveryMethod(deliveryOption.codeName)
                "
                :class="[
                  'column items-center delivery-options__item',
                  deliveryOption.isComingSoon ? 'disabled' : '',
                  activeDeliveryMethod === deliveryOption.codeName &&
                  !deliveryOption.isComingSoon
                    ? 'delivery-options__item--active'
                    : '',
                ]"
              >
                <q-item-section class="delivery-options__header">
                  <q-avatar class="delivery-options__avatar">
                    <SvgIcon
                      :icon="deliveryOption.codeName"
                      size="40px"
                      color="secondary"
                    />
                    <q-badge
                      class="delivery-options__badge"
                      floating
                      color="secondary"
                      rounded
                      dense
                    >
                      <q-icon name="check" size="14px" color="white" />
                    </q-badge>
                  </q-avatar>
                </q-item-section>
                <q-item-section class="items-center">
                  <q-item-label class="delivery-options__title">
                    {{ deliveryOption.fulfillmentName }}
                  </q-item-label>
                  <q-item-label
                    class="delivery-options__title"
                    v-if="deliveryOption.isComingSoon"
                  >
                    <small
                      class="block bg-dark text-white text-caption q-py-xs q-px-sm r-8"
                    >
                      Coming Soon!
                    </small>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>

          <div class="col-12 col-md-10 q-mx-auto">
            <div
              :class="`form-field fulfillment-autocomplete ${
                inputFocus ? 'show' : ''
              }`"
            >
              <GoogleAutoComplete
                :outlined="true"
                :clearable="true"
                label="Enter the delivery address"
                :addressFieldChange="addressFieldChange"
                modelField="searchKeyword"
                :value="searchObj.searchKeyword"
                :placechanged="getAddressData"
                :readonly="false"
                :rules="[
                  (val) => validateRequired(val),
                  (val) => validateAddress(),
                ]"
                :lazy-rules="lazyValidationRules"
                v-if="
                  askFullAddressForDelivery && activeDeliveryMethod == 'scd'
                "
                ref="deliveryAddressInput"
              />
              <q-input
                v-else
                outlined
                :value="searchObj.searchKeyword"
                :placeholder="searchPlaceholder"
                :lazy-rules="lazyValidationRules"
                class="search-location"
                :clearable="false"
                :rules="[this.validateRequired]"
                @input="onInput"
                @blur="inputFocus = false"
                @click="inputFocus = true"
                ref="cityStateInput"
              >
                <template v-slot:after>
                  <UseMyCurrentLocation
                    :locationFound="getMyLocation"
                    :queryOnMount="queryDeviceLocationOnMount"
                  />
                </template>

                <div class="autocomplete-lists" id="autocompleteItems">
                  <div
                    v-for="(gSuggestion, index) in googleSuggestion"
                    :key="index"
                    class="autocomplete-items"
                    @click="selectSuggestion(gSuggestion)"
                  >
                    <template
                      v-if="
                        gSuggestion.city &&
                        gSuggestion.state &&
                        gSuggestion.country
                      "
                    >
                      <div class="row items-center">
                        <div class="col-auto">
                          <q-avatar size="2rem" icon="place" />
                        </div>
                        <div class="col">
                          {{ gSuggestion.city }}, {{ gSuggestion.state }},
                          {{ gSuggestion.country }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      {{ gSuggestion.description }}
                    </template>
                  </div>
                </div>
              </q-input>
            </div>
          </div>

          <div v-if="activeDeliveryMethod == 'sp'" class="col-12 col-md-2">
            <div class="form-field">
              <q-select
                outlined
                v-model="searchObj.withinMiles"
                :options="milesOptions"
                option-value="val"
                option-label="label"
                emit-value
                map-options
                label="Within Miles"
                class="mile-select"
                dropdown-icon="expand_more"
              />
            </div>
          </div>

          <div v-show="error" class="col-12 col-md-12 text-center text-red">
            {{ error }}
          </div>
          <div class="col-12 col-md-12 text-center searchStoreAction">
            <q-btn
              unelevated
              rounded
              outline
              color="tertiary"
              label="Reset"
              class="q-ma-sm q-py-lg-xs q-py-xl-sm"
              type="reset"
            />
            <q-btn
              unelevated
              rounded
              color="secondaryOnBody"
              :label="activeDeliveryMethod == 'sd' ? 'Proceed' : 'Find Store'"
              class="q-ma-sm q-py-lg-xs q-py-xl-sm"
              type="submit"
              :loading="loading"
            />
          </div>
        </div>
      </q-form>
    </div>

    <q-scroll-area
      v-if="onDialog"
      :thumb-style="scrollAreaThumbStyle"
      :bar-style="scrollAreaBarStyle"
      :visible="scrollAreaVisible"
      :style="{
        height: `calc(${scrollHeight}dvh - 410px)`,
        maxWidth: '100%',
        transition: 'height 0.4s ease-in-out',
        paddingRight: '25px',
      }"
    >
      <FulfillmentLocationList
        :fulfillmentCode="activeDeliveryMethod"
        :fulfillment="businessFulfillments[activeDeliveryMethod]"
        :ref="(el) => setListRef(el, activeDeliveryMethod)"
        :searchObj="searchObj"
        :deliveryAddress="addressObj"
        :setShowAdditional="activeDeliveryMethod == 'sd' && setShowAdditional"
        :setError="setError"
        :setFirstLoading="setFirstLoading"
        isPrimary
      />
      <div v-show="showAdditional || activeDeliveryMethod != 'sd'">
        <h2
          v-show="hasAdditionalOption"
          class="text-h4 text-weight-bold text-dark text-center"
        >
          Enjoy Shopping with more options
        </h2>
        <FulfillmentLocationList
          v-for="(reconFulfillments, index) in notActiveDeliveryMethod"
          :key="`${reconFulfillments}-${index}`"
          :ref="(el) => setListRef(el, reconFulfillments)"
          :fulfillmentCode="reconFulfillments"
          :fulfillment="businessFulfillments[reconFulfillments]"
          :label="businessFulfillments[reconFulfillments].fulfillmentName"
          :searchObj="searchObj"
          :deliveryAddress="addressObj"
          :setHasAdditionalOption="setHasAdditionalOption"
        />
      </div>
    </q-scroll-area>
    <template v-else>
      <FulfillmentLocationList
        :fulfillmentCode="activeDeliveryMethod"
        :fulfillment="businessFulfillments[activeDeliveryMethod]"
        :ref="(el) => setListRef(el, activeDeliveryMethod)"
        :searchObj="searchObj"
        :deliveryAddress="addressObj"
        :setShowAdditional="activeDeliveryMethod == 'sd' && setShowAdditional"
        :setError="setError"
        :setFirstLoading="setFirstLoading"
        isPrimary
      />
      <div v-show="showAdditional || activeDeliveryMethod != 'sd'">
        <h2
          v-show="hasAdditionalOption"
          class="text-h4 text-weight-bold text-dark text-center"
        >
          Enjoy Shopping with more options
        </h2>
        <FulfillmentLocationList
          v-for="(reconFulfillments, index) in notActiveDeliveryMethod"
          :key="`${reconFulfillments}-${index}`"
          :ref="(el) => setListRef(el, reconFulfillments)"
          :fulfillmentCode="reconFulfillments"
          :fulfillment="businessFulfillments[reconFulfillments]"
          :label="businessFulfillments[reconFulfillments].fulfillmentName"
          :searchObj="searchObj"
          :deliveryAddress="addressObj"
          :setHasAdditionalOption="setHasAdditionalOption"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { resetObjRequests } from 'boot/axios'

let autocompleteService

if (typeof google !== 'undefined')
  autocompleteService = new google.maps.places.AutocompleteService()

const initialState = {
  showAdditional: false,
  hasAdditionalOption: false,
  error: null,
  loading: false,
}

let addressTypeMaper = {
  street_number: {
    get: 'short_name',
    set: 'line1',
  },
  route: {
    get: 'long_name',
    set: 'line1',
  },
  locality: {
    get: 'long_name',
    set: 'city',
  },
  administrative_area_level_1: {
    get: 'short_name',
    set: 'state',
  },
  sublocality_level_1: {
    get: 'long_name',
    set: 'line2',
  },
  sublocality_level_2: {
    get: 'long_name',
    set: 'line2',
  },
  sublocality_level_3: {
    get: 'long_name',
    set: 'line2',
  },
  postal_code: { get: 'short_name', set: 'zip' },
}

export default {
  name: 'PageSearchLocation',
  props: {
    onDialog: {
      type: Boolean,
      default: false,
    },
    targetFulfillment: {
      type: String,
      default: '',
    },
  },
  components: {
    // lazy load component
    UseMyCurrentLocation: () =>
      import('components/common/UseMyCurrentLocation'),
    FulfillmentLocationList: () => import('./FulfillmentLocationList'),
    GoogleAutoComplete: () => import('components/common/GoogleAutoComplete'),
  },
  computed: {
    ...mapGetters('location', ['milesOptions']),
    ...mapGetters('common', ['askFullAddressForDelivery']),
    ...mapGetters('persisted', ['selectedZipCode']),
    activeDeliveryMethod: {
      get() {
        if (this.localActiveDelivery) return this.localActiveDelivery

        if (this.targetFulfillment) return this.targetFulfillment

        if (
          this.selectedFulfillmentCodeName &&
          this.businessFulfillments[this.selectedFulfillmentCodeName]
            ?.isActive &&
          !this.businessFulfillments[this.selectedFulfillmentCodeName]
            ?.isComingSoon
        )
          return this.selectedFulfillmentCodeName

        for (const fulfillmentCode in this.businessFulfillments) {
          if (!this.businessFulfillments[fulfillmentCode].isComingSoon) {
            return fulfillmentCode
          }
        }

        return ''
      },
      set(value) {
        if (value !== this.localActiveDelivery) {
          this.localActiveDelivery = value
          this.onReset()
          this.$forceUpdate()
        }
      },
    },
    notActiveDeliveryMethod() {
      let notActiveList = []
      if (this.activeDeliveryMethod) {
        Object.keys(this.businessFulfillments).forEach((codeName) => {
          if (
            codeName !== this.activeDeliveryMethod &&
            this.businessFulfillments[codeName]?.isActive &&
            !this.businessFulfillments[codeName]?.isComingSoon
          )
            notActiveList.push(codeName)
        })
      }

      return notActiveList
    },
    searchPlaceholder() {
      return 'Enter zip code or city, state'
      // if (this.activeDeliveryMethod == 'sp')
      //   return 'Search nearby store using zip code'
      // if (this.activeDeliveryMethod == 'scd')
      //   return 'Enter the zip code of the delivery address'

      // return 'Enter the zip code of the shipping address'
    },
  },
  data() {
    return {
      scrollHeight: 55,
      refsMapper: {},
      localActiveDelivery: null,
      searchObj: {
        withinMiles: '0',
        searchKeyword: '',
        selGSuggestion: null,
      },
      addressObj: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        geoLocation: '',
      },
      ...initialState,
      googleSuggestion: [],
      queryDeviceLocationOnMount: true,
      inputFocus: false,
    }
  },
  deactivated() {
    this.onReset()
  },
  unmounted() {
    this.onReset()
  },
  methods: {
    selectSuggestion(suggestion) {
      let lastSearchKeyword = this.searchObj?.searchKeyword || ''

      this.searchObj = {
        ...this.searchObj,
        searchKeyword:
          suggestion.city && suggestion.state
            ? `${suggestion.city}, ${suggestion.state}`
            : suggestion.description,
        selGSuggestion: suggestion,
      }

      setTimeout(() => {
        this.$refs.cityStateInput?.blur()
        if (lastSearchKeyword !== suggestion.description) this.onSubmit()
      }, 0)
    },
    onInput(value) {
      if (!autocompleteService) {
        autocompleteService = new google.maps.places.AutocompleteService()
      }

      let inputValue = value.trim()

      if (inputValue) {
        let lastChar = inputValue.slice(-1)
        let isNum = /^[0-9]+$/.test(lastChar)
        let isChar = /^[a-zA-Z]+$/.test(lastChar)

        if (isNum) {
          inputValue = inputValue.replace(/[^0-9]/g, '').substr(0, 5)
        } else if (isChar) {
          inputValue = inputValue.replace(/\d+/g, '')
        }

        if (inputValue.length >= 2 && isChar) {
          autocompleteService.getPlacePredictions(
            {
              input: inputValue,
              types: ['(cities)'],
              componentRestrictions: { country: 'us' },
            },
            (predictions, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                predictions = predictions.map((prediction) => {
                  let descArray = prediction.description?.split(',') || []
                  return {
                    ...prediction,
                    city: descArray[0] ? descArray[0].trim() : '',
                    state: descArray[1] ? descArray[1].trim() : '',
                    country: descArray[2] ? descArray[2].trim() : '',
                  }
                })
                this.googleSuggestion = predictions
              } else {
                this.googleSuggestion = []
              }
            }
          )
        } else this.googleSuggestion = []
      } else this.googleSuggestion = []

      this.searchObj = {
        ...this.searchObj,
        searchKeyword: inputValue,
        selGSuggestion: null,
      }
    },
    validateAddress() {
      return this.addressObj?.geoLocation
        ? true
        : 'Delivery address is not valid.'
    },
    async getAddressData({ addressData, geoLocation }) {
      this.resetAddress()
      // Object.keys(addressTypeMaper).forEach((key) => {
      //   this.addressFieldChange(addressTypeMaper[key].set, '')
      // })

      addressData.forEach((addressComponents) => {
        const addressType = addressComponents.types[0]
        if (addressTypeMaper[addressType]) {
          const val = addressComponents[addressTypeMaper[addressType]['get']]

          this.addressFieldChange(
            addressTypeMaper[addressType]['set'],
            this.addressObj[addressTypeMaper[addressType]['set']]
              ? `${
                  this.addressObj[addressTypeMaper[addressType]['set']]
                } ${val}`
              : val
          )
        }
      })

      if (geoLocation?.latitude && geoLocation?.longitude) {
        const geoLocationDetails = `${geoLocation.latitude},${geoLocation.longitude}`
        this.addressFieldChange('geoLocation', geoLocationDetails)
      }

      setTimeout(() => {
        this.onSubmit()
      }, 0)
    },
    addressFieldChange(field, val) {
      this.addressObj[field] = val

      if (field === 'searchKeyword') {
        this.searchObj = {
          ...this.searchObj,
          searchKeyword: val,
        }

        this.resetAddress()
      }
    },
    setActiveDeliveryMethod(deliveryMethod) {
      this.queryDeviceLocationOnMount = false

      resetObjRequests()

      for (const fCodeName in this.refsMapper) {
        this.refsMapper[fCodeName]?.reset()
      }

      let lastActiveDeliveryMethod = this.activeDeliveryMethod

      this.activeDeliveryMethod = deliveryMethod

      if (
        lastActiveDeliveryMethod != deliveryMethod &&
        this.askFullAddressForDelivery &&
        (lastActiveDeliveryMethod === 'scd' || deliveryMethod === 'scd')
      ) {
        if (lastActiveDeliveryMethod === 'scd') this.resetAddress()
        else this.googleSuggestion = []

        this.searchObj = {
          ...this.searchObj,
          searchKeyword: '',
        }
      }
    },
    setListRef(refEle, id) {
      if (refEle && id) this.refsMapper[id] = refEle
    },
    getMyLocation(myLocation) {
      // if (myLocation.zip != this.searchObj.searchKeyword)
      let zipCode = myLocation.zip
      if (zipCode?.length > 5) zipCode = zipCode.substr(0, 5)
      this.searchObj.searchKeyword = zipCode
      this.googleSuggestion = []
      this.onSubmit()
    },
    onSubmit() {
      if (this.loading) return
      if (
        this.askFullAddressForDelivery &&
        this.activeDeliveryMethod == 'scd' &&
        !this.addressObj.geoLocation
      ) {
        if (this.$refs.deliveryAddressInput?.$refs?.AutoCompleteInput)
          this.$refs.deliveryAddressInput?.$refs.AutoCompleteInput.validate()

        return
      }

      this.onReset()

      for (const fCodeName in this.refsMapper) {
        this.refsMapper[fCodeName]?.search()
      }
    },
    setFirstLoading(loading) {
      this.loading = loading
      if (!loading) {
        this.scrollHeight = 90
      }
    },
    onReset() {
      Object.assign(this.$data, {
        ...initialState,
      })
      this.$refs.addressForm.resetValidation()
      for (const fCodeName in this.refsMapper) {
        this.refsMapper[fCodeName]?.reset()
      }
    },
    resetAddress() {
      if (!this.askFullAddressForDelivery) return

      this.addressObj = {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        geoLocation: '',
      }
    },
    setShowAdditional(val) {
      this.showAdditional = true
    },
    setHasAdditionalOption(val) {
      this.hasAdditionalOption = val
    },
    setError(error) {
      this.error = error
    },
  },
}
</script>

<style lang="scss">
// new CSS
.delivery-options {
  font-family: 'Poppins', sans-serif;
  margin-top: 0px;
  margin-bottom: 16px;
  flex-wrap: nowrap;
  @media (min-width: 480px) {
    margin-bottom: 26px;
    justify-content: center;
  }
  @media (min-width: 1681px) {
    // margin-top: 30px;
    margin-bottom: 56px;
  }
  .delivery-options__header {
    border: 2px solid transparent;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    min-height: 60px;
    background: #fff;
    min-width: 60px;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: 480px) {
      width: 80px;
      height: 80px;
      min-height: 80px;
      min-width: 80px;
      margin-bottom: 15px;
    }
    @media (min-width: 1681px) {
      width: 120px;
      height: 120px;
      min-height: 120px;
      min-width: 120px;
    }
  }
  .delivery-options__title {
    font-size: 12px;
    line-height: 1.3 !important;
    color: $dark-light;
    font-weight: 500;
    transition: 0.2s ease all;
    text-align: center;
    margin-bottom: 0px;
    @media (min-width: 767px) {
      margin-bottom: 5px;
      font-size: 14px;
    }
    @media (min-width: 1681px) {
      font-size: 16px;
    }
  }
  .delivery-options__avatar {
    margin-bottom: 0;
    transition: 0.2s ease all;
    width: 50px;
    height: 50px;
    min-height: 46px;
    background: #eeeeee;

    .q-avatar__content {
      > i {
        font-size: 20px !important;
      }
    }
    @media (min-width: 480px) {
      width: 70px;
      height: 70px;
      min-height: 66px;
      .q-avatar__content {
        > i {
          font-size: 40px !important;
        }
      }
    }
    @media (min-width: 1681px) {
      width: 110px;
      height: 110px;
      min-height: 106px;
    }
  }
  .delivery-options__badge {
    width: 20px;
    height: 20px;
    padding: 0;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease all;
    top: -3px;
    right: -9px;

    border-radius: 100%;
    cursor: pointer;
    opacity: 0;
    @media (min-width: 480px) {
      top: 4px;
      right: -6px;
    }
    @media (min-width: 1681px) {
      width: 26px;
      height: 26px;
    }
  }
  .delivery-options__selected {
    opacity: 0;
    user-select: none;
    transition: 0.2s ease all;
  }
  .delivery-options__item {
    cursor: pointer;
    .q-focus-helper {
      display: none;
    }
    &--active {
      .delivery-options__title {
        font-size: 12px;

        @media (min-width: 767px) {
          font-size: 16px;
        }
        @media (min-width: 1681px) {
          font-size: 18px;
        }
      }
      .delivery-options__badge {
        opacity: 1;
      }
      .delivery-options__avatar {
        color: $secondary;
        color: var(--q-color-secondary) !important;
      }
      .delivery-options__header {
        border-color: $secondary;
        border-color: var(--q-color-secondary) !important;
        box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.1);
      }

      .delivery-options__selected {
        opacity: 1;
        user-select: auto;
      }
    }
    + .delivery-options__item {
      margin-left: 0px;

      @media (min-width: 480px) {
        margin-left: 18px;
      }
    }
    @media (min-width: 768px) {
      padding: 8px;
    }
    @media (min-width: 1681px) {
      padding: 8px 16px;
    }
  }
  .q-item__section--main + .q-item__section--main {
    margin-left: 0;
  }
}
.search-location {
  box-shadow: 0px 3px 6px #00000010;
  border-radius: 7px;
  font-size: 14px;
  color: #777777;
  .q-field__control {
    box-shadow: none;
    border-radius: 4px 0 0 4px;
    &:before {
      border-color: rgba(0, 0, 0, 0.09) !important;
    }
  }
  .allowLocation {
    font-size: 14px;
    color: #777777;
  }
  .q-field__control:before {
    border-color: #fff;
  }
  .q-field__after {
    padding-left: 8px;
    padding-right: 16px;
    border-radius: 0 4px 4px 0;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-left: none;
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-right: 8px;
    }
  }
}

.location-title-error {
  // margin-left: 5px;
  font-size: 12px;
  display: inline-block;
  i {
    font-size: 14px;
    vertical-align: bottom;
  }
}
.searchStore {
  &.hlocationList {
    .mile-select {
      background-color: #f3f3f3;
      border-radius: 7px;
      color: #777777;
      font-size: 12px;
      .q-field__control:before {
        border-color: #fff;
      }
      &:hover {
        .q-field__control:before {
          border-color: #777777;
        }
      }
    }
    .searchStoreForm .q-item {
      border: none;
      @media (max-width: 400px) {
        max-width: 96px;
      }
    }
    .locationList {
      .hlocationListIcon i {
        &.hlocationListIcon-error {
          border-color: $negative !important;
          color: $negative !important;
          &::before {
            content: '';
            position: absolute;
            width: 80%;
            height: 1px;
            background: $negative;
            transform: rotate(45deg);
          }
        }
      }
      .q-item {
        background-color: #fafafa;
        border-radius: 24px;
        padding: 15px;
        border: none;
        border: 3px solid transparent;
        @media (min-width: 1681px) {
          padding: 25px;
        }
        @media (max-width: 1023px) {
          padding: 10px;
        }
        .q-btn {
          font-size: 12px;
          @media (min-width: 1681px) {
            font-size: 14px;
          }
        }
        &.location-error {
          border-color: $negative;
          background-color: #d92f3208;
        }
      }
      .hlocationListDetail {
        .title {
          font-size: 16px;
          @media (min-width: 1681px) {
            font-size: 20px;
          }
        }
        .text-right {
          font-size: 12px;
          @media (min-width: 1681px) {
            font-size: 18px;
          }
        }
        .text-caption {
          font-size: 12px;
          @media (min-width: 1681px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.searchStoreAction {
  margin-bottom: 25px;
  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }
}

.searchStoreForm {
  padding-bottom: 0px;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: normal;
    font-weight: bold;
    color: $tertiary;
  }
  .form-action {
    .q-btn {
      font-size: 16px;
      @media (min-width: 1681px) {
        font-size: 18px;
      }
    }
  }
  .allowLocation {
    display: inline-block;
    padding: 5px 0;
    color: #0f141a;
    @media (max-width: 599px) {
      span {
        display: none;
      }
    }
  }
  .searchStoreAction {
    margin-top: 16px;
    .q-btn {
      min-width: 200px;
    }
    @media (max-width: 767px) {
      margin-top: 0px;
      .q-btn {
        width: 40%;
        max-width: 140px;
        min-width: 128px;
        .q-btn__wrapper {
          padding: 8px 16px;
        }
      }
    }
  }
}
.searchStore,
.searchstore-dialog {
  padding: 0 15px;
  @media (max-width: 599px) {
    padding-bottom: 0;
  }
  @media (min-width: 1024px) {
    padding: 0 35px;
  }
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0;
  }
}

.searchstore-dialog {
  .searchStore {
    padding: 0;
    padding-bottom: 15px;
    @media (min-width: 1024px) {
      padding-bottom: 0;
    }
  }
  .delivery-options {
    margin-bottom: 5px;

    @media (min-width: 1680px) {
      margin-bottom: 26px;
    }
    &__header {
      width: 60px;
      height: 60px;
      min-height: 60px;
      min-width: 60px;
      margin-bottom: 10px;

      @media (min-width: 1680px) {
        width: 80px;
        height: 80px;
        min-height: 80px;
        min-width: 80px;
      }
    }
    &__title {
      font-size: 12px;
      @media (min-width: 1680px) {
        font-size: 14px;
      }
    }
    &__avatar {
      margin-bottom: 0;
      width: 50px;
      height: 50px;
      min-height: 46px;

      .q-avatar__content {
        > i {
          font-size: 20px !important;
        }
      }
      @media (min-width: 480px) {
        .q-avatar__content {
          > i {
            font-size: 22px !important;
          }
        }
      }
      @media (min-width: 1680px) {
        width: 70px;
        height: 70px;
        min-height: 66px;
        .q-avatar__content {
          > i {
            font-size: 30px !important;
          }
        }
      }
    }
    &__badge {
      width: 18px;
      height: 18px;

      @media (min-width: 480px) {
        > i {
          font-size: 10px !important;
        }
      }
      @media (min-width: 1680px) {
        width: 20px;
        height: 20px;
        > i {
          font-size: 14px !important;
        }
      }
    }
  }
  .searchStoreAction {
    margin-top: 0;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
}

// custom autocomplete
// .autocomplete {
//   position: relative;
//   display: inline-block;
// }

// /* Styling for input field */
// .autocomplete input {
//   width: 300px;
//   padding: 10px;
//   font-size: 16px;
// }

/* Styling for autocomplete dropdown */
.autocomplete .autocomplete-items {
  // position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.fulfillment-autocomplete {
  position: relative;

  .autocomplete-lists {
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    z-index: 9;
    width: calc(100% + 24px);
    left: -12px;
    max-height: 195px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  }
  &.show .autocomplete-lists {
    display: block;
  }

  /* Styling for autocomplete item */
  .autocomplete-items {
    color: #333;
    padding: 3px;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    &:last-child {
      border: none;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

/* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
.pac-container:after {
  background-image: none !important;
  height: 0px;
}
</style>
