var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.orderDetail.substitutedOrderLineItems &&
    _vm.orderDetail.substitutedOrderLineItems.length > 0
  )?_c('div',{staticClass:"col-12 col-md-12 subtitute_linked"},[_c('div',{staticClass:"orderSingleLabel"},[_vm._v("Substitutions")]),_vm._l((_vm.orderDetail.substitutedOrderLineItems),function(substitutedOrderLineItem,index){return _c('q-list',{key:`${substitutedOrderLineItem.orderLineItemID}-${index}`,staticClass:"singleItem"},[_c('q-item',{class:[
        'item-start',
        substitutedOrderLineItem.linkedProductLineItem
          ? 'link-products'
          : !substitutedOrderLineItem.isCurrent &&
            substitutedOrderLineItem.qty === 0
          ? 'text-grey text-weight-regular'
          : '',
      ]},[_c('q-item-section',{class:{
          blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
        },attrs:{"avatar":""}},[_c('q-avatar',{attrs:{"square":""}},[_c('box-image',{attrs:{"link":substitutedOrderLineItem.isBlacklisted
                ? ''
                : {
                    name: 'Detail',
                    params: {
                      seoName: substitutedOrderLineItem.seoName,
                    },
                  },"imageSrc":substitutedOrderLineItem.image}})],1)],1),_c('q-item-section',{class:{
          blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
        }},[_c('q-item-label',[_c('router-link',{attrs:{"to":{
              name: 'Detail',
              params: {
                seoName: substitutedOrderLineItem.seoName,
              },
            }}},[_c('span',{class:!substitutedOrderLineItem.isCurrent &&
                substitutedOrderLineItem.qty === 0
                  ? 'block text-strike'
                  : ''},[_vm._v(_vm._s(substitutedOrderLineItem.productName))])]),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]}),(_vm.isMobile)?[_c('div',{staticClass:"q-my-sm"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(substitutedOrderLineItem.allowSubstitute),expression:"substitutedOrderLineItem.allowSubstitute"}],staticClass:"allowed q-ml-none"},[_vm._v("\n                Substitution Allowed\n                "),_c('SvgIcon',{staticClass:"q-ml-sm",attrs:{"icon":"info-outline"}})],1)])]:[_c('span',{directives:[{name:"show",rawName:"v-show",value:(substitutedOrderLineItem.allowSubstitute),expression:"substitutedOrderLineItem.allowSubstitute"}],staticClass:"allowed"},[_vm._v("\n              Substitution Allowed\n              "),_c('SvgIcon',{staticClass:"q-ml-sm",attrs:{"icon":"info-outline"}})],1)]],2),_c('q-item-label',[(substitutedOrderLineItem.instruction)?_c('div',{staticClass:"text-body2 word-break-word"},[_c('strong',{staticClass:"text-grey-6"},[_vm._v("Item instruction:")]),_c('br'),_vm._v("\n            "+_vm._s(substitutedOrderLineItem.instruction || '(not set)')+"\n          ")]):_vm._e(),_c('div',{staticClass:"text-body2 word-break-word"},[_vm._v("\n            "+_vm._s(substitutedOrderLineItem.qty)+" x\n            "),_c('strong',{staticClass:"q-mr-sm"},[_vm._v("\n              "+_vm._s(_vm._f("currency")(substitutedOrderLineItem.price))+"\n            ")])])]),_c('q-item-label',{attrs:{"caption":""}},[(substitutedOrderLineItem.isBlacklisted)?_c('span',{staticClass:"text-negative text-body2 block q-mt-xs"},[_c('q-icon',{attrs:{"name":"info"}}),_vm._v(" Product No Longer on Site")],1):_vm._e()])],1),_c('q-item-section',{staticClass:"text-left col-2 col-sm-2 col-md-2 trackQty",attrs:{"side":""}}),_c('q-item-section',{staticClass:"text-right subs-linked-price",attrs:{"side":""}},[_c('q-item-label',{staticClass:"orderItemPrice"},[(
              substitutedOrderLineItem.totalPrice !=
                substitutedOrderLineItem.finalPrice &&
              substitutedOrderLineItem.totalPrice > 0
            )?_c('div',{staticClass:"text-strike strike-price text-grey text-weight-regular",domProps:{"innerHTML":_vm._s(
              _vm.$options.filters.currency(substitutedOrderLineItem.totalPrice)
            )}}):_vm._e(),_c('div',{class:!substitutedOrderLineItem.isCurrent &&
              substitutedOrderLineItem.qty === 0
                ? 'text-strike'
                : ''},[_vm._v("\n            "+_vm._s(_vm._f("currency")(substitutedOrderLineItem.finalPrice)))])]),(
            !substitutedOrderLineItem.isCurrent &&
            substitutedOrderLineItem.qty === 0
          )?_c('q-item-label',{staticClass:"caption product-not-available-label"},[_vm._v("\n          Product not available\n        ")]):_vm._e()],1),(substitutedOrderLineItem.linkedProductLineItem)?_c('q-item',{class:[
          'linked-product',
          {
            blacklistedproduct:
              substitutedOrderLineItem.linkedProductLineItem.isBlacklisted,
          },
        ]},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-avatar',{attrs:{"square":"","color":"white"}},[_c('box-image',{attrs:{"link":substitutedOrderLineItem.linkedProductLineItem.isBlacklisted
                  ? ''
                  : {
                      name: 'Detail',
                      params: {
                        seoName:
                          substitutedOrderLineItem.linkedProductLineItem
                            .seoName,
                      },
                    },"imageSrc":substitutedOrderLineItem.linkedProductLineItem.image}})],1)],1),_c('q-item-section',[_c('q-item-label',[_c('router-link',{attrs:{"to":{
                name: 'Detail',
                params: {
                  seoName:
                    substitutedOrderLineItem.linkedProductLineItem.seoName,
                },
              }}},[_c('span',{class:substitutedOrderLineItem.linkedProductLineItem.qty == 0
                    ? 'text-strike'
                    : ''},[_vm._v(_vm._s(substitutedOrderLineItem.linkedProductLineItem.productName))])]),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                substitutedOrderLineItem.linkedProductLineItem.isLinkedProduct
              ),expression:"\n                substitutedOrderLineItem.linkedProductLineItem.isLinkedProduct\n              "}],staticClass:"allowed"},[_vm._v("\n              Linked Product\n              "),_c('SvgIcon',{staticClass:"q-ml-sm",attrs:{"icon":"info-outline"}})],1)],1),_c('q-item-label',{attrs:{"caption":"","lines":"2"}},[_vm._v("\n            "+_vm._s(substitutedOrderLineItem.linkedProductLineItem.qty)+" x\n            "),_c('strong',{staticClass:"q-mr-sm"},[_vm._v("\n              "+_vm._s(_vm._f("currency")(substitutedOrderLineItem.linkedProductLineItem.price))+"\n            ")])]),_c('q-item-label',{attrs:{"caption":""}},[(
                substitutedOrderLineItem.linkedProductLineItem.isBlacklisted
              )?_c('span',{staticClass:"text-negative text-body2 block q-mt-xs"},[_c('q-icon',{attrs:{"name":"info"}}),_vm._v(" Product No Longer on Site")],1):_vm._e()])],1),_c('q-item-section',{staticClass:"text-right linked-product__price",attrs:{"side":""}},[_c('q-item-label',{staticClass:"orderItemPrice"},[(
                substitutedOrderLineItem.linkedProductLineItem.totalPrice !=
                  substitutedOrderLineItem.linkedProductLineItem.finalPrice &&
                substitutedOrderLineItem.linkedProductLineItem.totalPrice > 0
              )?_c('div',{staticClass:"text-strike strike-price text-grey text-weight-regular",domProps:{"innerHTML":_vm._s(
                _vm.$options.filters.currency(
                  substitutedOrderLineItem.linkedProductLineItem.totalPrice
                )
              )}}):_vm._e(),_c('div',{class:substitutedOrderLineItem.linkedProductLineItem.qty == 0
                  ? 'text-strike'
                  : ''},[_vm._v("\n              "+_vm._s(_vm._f("currency")(substitutedOrderLineItem.linkedProductLineItem.finalPrice)))])])],1)],1):_vm._e()],1),_vm._l((substitutedOrderLineItem.newOrderLineItem),function(newOrderLineItem,index){return _c('q-list',{key:`${substitutedOrderLineItem.newOrderLineItem}-${index}`,staticClass:"singleItem substitution",class:!newOrderLineItem.isSubtitutionAccepted ? 'decline' : ''},[_c('q-item',{class:[
          'item-start',
          newOrderLineItem && newOrderLineItem.linkedProductLineItem
            ? 'link-products'
            : 'link-products',
          !newOrderLineItem.isSubtitutionAccepted ? 'disable' : '',
        ]},[_c('q-item-section',{class:{
            blacklistedproduct: newOrderLineItem.isBlacklisted,
          },attrs:{"avatar":""}},[_c('q-avatar',{attrs:{"square":"","color":"white"}},[_c('box-image',{attrs:{"link":newOrderLineItem.isBlacklisted
                  ? ''
                  : {
                      name: 'Detail',
                      params: {
                        seoName: newOrderLineItem.seoName,
                      },
                    },"imageSrc":newOrderLineItem.image}})],1)],1),_c('q-item-section',{class:{
            blacklistedproduct: newOrderLineItem.isBlacklisted,
          }},[_c('q-item-label',[_c('router-link',{attrs:{"to":{
                name: 'Detail',
                params: {
                  seoName: newOrderLineItem.seoName,
                },
              }}},[_c('span',{class:!newOrderLineItem.isSubtitutionAccepted
                    ? 'block text-strike'
                    : ''},[_vm._v(_vm._s(newOrderLineItem.productName))])]),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]}),(newOrderLineItem.isLinkedProduct)?_c('span',{staticClass:"allowed"},[_vm._v("\n              Linked Product\n              "),_c('SvgIcon',{staticClass:"q-ml-sm",attrs:{"icon":"info-outline"}})],1):_vm._e()],1),_c('q-item-label',{attrs:{"caption":"","lines":"2"}},[_vm._v("\n            "+_vm._s(newOrderLineItem.qty)+" x\n            "),_c('strong',{staticClass:"q-mr-sm"},[_vm._v("\n              "+_vm._s(_vm._f("currency")(newOrderLineItem.price))+"\n            ")])]),_c('q-item-label',{attrs:{"caption":""}},[(newOrderLineItem.isBlacklisted)?_c('span',{staticClass:"text-negative text-body2 block q-mt-xs"},[_c('q-icon',{attrs:{"name":"info"}}),_vm._v(" Product No Longer on Site")],1):_vm._e()])],1),_c('q-item-section',{staticClass:"text-center subs-linked-price",attrs:{"side":""}},[_c('q-item-label',{staticClass:"orderItemPrice"},[(
                newOrderLineItem.totalPrice != newOrderLineItem.finalPrice &&
                newOrderLineItem.totalPrice > 0
              )?_c('div',{staticClass:"text-strike strike-price text-grey text-weight-regular",domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(newOrderLineItem.totalPrice))}}):_vm._e(),_c('div',{class:!newOrderLineItem.isSubtitutionAccepted
                  ? 'block text-strike'
                  : ''},[_vm._v("\n              "+_vm._s(_vm._f("currency")(newOrderLineItem.finalPrice))+"\n            ")])]),(
              newOrderLineItem.isSubtitutionAccepted &&
              !_vm.orderDetail.orderCompleted &&
              _vm.showDeclineSubstitutionLink
            )?[_c('q-item-label',{staticClass:"caption decline",class:{
                invisible:
                  _vm.$route.name == 'TrackGuestOrder' ||
                  (_vm.isLoggedIn && _vm.orderDetail.codeName != 'sp'),
              }},[_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.declineItem(newOrderLineItem)}}},[_vm._v("\n                Decline Substitution\n              ")])])]:(!newOrderLineItem.isSubtitutionAccepted)?[_c('q-item-label',{staticClass:"caption decline"},[_vm._v(" Declined")])]:_vm._e()],2)],1),(newOrderLineItem && newOrderLineItem.linkedProductLineItem)?_c('q-item',{class:[
          'linked-product',
          {
            blacklistedproduct:
              newOrderLineItem.linkedProductLineItem.isBlacklisted,
          },
          !newOrderLineItem.isSubtitutionAccepted ? 'disable' : '',
        ]},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-avatar',{attrs:{"square":"","color":"white"}},[_c('box-image',{attrs:{"link":newOrderLineItem.linkedProductLineItem.isBlacklisted
                  ? ''
                  : {
                      name: 'Detail',
                      params: {
                        seoName:
                          newOrderLineItem.linkedProductLineItem.seoName,
                      },
                    },"imageSrc":newOrderLineItem.linkedProductLineItem.image}})],1)],1),_c('q-item-section',[_c('q-item-label',[_c('router-link',{attrs:{"to":{
                name: 'Detail',
                params: {
                  seoName: newOrderLineItem.linkedProductLineItem.seoName,
                },
              }}},[_c('span',{class:!newOrderLineItem.linkedProductLineItem
                    .isSubtitutionAccepted
                    ? 'text-strike'
                    : ''},[_vm._v(_vm._s(newOrderLineItem.linkedProductLineItem.productName))])]),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(newOrderLineItem.linkedProductLineItem.isLinkedProduct),expression:"newOrderLineItem.linkedProductLineItem.isLinkedProduct"}],staticClass:"allowed"},[_vm._v("\n              Linked Product\n              "),_c('SvgIcon',{staticClass:"q-ml-sm",attrs:{"icon":"info-outline"}})],1)],1),_c('q-item-label',{attrs:{"caption":"","lines":"2"}},[_vm._v("\n            "+_vm._s(newOrderLineItem.linkedProductLineItem.qty)+"\n            x\n            "),_c('strong',{staticClass:"q-mr-sm"},[_vm._v("\n              "+_vm._s(_vm._f("currency")(newOrderLineItem.linkedProductLineItem.price))+"\n            ")])]),_c('q-item-label',{attrs:{"caption":""}},[(newOrderLineItem.linkedProductLineItem.isBlacklisted)?_c('span',{staticClass:"text-negative text-body2 block q-mt-xs"},[_c('q-icon',{attrs:{"name":"info"}}),_vm._v(" Product No Longer on Site")],1):_vm._e()])],1),_c('q-item-section',{staticClass:"text-right linked-product__price",attrs:{"side":""}},[_c('q-item-label',{staticClass:"orderItemPrice"},[(
                newOrderLineItem.linkedProductLineItem.totalPrice !=
                  newOrderLineItem.linkedProductLineItem.finalPrice &&
                newOrderLineItem.linkedProductLineItem.totalPrice > 0
              )?_c('div',{staticClass:"text-strike strike-price text-grey text-weight-regular",domProps:{"innerHTML":_vm._s(
                _vm.$options.filters.currency(
                  newOrderLineItem.linkedProductLineItem.totalPrice
                )
              )}}):_vm._e(),_c('div',{class:!newOrderLineItem.linkedProductLineItem.isSubtitutionAccepted
                  ? 'text-strike'
                  : ''},[_vm._v("\n              "+_vm._s(_vm._f("currency")(newOrderLineItem.linkedProductLineItem.finalPrice))+"\n            ")])]),(newOrderLineItem.linkedProductLineItem.qty == 0)?_c('q-item-label',{staticClass:"caption product-not-available-label"},[_vm._v("\n            Product not available\n          ")]):_vm._e(),(
              !newOrderLineItem.linkedProductLineItem.isSubtitutionAccepted
            )?[_c('q-item-label',{staticClass:"caption decline"},[_vm._v(" Declined ")])]:_vm._e()],2)],1):_vm._e()],1)})],2)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }