<template>
  <div
    ref="OrderLevelinstruction"
    :class="[
      'cartItemInstruction q-mt-sm relative-position',
      showLoader ? 'product-cart-loading' : '',
      isMobile ? '' : '',
    ]"
    v-if="isinstock"
  >
    <div
      class="relative-position product-cart product-outstock"
      v-if="isLabelTextarea"
    >
      <div class="row q-col-gutter-xs">
        <div class="col-12 col-sm-12"
          ><div class="text-caption text-grey-6">Item Instruction:</div></div
        >
        <div class="col col-sm-9">
          <div class="text-body2 word-break-word" v-if="!readMore"
            >{{ textareavalue.substring(0, 50) + '...'
            }}<q-btn
              unelevated
              no-caps
              flat
              color="secondaryOnBody"
              label="More"
              size="12px"
              padding="0 5px"
              class="no-hover text-underline"
              @click="readMoreInst"
              v-if="!isMobile"
            />
            <q-btn
              unelevated
              no-caps
              flat
              color="secondaryOnBody"
              label="More"
              size="12px"
              padding="0 5px"
              class="no-hover text-underline"
              @click="
                changeDialogState({
                  dialog: 'ItemInstructionMobile',
                  val: true,
                  properties: {
                    dialogText: textareavalue,
                  },
                })
              "
              v-else
            />
          </div>
          <div class="text-body2 word-break-word" v-else
            >{{ textareavalue }}
            <q-btn
              unelevated
              no-caps
              flat
              color="secondaryOnBody"
              label="Less"
              size="12px"
              padding="0 5px"
              class="no-hover text-underline"
              @click="readMoreInst"
          /></div>
        </div>
        <div class="col-auto col-sm-auto">
          <q-btn
            v-if="textareavalue.length"
            no-caps
            round
            dense
            unelevated
            text-color="grey-7"
            icon="edit"
            size="10px"
            padding="6px"
            @click="OnClickTextArea"
          />
        </div>
        <div class="col-auto col-sm-auto">
          <q-btn
            v-if="textareavalue.length"
            no-caps
            round
            dense
            unelevated
            text-color="grey-7"
            icon="close"
            size="10px"
            padding="6px"
            @click="OnClickDeleteTextArea"
          />
        </div>
      </div>
    </div>
    <div v-else-if="!isVisibleTextarea && !isLabelTextarea">
      <q-btn
        no-caps
        unelevated
        :label="'+ Add instruction'"
        padding="0"
        size="13px"
        class="text-underline no-hover"
        @click="OnClickTextArea"
      />
    </div>
    <div v-else>
      <div class="row q-col-gutter-sm">
        <div class="col-12 col-sm-10 relative-position">
          <!-- Loader -->
          <q-inner-loading
            :showing="showLoader"
            style="background: rgb(0 0 0 / 15%); border-radius: 7px"
            class="q-mt-sm q-ml-sm"
          >
            <q-spinner color="tertiary" size="20px" :thickness="3" />
          </q-inner-loading>
          <!-- Loader -->
          <div class="form-field__area pb-0">
            <q-input
              outlined
              v-model="textareavalue"
              type="textarea"
              color="grey"
              label="Enter Instruction"
              maxlength="255"
              :rules="[(val) => validateRequired(val)]"
              :lazy-rules="lazyValidationRules"
            >
            </q-input
            ><span class="float-right text-caption"
              >{{ textareavalue.length }}/255</span
            >
          </div>
        </div>
        <div class="col-12 q-pt-none"></div>
        <div class="col-auto q-mb-sm">
          <q-btn
            no-caps
            unelevated
            :label="getProductInstruction().length ? 'Save' : 'Add'"
            size="12px"
            padding="xs"
            color="primary"
            class="q-pr-sm q-pl-sm"
            @click="UpdateItemInstruction()"
          />
        </div>
        <div class="col-auto q-mb-sm">
          <q-btn
            no-caps
            unelevated
            label="Cancel"
            size="12px"
            padding="xs"
            color="tertiary"
            class="q-pr-sm q-pl-sm"
            @click="OnClickCancel"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ToDo: Need Code Refactor
import { mapGetters } from 'vuex'

export default {
  name: 'OrderLevelinstruction',
  props: {
    isinstock: {
      type: Boolean,
      default: true,
    },
    productId: {
      type: String,
      default: () => {
        return ''
      },
    },
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
    productFulFillments: {
      type: Array,
      default: () => {
        return new Array()
      },
    },
    showFullFillment: {
      type: Boolean,
      default: true,
    },
    isfromEditOrderLevel: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
    cartLineItem: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      showLoader: false,
      focus: false,
      cartDelivery: '',
      noOfItemsInCart: null,
      isLabelTextarea: false,
      isVisibleTextarea: false,
      textareavalue: '',
      readMore: false,
    }
  },
  computed: {
    ...mapGetters('common', ['businessFulfillments']),
    ...mapGetters('cart', ['getProductById']),
    ...mapGetters('order', ['recentItems']),
    fulFillmentId() {
      return this.selectedFulfillment.fulfillmentId
    },
    selectedFulFillment() {
      for (let fulFillment of this.productFulFillments) {
        if (fulFillment.codeName == this.deliveryMode) {
          return fulFillment
        }
      }
      return false
    },
    isItemInCart() {
      return this.cartItemInfo.orderLineItemID ? true : false
    },
  },
  mounted() {
    this.OnUpdateFlag()
  },
  methods: {
    getProductQty() {
      if (this.isfromEditOrderLevel) {
        let info = this.recentItems[this.productId] || this.product
        return parseInt(info?.qty || 0)
      } else {
        let productInfo = this.getProductById(this.productId)
        return productInfo?.qty || 0
      }
    },
    getProductInstruction() {
      if (this.isfromEditOrderLevel) {
        let info = this.recentItems[this.productId] || this.product
        return info?.instruction || ''
      } else {
        let productInfo = this.getProductById(this.productId)
        return productInfo?.instruction || ''
      }
    },
    OnUpdateFlag() {
      this.textareavalue = this.getProductInstruction()
      this.isLabelTextarea = this.textareavalue.length ? true : false

      if (this.textareavalue.length <= 0) {
        this.isLabelTextarea = false
        this.isVisibleTextarea = false
      }
    },
    OnClickTextArea() {
      this.isVisibleTextarea = true
      this.isLabelTextarea = false
    },
    OnClickDeleteTextArea() {
      this.UpdateItemInstruction(true)
    },
    OnClickCancel() {
      this.OnUpdateFlag()
    },
    setLoading(loading = false) {
      this.showLoader = loading
    },
    async UpdateItemInstruction(isDelete = false) {
      if (this.textareavalue.length <= 0) {
        return
      }

      if (!isDelete && this.textareavalue == this.getProductInstruction()) {
        this.OnUpdateFlag()
        return
      }

      let alertMessage = ''
      if (
        this.textareavalue.length &&
        this.textareavalue != this.getProductInstruction()
      ) {
        alertMessage = 'Instruction edited successfully.'
      }
      if (!this.getProductInstruction().length) {
        alertMessage = 'Instruction added successfully.'
      }
      if (isDelete) {
        alertMessage = 'Instruction removed successfully.'
      }

      if (this.isfromEditOrderLevel) {
        let params = {
          orderID: this.orderId,
          editOrderLineItems: [
            {
              productId: this.productId,
              qty: this.getProductQty(),
              instruction: isDelete ? '' : this.textareavalue,
            },
          ],
        }
        let response = await this.$store.dispatch('order/updateOrder', params)
        if (response) {
          this.OnUpdateFlag()
          if (alertMessage) {
            this.notify(alertMessage)
          }
        }
      } else {
        let params = {
          fulfillmentMethodId: this.fulFillmentId,
          products: [
            {
              productId: this.productId,
              qty: this.getProductQty(),
              instruction: isDelete ? '' : this.textareavalue,
            },
          ],
          apiVersion: 'v1',
        }
        this.setLoading(true)
        let response = await this.$store.dispatch(
          'cart/addUpdateCartDetail',
          params
        )
        if (response) {
          this.OnUpdateFlag()
          if (alertMessage) {
            this.notify(alertMessage)
          }
        }
        this.setLoading(false)
      }
    },
    notify(message) {
      this.showSuccess(
        message,
        this.$route.name != 'Cart' && this.cartQty > 0
          ? [
              {
                color: 'dark',
                label: 'View Cart',
                color: 'orange-5',
                handler: () => {
                  if (this.isMobile)
                    this.$router
                      .push({
                        name: 'Cart',
                      })
                      .catch(() => {})
                  else
                    this.changeDialogState({
                      dialog: 'cartDrawer',
                      val: true,
                    })
                },
              },
            ]
          : []
      )
    },
    readMoreInst() {
      this.readMore = !this.readMore
    },
  },
  watch: {
    cartLineItem() {
      this.OnUpdateFlag()
    },
  },
}
</script>
<style lang="scss">
.h-search-2 {
  .q-list {
    .q-item {
      &:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
        .product-cart .dropdown {
          bottom: calc(100% - 1px);
          top: auto;
        }
      }
    }
  }
  .q-scrollarea {
    min-height: 330px;
  }
  &.one-record {
    .q-scrollarea {
      max-height: 220px;
      min-height: 220px;
    }
  }
  &.two-record {
    .q-scrollarea {
      max-height: 270px;
      min-height: 270px;
    }
  }
}

.product-cart {
  position: relative;
  width: 100%;
  .q-btn {
    font-family: 'Lato', Arial, sans-serif;
    font-weight: normal;
  }
  .dropdown {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 1px);
    color: $tertiary;
    background: #fff;
    border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondary) !important;
    border-radius: 0px 0px 3px 3px;
    z-index: 3;
    transition: all 0.3s ease-in-out;
    // &:nth-child(1),
    // &:nth-child(2),
    // &:nth-child(3),
    // &:nth-child(4) {
    //   top: calc(100% - 1px);
    //   bottom: auto;
    // }
    .q-item {
      min-height: 30px;
      padding: 2px 10px;
      flex-wrap: nowrap;
      background: #fff;
      i {
        color: $tertiary;
      }
      .q-item__section {
      }
    }
    .q-item__section--avatar {
      min-width: 24px;
      padding-right: 5px;
      justify-content: center;
    }
    .q-item__section--main {
      font-size: 12px !important;
      color: #314154;
    }
    .q-radio__inner {
      width: 0.4em;
      min-width: 0.4em;
      height: 0.4em;
    }
    .q-hoverable:hover .q-focus-helper {
      background: $secondary;
      background: var(--q-color-secondary) !important;
    }
  }
  .q-btn__wrapper {
    width: auto;
  }
  &:hover .q-btn--rectangle {
    border-radius: 3px 3px 0px 0px;
  }
  &:hover .q-btn--outline .q-btn__wrapper:before {
    border-color: $secondary;
    border-color: var(--q-color-secondaryOnBody) !important;
  }
  .hover:hover .dropdown {
    display: block;
  }
  .add-cart {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
    background: $white;
    color: $tertiary;
    border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondaryOnBody) !important;
    border-radius: 5px;
    cursor: pointer;
    .icon,
    .btn,
    .arrow {
      padding: 10px 15px;
      min-height: 17px;
      @media (max-width: 767px) {
        /*padding-left: 8px !important;
        padding-right: 8px !important;*/
        padding: 10px;
      }
      @media (max-width: 374px) {
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
    .arrow {
      @media (max-width: 374px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
    .icon {
      padding: 10px;
      line-height: 16px;
      display: none;
      i {
        font-size: 14px;
      }
    }
    &.addDelivery .icon {
      display: flex;
    }
    .btn {
      font-size: 14px;
      padding: 10px 15px;
    }
    .arrow {
      border-left: 1px solid $secondary;
      border-left: 1px solid var(--q-color-secondary) !important;
      .ar {
        line-height: 13px;
      }
      i {
        font-size: 12px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      .dropdown {
        left: 0px;
        right: 0px;
      }
    }
    &.addDelivery {
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: $secondary;
      color: var(--q-color-secondaryText) !important;
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      cursor: pointer;
      .arrow {
        border-left: 1px solid $secondary;
        border-left: 1px solid var(--q-color-secondary) !important;
      }
      .btn {
        padding: 10px 5px;
        @media (max-width: 767px) {
          padding: 10px 10px 10px 0px;
        }
        @media (max-width: 375px) {
          font-size: 12px;
        }
      }
    }
  }
  .added-cart {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    color: $tertiary;
    // border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondaryOnBody) !important;
    // outline: 1px solid var(--q-color-secondaryOnBody);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: relative;
    .icon,
    .btn {
      // padding: 10px 8px;
      min-height: 17px;
      @media (max-width: 374px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .icon {
      padding: 9px 10px;
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: var(--q-color-secondaryText) !important;
      line-height: 16px;
      cursor: pointer;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      i {
        font-size: 14px;
      }
    }
    .btn {
      flex: 10000 0 0%;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .q-btn {
        flex: 0 0 50px;
        @media (max-width: 1024px) {
          flex: 0 0 30px;
        }
        .q-focus-helper,
        .q-ripple {
          display: none;
        }
        @media (max-width: 374px) {
          flex: 0 0 36px;
        }
      }
      .q-field {
        flex: 10000 0 0%;
        @media (max-width: 991px) {
          font-size: 10px;
        }
      }
    }
    .q-field__control,
    .q-field__marginal {
      height: 35px;
      padding: 0;
    }
    .q-field__native {
      text-align: center;
    }
    .q-field--outlined .q-field__control:before,
    .q-field--outlined .q-field__control:after {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  /* Bottom */
  &:hover .add-cart {
    border-radius: 5px 5px 0px 0px;
  }
  &:hover .addDelivery {
    border-radius: 5px 5px 5px 5px;
  }
  &.no-rounder:hover {
    .add-cart.addDelivery,
    .added-cart {
      border-radius: 5px 5px 0 0;
      .icon {
        border-top-left-radius: 0;
      }
    }
    .added-cart {
      .dropdown {
        left: -1px;
        right: -1px;
      }
    }
    .addDelivery {
      border-radius: 5px;
    }
  }
  /* Bottom */
  /* Top */
  &.top {
    .dropdown {
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      top: auto;
      bottom: calc(100% - 1px);
      border-radius: 3px 3px 0px 0px;
    }
    &:hover .add-cart {
      border-radius: 0px 0px 5px 5px;
    }
    &:hover .addDelivery {
      border-radius: 5px 5px 5px 5px;
    }
    &.no-rounder:hover {
      .add-cart.addDelivery,
      .added-cart {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
  /* Top */
  /* Loader */
  &.product-cart-loading {
    .added-cart {
      .q-inner-loading {
        svg {
          position: absolute;
          left: 10px;
          //  @media (max-width: 767px) {
          //    left:20px;
          // }
        }
      }
    }
    .add-cart {
      .q-inner-loading {
        svg {
          position: absolute;
          right: 10px;
        }
      }
    }
    .delivery-mode,
    .ar {
      opacity: 0.3;
      transition: opacity 0.1s;
      -webkit-transition: opacity 0.1s;
    }
  }
  /* Loader */
  /* Out of Stock */
  .btn-outstock {
    min-height: 39px;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    background: rgba(255, 0, 0, 0.1);
    color: rgba(255, 0, 0, 1);
    display: block;
    border-radius: 5px;
  }
  /* Out of Stock */
}
.q-product-slider,
.discount-content {
  .product-cart {
    .dropdown {
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      top: auto;
      bottom: calc(100% - 1px);
      border-radius: 3px 3px 0px 0px;
    }
    &:hover .add-cart {
      border-radius: 0px 0px 5px 5px;
    }
    &:hover .addDelivery {
      border-radius: 5px 5px 5px 5px;
    }
    &.no-rounder:hover {
      .add-cart.addDelivery,
      .added-cart {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
}
.error {
  color: red;
  width: 100%;
  font-size: 10px;
  position: absolute;
  font-weight: bold;
  top: 40px;
  &.top {
    top: -13px;
  }
}

.disable {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.notify-custom-mobile-postion {
  @media (max-width: 767px) {
    margin-top: -96px;
  }
}
</style>
