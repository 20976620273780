<template>
  <div class="toggleMap" v-if="coordinates.length">
    <GmapMap
      :api-key="getMapApiKey"
      class="full-width"
      :center="coordinates[0].geoLocation"
      :zoom="calculateZoom"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoPosition"
        :opened="infoOpened"
        @closeclick="infoOpened = false"
      >
        <div v-html="infoContent" style="padding: 5px"></div>
      </gmap-info-window>
      <GmapMarker
        :key="index"
        v-for="(markerOption, index) in markerOptions"
        :options="markerOption"
        :clickable="true"
        @click="toggleInfo(markerOption, index)"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'GoogleMapRadius',
  props: {
    coordinates: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getMapApiKey() {
      return process.env.GOOGLE_MAP_API_KEY
    },
    calculateZoom() {
      return 6
    },
  },
  data() {
    return {
      markerOptions: [],
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    }
  },
  mounted() {
    if (this.coordinates && this.coordinates.length) {
      // this.radiusOptions.center = this.coordinates.geoLocation
      // this.markerOptions.title = this.coordinates.locationName
      // this.markerOptions.position = this.coordinates.geoLocation
      this.markerOptions = this.coordinates.map((item) => {
        return {
          title: item.locationName,
          position: item.geoLocation,
        }
      })
    }
  },
  watch: {
    coordinates: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.length) {
          this.markerOptions = newVal.map((item) => {
            return {
              title: item.locationName,
              position: item.geoLocation,
            }
          })
        }
      },
      deep: true,
    },
  },
  methods: {
    toggleInfo: function (marker, key) {
      this.infoPosition = marker.position
      this.infoContent = `<strong>Store Name: ${marker.title}</strong>`
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened
      } else {
        this.infoOpened = true
        this.infoCurrentKey = key
      }
    },
  },
}
</script>

<style lang="scss">
.toggleMap .q-expansion-item__toggle-icon {
  display: none;
}
.toggleMap {
  .q-item__section--avatar {
    min-width: auto;
  }
}
#map-canvas {
  min-width: 300px;
  min-height: 300px;
}
#gmap-map {
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}
// .q-expansion-item {
//   .q-item {
//     text-align: right;
//     width: 40px;
//     margin-left: auto;
//     padding: 8px;
//     min-height: 40px;
//   }
// }
</style>
