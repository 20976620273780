<template>
  <div
    v-if="
      orderDetail.substitutedOrderLineItems &&
      orderDetail.substitutedOrderLineItems.length > 0
    "
    class="col-12 col-md-12 subtitute_linked"
  >
    <div class="orderSingleLabel">Substitutions</div>
    <q-list
      v-for="(
        substitutedOrderLineItem, index
      ) of orderDetail.substitutedOrderLineItems"
      class="singleItem"
      :key="`${substitutedOrderLineItem.orderLineItemID}-${index}`"
    >
      <q-item
        :class="[
          'item-start',
          substitutedOrderLineItem.linkedProductLineItem
            ? 'link-products'
            : !substitutedOrderLineItem.isCurrent &&
              substitutedOrderLineItem.qty === 0
            ? 'text-grey text-weight-regular'
            : '',
        ]"
      >
        <q-item-section
          avatar
          :class="{
            blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
          }"
        >
          <q-avatar square>
            <box-image
              :link="
                substitutedOrderLineItem.isBlacklisted
                  ? ''
                  : {
                      name: 'Detail',
                      params: {
                        seoName: substitutedOrderLineItem.seoName,
                      },
                    }
              "
              :imageSrc="substitutedOrderLineItem.image"
            />
          </q-avatar>
        </q-item-section>
        <q-item-section
          :class="{
            blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
          }"
        >
          <q-item-label>
            <router-link
              :to="{
                name: 'Detail',
                params: {
                  seoName: substitutedOrderLineItem.seoName,
                },
              }"
            >
              <span
                :class="
                  !substitutedOrderLineItem.isCurrent &&
                  substitutedOrderLineItem.qty === 0
                    ? 'block text-strike'
                    : ''
                "
                >{{ substitutedOrderLineItem.productName }}</span
              >
            </router-link>
            <br v-show="isMobile" />

            <template v-if="isMobile">
              <div class="q-my-sm">
                <span
                  v-show="substitutedOrderLineItem.allowSubstitute"
                  class="allowed q-ml-none"
                >
                  Substitution Allowed
                  <SvgIcon icon="info-outline" class="q-ml-sm" />
                </span>
              </div>
            </template>
            <template v-else>
              <span
                v-show="substitutedOrderLineItem.allowSubstitute"
                class="allowed"
              >
                Substitution Allowed
                <SvgIcon icon="info-outline" class="q-ml-sm" />
              </span>
            </template>
          </q-item-label>
          <q-item-label>
            <div
              class="text-body2 word-break-word"
              v-if="substitutedOrderLineItem.instruction"
            >
              <strong class="text-grey-6">Item instruction:</strong>
              <br />
              {{ substitutedOrderLineItem.instruction || '(not set)' }}
            </div>
            <div class="text-body2 word-break-word">
              {{ substitutedOrderLineItem.qty }} x
              <strong class="q-mr-sm">
                {{ substitutedOrderLineItem.price | currency }}
              </strong>
            </div>
          </q-item-label>
          <q-item-label caption>
            <span
              v-if="substitutedOrderLineItem.isBlacklisted"
              class="text-negative text-body2 block q-mt-xs"
            >
              <q-icon name="info"></q-icon> Product No Longer on Site</span
            >
          </q-item-label>
        </q-item-section>
        <q-item-section class="text-left col-2 col-sm-2 col-md-2 trackQty" side>
        </q-item-section>
        <q-item-section class="text-right subs-linked-price" side>
          <q-item-label class="orderItemPrice">
            <div
              class="text-strike strike-price text-grey text-weight-regular"
              v-html="
                $options.filters.currency(substitutedOrderLineItem.totalPrice)
              "
              v-if="
                substitutedOrderLineItem.totalPrice !=
                  substitutedOrderLineItem.finalPrice &&
                substitutedOrderLineItem.totalPrice > 0
              "
            />
            <div
              :class="
                !substitutedOrderLineItem.isCurrent &&
                substitutedOrderLineItem.qty === 0
                  ? 'text-strike'
                  : ''
              "
            >
              {{ substitutedOrderLineItem.finalPrice | currency }}</div
            >
          </q-item-label>
          <q-item-label
            class="caption product-not-available-label"
            v-if="
              !substitutedOrderLineItem.isCurrent &&
              substitutedOrderLineItem.qty === 0
            "
          >
            Product not available
          </q-item-label>
        </q-item-section>
        <q-item
          v-if="substitutedOrderLineItem.linkedProductLineItem"
          :class="[
            'linked-product',
            {
              blacklistedproduct:
                substitutedOrderLineItem.linkedProductLineItem.isBlacklisted,
            },
          ]"
        >
          <q-item-section avatar>
            <q-avatar square color="white">
              <box-image
                :link="
                  substitutedOrderLineItem.linkedProductLineItem.isBlacklisted
                    ? ''
                    : {
                        name: 'Detail',
                        params: {
                          seoName:
                            substitutedOrderLineItem.linkedProductLineItem
                              .seoName,
                        },
                      }
                "
                :imageSrc="substitutedOrderLineItem.linkedProductLineItem.image"
              />
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label>
              <router-link
                :to="{
                  name: 'Detail',
                  params: {
                    seoName:
                      substitutedOrderLineItem.linkedProductLineItem.seoName,
                  },
                }"
              >
                <span
                  :class="
                    substitutedOrderLineItem.linkedProductLineItem.qty == 0
                      ? 'text-strike'
                      : ''
                  "
                  >{{
                    substitutedOrderLineItem.linkedProductLineItem.productName
                  }}</span
                >
              </router-link>
              <br v-show="isMobile" />
              <span
                v-show="
                  substitutedOrderLineItem.linkedProductLineItem.isLinkedProduct
                "
                class="allowed"
              >
                Linked Product
                <SvgIcon icon="info-outline" class="q-ml-sm" />
              </span>
            </q-item-label>
            <q-item-label caption lines="2">
              {{ substitutedOrderLineItem.linkedProductLineItem.qty }} x
              <strong class="q-mr-sm">
                {{
                  substitutedOrderLineItem.linkedProductLineItem.price
                    | currency
                }}
              </strong>
            </q-item-label>
            <q-item-label caption>
              <span
                v-if="
                  substitutedOrderLineItem.linkedProductLineItem.isBlacklisted
                "
                class="text-negative text-body2 block q-mt-xs"
              >
                <q-icon name="info"></q-icon> Product No Longer on Site</span
              >
            </q-item-label>
          </q-item-section>
          <q-item-section class="text-right linked-product__price" side>
            <q-item-label class="orderItemPrice">
              <div
                class="text-strike strike-price text-grey text-weight-regular"
                v-html="
                  $options.filters.currency(
                    substitutedOrderLineItem.linkedProductLineItem.totalPrice
                  )
                "
                v-if="
                  substitutedOrderLineItem.linkedProductLineItem.totalPrice !=
                    substitutedOrderLineItem.linkedProductLineItem.finalPrice &&
                  substitutedOrderLineItem.linkedProductLineItem.totalPrice > 0
                "
              />
              <div
                :class="
                  substitutedOrderLineItem.linkedProductLineItem.qty == 0
                    ? 'text-strike'
                    : ''
                "
              >
                {{
                  substitutedOrderLineItem.linkedProductLineItem.finalPrice
                    | currency
                }}</div
              >
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-item>

      <!-- newOrderLineItem -->
      <q-list
        v-for="(
          newOrderLineItem, index
        ) of substitutedOrderLineItem.newOrderLineItem"
        class="singleItem substitution"
        :class="!newOrderLineItem.isSubtitutionAccepted ? 'decline' : ''"
        :key="`${substitutedOrderLineItem.newOrderLineItem}-${index}`"
      >
        <q-item
          :class="[
            'item-start',
            newOrderLineItem && newOrderLineItem.linkedProductLineItem
              ? 'link-products'
              : 'link-products',
            !newOrderLineItem.isSubtitutionAccepted ? 'disable' : '',
          ]"
        >
          <q-item-section
            avatar
            :class="{
              blacklistedproduct: newOrderLineItem.isBlacklisted,
            }"
          >
            <q-avatar square color="white">
              <box-image
                :link="
                  newOrderLineItem.isBlacklisted
                    ? ''
                    : {
                        name: 'Detail',
                        params: {
                          seoName: newOrderLineItem.seoName,
                        },
                      }
                "
                :imageSrc="newOrderLineItem.image"
              />
            </q-avatar>
          </q-item-section>
          <q-item-section
            :class="{
              blacklistedproduct: newOrderLineItem.isBlacklisted,
            }"
          >
            <q-item-label>
              <router-link
                :to="{
                  name: 'Detail',
                  params: {
                    seoName: newOrderLineItem.seoName,
                  },
                }"
              >
                <span
                  :class="
                    !newOrderLineItem.isSubtitutionAccepted
                      ? 'block text-strike'
                      : ''
                  "
                  >{{ newOrderLineItem.productName }}</span
                >
              </router-link>
              <br v-show="isMobile" />
              <span v-if="newOrderLineItem.isLinkedProduct" class="allowed">
                Linked Product
                <SvgIcon icon="info-outline" class="q-ml-sm" />
              </span>
            </q-item-label>
            <q-item-label caption lines="2">
              {{ newOrderLineItem.qty }} x
              <strong class="q-mr-sm">
                {{ newOrderLineItem.price | currency }}
              </strong>
            </q-item-label>
            <q-item-label caption>
              <span
                v-if="newOrderLineItem.isBlacklisted"
                class="text-negative text-body2 block q-mt-xs"
              >
                <q-icon name="info"></q-icon> Product No Longer on Site</span
              >
            </q-item-label>
          </q-item-section>
          <q-item-section class="text-center subs-linked-price" side>
            <q-item-label class="orderItemPrice">
              <div
                class="text-strike strike-price text-grey text-weight-regular"
                v-html="$options.filters.currency(newOrderLineItem.totalPrice)"
                v-if="
                  newOrderLineItem.totalPrice != newOrderLineItem.finalPrice &&
                  newOrderLineItem.totalPrice > 0
                "
              />
              <div
                :class="
                  !newOrderLineItem.isSubtitutionAccepted
                    ? 'block text-strike'
                    : ''
                "
              >
                {{ newOrderLineItem.finalPrice | currency }}
              </div>
            </q-item-label>
            <template
              v-if="
                newOrderLineItem.isSubtitutionAccepted &&
                !orderDetail.orderCompleted &&
                showDeclineSubstitutionLink
              "
            >
              <q-item-label
                class="caption decline"
                :class="{
                  invisible:
                    $route.name == 'TrackGuestOrder' ||
                    (isLoggedIn && orderDetail.codeName != 'sp'),
                }"
              >
                <a
                  href="javascript:void(0);"
                  @click="declineItem(newOrderLineItem)"
                >
                  Decline Substitution
                </a>
              </q-item-label>
            </template>
            <template v-else-if="!newOrderLineItem.isSubtitutionAccepted">
              <q-item-label class="caption decline"> Declined</q-item-label>
            </template>
          </q-item-section>
        </q-item>

        <q-item
          v-if="newOrderLineItem && newOrderLineItem.linkedProductLineItem"
          :class="[
            'linked-product',
            {
              blacklistedproduct:
                newOrderLineItem.linkedProductLineItem.isBlacklisted,
            },
            !newOrderLineItem.isSubtitutionAccepted ? 'disable' : '',
          ]"
        >
          <q-item-section avatar>
            <q-avatar square color="white">
              <box-image
                :link="
                  newOrderLineItem.linkedProductLineItem.isBlacklisted
                    ? ''
                    : {
                        name: 'Detail',
                        params: {
                          seoName:
                            newOrderLineItem.linkedProductLineItem.seoName,
                        },
                      }
                "
                :imageSrc="newOrderLineItem.linkedProductLineItem.image"
              />
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label>
              <router-link
                :to="{
                  name: 'Detail',
                  params: {
                    seoName: newOrderLineItem.linkedProductLineItem.seoName,
                  },
                }"
              >
                <span
                  :class="
                    !newOrderLineItem.linkedProductLineItem
                      .isSubtitutionAccepted
                      ? 'text-strike'
                      : ''
                  "
                  >{{
                    newOrderLineItem.linkedProductLineItem.productName
                  }}</span
                >
              </router-link>
              <br v-show="isMobile" />
              <span
                v-show="newOrderLineItem.linkedProductLineItem.isLinkedProduct"
                class="allowed"
              >
                Linked Product
                <SvgIcon icon="info-outline" class="q-ml-sm" />
              </span>
            </q-item-label>
            <q-item-label caption lines="2">
              {{ newOrderLineItem.linkedProductLineItem.qty }}
              x
              <strong class="q-mr-sm">
                {{ newOrderLineItem.linkedProductLineItem.price | currency }}
              </strong>
            </q-item-label>
            <q-item-label caption>
              <span
                v-if="newOrderLineItem.linkedProductLineItem.isBlacklisted"
                class="text-negative text-body2 block q-mt-xs"
              >
                <q-icon name="info"></q-icon> Product No Longer on Site</span
              >
            </q-item-label>
          </q-item-section>
          <q-item-section class="text-right linked-product__price" side>
            <q-item-label class="orderItemPrice">
              <div
                class="text-strike strike-price text-grey text-weight-regular"
                v-html="
                  $options.filters.currency(
                    newOrderLineItem.linkedProductLineItem.totalPrice
                  )
                "
                v-if="
                  newOrderLineItem.linkedProductLineItem.totalPrice !=
                    newOrderLineItem.linkedProductLineItem.finalPrice &&
                  newOrderLineItem.linkedProductLineItem.totalPrice > 0
                "
              />
              <div
                :class="
                  !newOrderLineItem.linkedProductLineItem.isSubtitutionAccepted
                    ? 'text-strike'
                    : ''
                "
              >
                {{
                  newOrderLineItem.linkedProductLineItem.finalPrice | currency
                }}
              </div>
            </q-item-label>
            <q-item-label
              class="caption product-not-available-label"
              v-if="newOrderLineItem.linkedProductLineItem.qty == 0"
            >
              Product not available
            </q-item-label>
            <template
              v-if="
                !newOrderLineItem.linkedProductLineItem.isSubtitutionAccepted
              "
            >
              <q-item-label class="caption decline"> Declined </q-item-label>
            </template>
          </q-item-section>
        </q-item>
      </q-list>
    </q-list>
  </div>
</template>

<script>
export default {
  name: 'Substitutions',
  props: {
    orderDetail: {
      type: Object,
      default: new Object(),
    },
    showDeclineSubstitutionLink: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    declineItem(substitutedOrderLineItem) {
      this.ftxConfirm(
        'Are you sure you want to decline substituted order line item?'
      ).onOk(async () => {
        this.$store
          .dispatch('order/allowDeclineSubstitution', {
            orderLineItemID: substitutedOrderLineItem.orderLineItemID,
            allowSubstitution: false,
          })
          .then((response) => {
            if (response) {
              this.showSuccess(
                'order line item substitution declined successfully.'
              )
            }
          })
      })
    },
  },
}
</script>
