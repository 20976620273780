<template>
  <div
    v-if="businessConfigurations.enableSubstitution"
    class="orderSubscription"
  >
    <q-item manual-focus tag="label" @click.prevent>
      <q-item-section avatar top>
        <q-checkbox
          color="secondaryOnBody"
          :value="substitutionAllowed"
          @input="substitutionToggle"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label> Allow Substitutions </q-item-label>
        <q-item-label caption>
          Want to make sure you'll have everything you need? Allow substitutions
          and we'll do our best to replace any unavailable items.
        </q-item-label>
      </q-item-section>
    </q-item>
    <!-- <p>
      *Prices shown here may change between now and your pickup or delivery
      date.
      butwe’ll always give you the lowest price.
      Final cost is determined at the time we pack your items.
    </p> -->
    <p
      style="padding-top: 0 !important"
      v-if="
        !isMobile &&
        ((isFor == 'order' &&
          orderInfo.appliedLnsCoupons &&
          orderInfo.appliedLnsCoupons.length) ||
          (isFor == 'cart' && currentCartDetails.appliedLnsCoupons?.length))
      "
    >
      *Coupons may be removed from the order if the applicable items are removed
      either by editing the order or being out of stock.
    </p>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { hideShowFullSpinner } from 'src/utils'

export default {
  name: 'AllowSubstitutions',
  props: {
    isFor: {
      type: String,
      default: 'cart',
    },
    orderId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('cart', [
      'isSubstitutionAllowed',
      'currentCartDetails',
      'recentSubstituteDetail',
    ]),
    ...mapGetters('order', [
      'isSubstitutionAllowedByOrderId',
      'editOrderInfoById',
    ]),
    substitutionAllowed() {
      return this.isFor === 'order'
        ? this.isSubstitutionAllowedByOrderId(this.orderId)
        : this.isSubstitutionAllowed
    },
    orderInfo() {
      return this.editOrderInfoById(this.orderId)
    },
  },
  methods: {
    ...mapActions('order', ['toggleSubstitutionByOrderId']),
    ...mapActions('cart', ['toggleSubstitution']),
    substitutionToggle: async function () {
      if (this.isFor === 'order') {
        this.toggleSubstitutionByOrderId({
          orderId: this.orderId,
          isSubstitutionAllowed: this.substitutionAllowed,
        })
      } else {
        let params = []
        for (let fId in this.currentCartDetails.fulfillments) {
          const fulfillment = this.currentCartDetails.fulfillments[fId]
          if (fulfillment.codeName !== 'sd') {
            for (let olId in fulfillment.shoppingCartItems) {
              const item = fulfillment.shoppingCartItems[olId]
              params.push({
                productID: item.productId,
                fulfillmentMethodID: fulfillment.fulfillmentMethodID,
                orderLineItemID: item.orderLineItemID,
              })
            }
          }
        }
        hideShowFullSpinner(true)
        await this.$store
          .dispatch('cart/getAllSubstituteItemsForProduct', params)
          .then((response) => {
            if (response.success) {
              let objSubstitute = {}
              let noPreferenceOption = {
                image: '',
                price: 0,
                productID: '00000000-0000-0000-0000-000000000000',
                productName: 'No Preference',
                qtyOnHand: 0,
                seoName: '',
                substituteProductID: '00000000-0000-0000-0000-000000000000',
              }
              for (let substituteProduct of response.data) {
                let substituteProducts =
                  Array.isArray(substituteProduct.substituteProductDetails) &&
                  substituteProduct.substituteProductDetails.length
                    ? [
                        ...substituteProduct.substituteProductDetails,
                        noPreferenceOption,
                      ]
                    : []
                objSubstitute[substituteProduct.orderLineItemID] = {
                  orderLineItemID: substituteProduct.orderLineItemID,
                  substituteProducts: substituteProducts,
                }
              }
              this.$store.dispatch('cart/toggleSubstitution', objSubstitute)
            } else {
              this.showError(response.message)
            }
          })
          .finally(() => {
            hideShowFullSpinner(false)
          })
      }
    },
  },
}
</script>
<style lang="scss">
.orderSubscription {
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  p {
    margin: 0;
    padding: 12px 15px;
    font-size: 12px;
    color: #888888;
  }
  .q-item {
    padding: 12px;
    background: #fff;
    border-bottom: 1px solid #cccccc;
    border-radius: 5px;
    .q-item__section--avatar {
      min-width: 50px;
      padding-right: 0;
    }
    .q-item__section--main {
      padding-top: 10px;
      padding-bottom: 5px;
      cursor: text;
    }
    .q-item__label {
      font-size: 16px;
      font-weight: bold;
      color: #0f141a;
      padding-bottom: 5px;
      &.text-caption {
        font-size: 13px;
        font-weight: normal;
        color: #888888;
      }
    }
  }
  @media (max-width: 767px) {
    margin: 0px;
    border: none;
    p {
      display: none;
    }
    .q-item {
      padding: 0;
      border-bottom: none;
      .q-item__section--main {
        padding-top: 0;
      }
      .q-item__label--caption {
        display: none;
      }
    }
  }
}
</style>
