<template>
  <div v-if="addressObj">
    <div class="row q-col-gutter-x-lg">
      <div class="col-12">
        <Alert
          v-if="addAddressAlert"
          className="q-mt-sm"
          :variant="addAddressAlert.variant"
          :show="3"
          dismissible
          @dismissed="resetAddressAlert"
        >
          <span v-html="addAddressAlert.message" />
        </Alert>
      </div>
      <div class="col-xs-12 col-md-12">
        <div class="text-h6">Step 1</div>
        <p><small>*Required Fields</small></p>
      </div>
      <div class="col-xs-12 col-md-6">
        <div class="form-field">
          <q-input
            :readonly="readonly"
            outlined
            :value="addressObj.firstName"
            @input="
              (value) => this.addressFieldChange('firstName', value.trim())
            "
            label="First Name*"
            :rules="[
              (val) => validateRequired(val),
              this.validatePersonName(
                addressObj.firstName,
                'Please enter a valid name'
              ),
            ]"
            :lazy-rules="lazyValidationRules"
            maxlength="40"
            aria-label="ftxcommerce"
          />
        </div>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="form-field">
          <q-input
            :readonly="readonly"
            outlined
            :value="addressObj.lastName"
            @input="
              (value) => this.addressFieldChange('lastName', value.trim())
            "
            label="Last Name*"
            :rules="[
              (val) => validateRequired(val),
              this.validatePersonName(
                addressObj.lastName,
                'Please enter a valid name'
              ),
            ]"
            :lazy-rules="lazyValidationRules"
            maxlength="40"
            aria-label="ftxcommerce"
          />
        </div>
      </div>
      <div
        :class="[
          'col-xs-12',
          addressObj.hasOwnProperty('securityCode') ? 'col-md-6' : 'col-md-12',
        ]"
      >
        <div class="form-field">
          <q-input
            :readonly="readonly"
            outlined
            :value="addressObj.phone"
            @input="(value) => this.addressFieldChange('phone', value.trim())"
            label="Phone*"
            v-mask="phoneMask"
            :rules="[(val) => validateRequired(val), this.validatePhone()]"
            :lazy-rules="lazyValidationRules"
            aria-label="ftxcommerce"
          />
        </div>
      </div>
    </div>
    <div class="row q-col-gutter-x-lg">
      <div class="col-xs-12 col-md-12">
        <div class="text-h6">Step 2</div>
        <p
          ><small
            >Start typing your address and select it as it appears from the
            google results. This will fill in the address information
            below.</small
          >
        </p>
        <p><small>*Required Fields</small></p>
      </div>
      <div class="col-xs-12 col-md-6">
        <div class="form-field">
          <GoogleAutoComplete
            :outlined="true"
            label="Address Line 1*"
            :addressFieldChange="addressFieldChange"
            modelField="line1"
            :value="addressObj.line1.trim()"
            :placechanged="getAddressData"
            :readonly="readonly"
            :rules="[(val) => validateRequired(val)]"
            :lazy-rules="lazyValidationRules"
          />
        </div>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="form-field">
          <q-input
            :readonly="readonly"
            outlined
            :value="addressObj.line2"
            @input="(value) => this.addressFieldChange('line2', value.trim())"
            label="Address Line 2"
            aria-label="ftxcommerce"
          />
        </div>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="form-field">
          <q-input
            :readonly="readonly"
            outlined
            :value="addressObj.city"
            @input="(value) => this.addressFieldChange('city', value.trim())"
            label="City*"
            :rules="[
              (val) => validateRequired(val),
              this.validatePersonName(
                addressObj.city,
                'Please enter valid city'
              ),
            ]"
            :lazy-rules="lazyValidationRules"
            aria-label="ftxcommerce"
          />
        </div>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="form-field">
          <q-input
            :readonly="readonly"
            outlined
            :value="addressObj.zip"
            @input="(value) => this.addressFieldChange('zip', value.trim())"
            label="Zip Code*"
            v-mask="'#####'"
            :rules="[(val) => validateRequired(val), this.validateZip]"
            :lazy-rules="lazyValidationRules"
            aria-label="ftxcommerce"
          />
        </div>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="form-field">
          <q-input
            :readonly="readonly"
            outlined
            :value="addressObj.state"
            @input="(value) => this.addressFieldChange('state', value.trim())"
            label="State*"
            :rules="[
              (val) => validateRequired(val),
              this.validatePersonName(
                addressObj.state,
                'Please enter valid state'
              ),
            ]"
            :lazy-rules="lazyValidationRules"
            aria-label="ftxcommerce"
          />
        </div>
      </div>
      <div class="col-xs-12 col-md-6">
        <div class="form-field">
          <q-input
            outlined
            :value="addressObj.country"
            label="Country*"
            readonly
            :rules="[(val) => validateRequired(val)]"
            :lazy-rules="lazyValidationRules"
            aria-label="ftxcommerce"
          />
        </div>
      </div>

      <!-- ToDo: v-if false Temporary -->
      <div
        class="col-xs-12 col-md-6"
        v-if="addressObj.hasOwnProperty('securityCode') && false"
      >
        <div class="form-field">
          <q-input
            :readonly="readonly"
            outlined
            :value="addressObj.securityCode"
            @input="
              (value) => this.addressFieldChange('securityCode', value.trim())
            "
            label="Security code or a Call box number to access this building"
            maxlength="25"
            aria-label="ftxcommerce"
          />
        </div>
      </div>

      <!-- ToDo: v-if false Temporary -->
      <div
        class="col-xs-12 col-md-12"
        v-if="addressObj.hasOwnProperty('instruction') && false"
      >
        <div class="form-field__area">
          <q-input
            :readonly="readonly"
            bottom-slots
            outlined
            :value="addressObj.instruction"
            @input="
              (value) => this.addressFieldChange('instruction', value.trim())
            "
            type="textarea"
            label="Instruction(optional)"
            placeholder="Message"
            maxlength="255"
            autogrow
            aria-label="ftxcommerce"
          />
          <span
            class="float-right text-caption"
            v-text="`${addressObj.instruction.length}/255`"
          />
        </div>
      </div>
      <div
        class="col-xs-12 col-md-12"
        v-if="addressObj.hasOwnProperty('markAsDefault') && !blnIsDefault"
      >
        <div class="form-field">
          <q-checkbox
            dense
            :value="addressObj.markAsDefault"
            @input="(value) => this.addressFieldChange('markAsDefault', value)"
            label="Set this as your Default Address"
          />
        </div>
      </div>
      <template v-if="selectedFulfillmentCodeName === 'scd'">
        <div class="col-xs-12 col-md-12" v-if="coordinates.length">
          <q-separator class="q-my-md" />
          <div class="text-h6">For Delivery</div>
          <p>
            <small
              v-text="
                `*Delivery Available for ${coordinates.length} ${
                  coordinates.length > 1 ? 'Stores' : 'Store'
                }`
              "
            >
            </small>
            &nbsp;
            <!-- <small>*Shipping Unavailable </small> -->
          </p>
        </div>
        <GoogleMapRadius
          class="location-radius-map full-width"
          :coordinates="coordinates"
        />
      </template>
    </div>
  </div>
</template>
<script>
import GoogleAutoComplete from 'components/common/GoogleAutoComplete'
import GoogleMapRadius from 'src/components/common/GoogleMapRadius'
import { mapGetters, mapActions } from 'vuex'

let addressTypeMaper = {
  street_number: {
    get: 'short_name',
    set: 'line1',
  },
  route: {
    get: 'long_name',
    set: 'line1',
  },
  locality: {
    get: 'long_name',
    set: 'city',
  },
  administrative_area_level_1: {
    get: 'short_name',
    set: 'state',
  },
  sublocality_level_1: {
    get: 'long_name',
    set: 'line2',
  },
  sublocality_level_2: {
    get: 'long_name',
    set: 'line2',
  },
  sublocality_level_3: {
    get: 'long_name',
    set: 'line2',
  },
  postal_code: { get: 'short_name', set: 'zip' },
}

export default {
  name: 'AddressField',
  props: {
    addressFieldChange: {
      type: Function,
      default: () => {},
    },
    addressObj: {
      type: Object,
      default: null,
    },
    resetForm: {
      type: Function,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    cordinates: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      blnIsDefault: this.addressObj.markAsDefault,
      coordinates: [],
    }
  },
  components: {
    GoogleAutoComplete,
    GoogleMapRadius,
  },
  watch: {
    cordinates: {
      handler: function (newVal, oldVal) {
        this.coordinates = newVal || []
      },
    },
    deep: true,
  },
  computed: {
    ...mapGetters('address', ['alertData']),
    addAddressAlert() {
      return this.alertData('addAddressAlert')
    },
  },
  methods: {
    ...mapActions('location', ['getAvailableNearByStoreDetails']),

    resetAddressAlert() {
      this.$store.commit('address/SET_ADDRESS_ALERT', {
        alertKey: 'addAddressAlert',
        alertData: null,
      })
    },
    async getAddressData({ addressData, geoLocation, place_id }) {
      Object.keys(addressTypeMaper).forEach((key) => {
        this.addressFieldChange(addressTypeMaper[key].set, '')
      })

      // this.addressFieldChange('line2', '')

      addressData.forEach((addressComponents) => {
        const addressType = addressComponents.types[0]
        if (addressTypeMaper[addressType]) {
          const val = addressComponents[addressTypeMaper[addressType]['get']]

          this.addressFieldChange(
            addressTypeMaper[addressType]['set'],
            this.addressObj[addressTypeMaper[addressType]['set']]
              ? `${
                  this.addressObj[addressTypeMaper[addressType]['set']]
                } ${val}`
              : val
          )
        }
      })

      if (geoLocation && geoLocation.latitude && geoLocation.longitude) {
        const geoLocationDetails = `${geoLocation.latitude},${geoLocation.longitude}`
        const result = await this.getAvailableNearByStoreDetails({
          geoLocation: geoLocationDetails,
          currentStorelocationID: this.currentLocationId,
        })

        if (result.data) {
          this.coordinates = result.data.map((item) => {
            const lat = parseFloat(item.geoLocation.split(',')[0])
            const lng = parseFloat(item.geoLocation.split(',')[1])

            return {
              locationName: item.locationName,
              geoLocation: { lat, lng },
            }
          })
        }

        this.addressFieldChange('geoLocation', geoLocationDetails)
      }
    },
  },
  destroyed() {
    if (this.addAddressAlert) this.resetAddressAlert()
  },
}
</script>
<style lang="scss">
.location-radius-map {
  .q-expansion-item__content {
    border: 1px solid $primary;
    border-radius: 7px;
    overflow: hidden;
    padding: 4px;
  }
  .vue-map-container {
    height: 300px !important;
  }
}
</style>
