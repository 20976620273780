<template>
  <q-dialog
    v-model="dialogState"
    maximized
    :position="isMobile ? 'right' : 'bottom'"
    @hide="onHide"
  >
    <!-- @show="onShow" -->
    <q-card
      v-if="discount"
      class="triggerDiscount"
      :style="[
        isMobile
          ? { height: '100vh !important' }
          : { height: 'calc(100vh - 99px) !important' },
      ]"
    >
      <div class="dialogHeader">
        <q-btn class="close" flat dense v-close-popup v-if="!isMobile">
          <SvgIcon icon="close" />
        </q-btn>
        <div class="container-fluid">
          <div class="row q-col-gutter-md">
            <div class="col-12 col-sm-12 col-md-4">
              <h4 class="text-h5 text-secondary q-mt-none q-mb-md ellipsis">
                <q-btn v-if="isMobile" flat dense>
                  <SvgIcon
                    color="white"
                    className="q-mr-xs"
                    size="1rem"
                    icon="back-arrow-line"
                    v-close-popup
                  />
                </q-btn>
                {{ discount.discountName }}
              </h4>
            </div>
            <div class="col-12 col-sm-12 col-md-4 offset-md-4" v-if="!isMobile">
              <div class="form-field pb-0">
                <q-input
                  outlined
                  label="Search Here"
                  v-model="searchKeyword"
                  debounce="500"
                  @input="refreshListing"
                >
                  <template v-slot:prepend>
                    <q-icon name="search" />
                  </template>
                </q-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="q-px-md">
        <div class="row q-col-gutter-md" :class="isMobile ? 'q-pt-md' : ''">
          <!--<div class="col-12 col-sm-6 col-md-4">
             <p :class="isMobile ? 'q-pt-md q-mb-sm ellipsis-2-lines' : ''">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna.
            </p>
          </div>-->
          <div class="col-12 col-sm-12 col-md-4 offset-md-4" v-if="isMobile">
            <div class="form-field pb-0">
              <q-input
                outlined
                label="Search Here"
                v-model="searchKeyword"
                debounce="500"
                @input="refreshListing"
              >
                <template v-slot:prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </div>
          </div>
          <div class="col-12">
            <q-scroll-area
              class="triggerProductHeight"
              :thumb-style="scrollAreaThumbStyle"
              :bar-style="scrollAreaBarStyle"
              :visible="scrollAreaVisible"
              style="max-width: 100%"
              ref="productListing"
            >
              <div class="triggerDialogItems">
                <!-- <template v-if="isOpen"> -->
                <q-infinite-scroll
                  debounce="500"
                  ref="infiniteScroll"
                  class="product-items"
                  @load="fetchNext"
                  :offset="500"
                >
                  <ProductCard
                    v-for="(product, index) in products"
                    :key="`${product.productID}-${index}`"
                    :product="product"
                    :parentDialog="dialog"
                    :applyCoupon="applyCoupon"
                    :addToCartButton="addToCartButton"
                    :orderId="orderId"
                  />
                  <template v-slot:loading>
                    <LoadMoreSpinner v-show="!showListingSkeleton" />
                    <ProductSkeleton :size="12" v-show="showListingSkeleton" />
                  </template>
                </q-infinite-scroll>

                <EmptyComponent
                  :imgWidth="180"
                  v-if="!loading && Object.keys(products).length == 0"
                  :image="`images/empty-product.png`"
                >
                  <p>No products found</p>
                </EmptyComponent>
                <!-- </template> -->
              </div>
            </q-scroll-area>
          </div>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import ProductSkeleton from 'components/category/ProductSkeleton'
import ProductCard from 'components/ProductCard'
import { mapGetters } from 'vuex'
function initialState() {
  return {
    dialog: 'TriggerListDialog',
    showListingSkeleton: false,
    products: [],
    loading: false,
    searchKeyword: '',
    timer: null,
    // isOpen: true,
  }
}

export default {
  name: 'TriggerListDialog',
  data: initialState,
  components: { ProductSkeleton, ProductCard },
  computed: {
    ...mapGetters('common', ['currentDeliveryModes']),
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val: val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    discount() {
      return this.properties?.discount
    },
    applyCoupon() {
      return !!this.properties?.applyCoupon
    },
    addToCartButton() {
      return this.properties?.addToCartButton
    },
    orderId() {
      return this.properties?.orderId
    },
    objListRequest() {
      return {
        PageSize: 20,
        searchKeyword: this.searchKeyword,
        locationId: this.currentLocationId,
        ...this.currentDeliveryModes,
      }
    },
  },
  methods: {
    // onShow() {
    //   this.isOpen = true
    // },
    onHide() {
      Object.assign(this.$data, initialState())
    },
    fetchNext(index, done) {
      let params = {
        page: index,
        ...this.objListRequest,
      }
      if (index == 1) {
        this.showListingSkeleton = true
        this.products = []
      }
      this.loading = true

      this.$store
        .dispatch('discounts/getTriggerProductList', {
          discountId: this.discount.discountID,
          params,
        })
        .then((response) => {
          if (response?.data?.success && response?.data?.data) {
            const { data } = response.data

            if (data.index === 1) {
              this.products = data.items
              this.scrollToPosition(this.$refs.productListing, 0, 100)
            } else this.products = [...data.items]

            done(!data.hasNext)
          } else done(true)
        })
        .catch((e) => {
          done(true)
        })
        .finally(() => {
          this.loading = false
          this.showListingSkeleton = false
        })
    },
    refreshListing() {
      if (this.$refs?.infiniteScroll) {
        this.loading = true
        this.loadingSkeleton = true
        this.$refs.infiniteScroll.stop()
        this.$refs.infiniteScroll.reset()
        this.$refs.infiniteScroll.resume()
        this.$refs.infiniteScroll.trigger()

        this.scrollToPosition(this.$refs.productListing, 0, 100)
      }
    },
  },
}
</script>

<style lang="scss">
.triggerDiscount {
  padding: 0;
  position: relative;
  width: 100vw !important;
  .dialogHeader {
    padding: 30px 70px 15px 25px;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      font-size: 8px;
    }
    h4.text-h5 {
      margin: 0;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    @media (max-width: 1023px) {
      padding: 15px 15px;
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: $white;
      color: var(--q-color-secondaryText) !important;
      position: relative;
      z-index: 9;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      h4.text-h5 {
        padding-right: 60px;
        font-size: 16px;
        color: #fff !important;
        text-align: left;
      }
      .close {
        width: 70px;
        height: 70px;
      }
    }
  }
}

.triggerDialogItems {
  .product-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    .product-items--card {
      width: calc(50% - 10px);
      @media (min-width: 600px) {
        width: calc(33.33% - 10px);
      }
      @media (min-width: 1024px) {
        width: calc(25% - 10px);
      }
      @media (min-width: 1200px) {
        width: calc(20% - 10px);
      }
      @media (min-width: 1440px) {
        width: calc(16.66% - 10px);
      }
      @media (min-width: 1600px) {
        width: calc(16.66% - 10px);
      }
      @media (min-width: 1920px) {
        width: calc(14.28% - 10px);
      }
    }
  }
}

.triggerProductHeight {
  padding-right: 12px;
  height: calc(100dvh - 160px);
  @media (min-width: 1024px) {
    height: calc(100dvh - 270px);
  }
}
</style>
