<template>
  <div class="avForm">
    <div class="row q-col-gutter-md justify-center">
      <div class="col-12">
        <div class="ageField">
          <div class="box"></div>
          <div class="box ageBox">21<span>+</span></div>
          <div class="box"></div>
        </div>
      </div>
      <div class="col-12">
        <div class="text-h5 text-weight-bold"> Age Verification </div>
        <div class="text-subtitle1 text-grey-9 text-weight-bold">
          We take Age Verification seriously.
        </div>
      </div>
      <div class="col-12">
        <div
          class="text-body1 text-dark q-mb-sm avFormText"
          v-if="businessConfigurations?.enableAgeRestrictedBrowsing"
        >
          This website contains <strong>age-restricted</strong> products, and
          age verification is required by law to show you
          <strong>age-restricted</strong> products. All purchases are digitally
          age-verified using
          <span
            class="avHighlight cursor-pointer"
            @click="openIdentityURL('https://ftxidentity.com/')"
          >
            FTx Identity
          </span>
          instantly. Your information will only be used to verify your age. In
          certain states, age verification or an adult signature upon
          delivery/pickup is also required.

          <template v-if="!isLoggedIn">
            If you have already verified in the past and have login information
            available, please choose the "<strong>Sign In</strong>" option
            below. You may skip the verification to continue exploring
            <strong>non-age-restricted</strong> products. <br /><br />
            <strong> How would you like to proceed? </strong>
          </template>
        </div>
        <div v-else class="text-body1 text-dark q-mb-sm avFormText">
          This website contains <strong>age-restricted</strong> products, and
          age verification is required by law to sell
          <strong>age-restricted</strong> products. All purchases are digitally
          age-verified using
          <span
            class="avHighlight cursor-pointer"
            @click="openIdentityURL('https://ftxidentity.com/')"
          >
            FTx Identity
          </span>
          instantly. Your information will only be used to verify your age. In
          certain states, age verification or an adult signature upon
          delivery/pickup is also required.
          <br /><br />
          <strong> Are you over 21? </strong>
        </div>
      </div>
      <div
        class="col-12"
        :class="
          businessConfigurations?.enableAgeRestrictedBrowsing
            ? 'col-md-10 col-lg-9'
            : 'col-md-12 col-lg-12'
        "
      >
        <div
          class="row"
          :class="$q.screen.lt.md ? 'q-col-gutter-sm' : 'q-col-gutter-md'"
          v-if="businessConfigurations?.enableAgeRestrictedBrowsing"
        >
          <template v-if="!isLoggedIn">
            <div class="col-6">
              <div class="form-action pb-0">
                <q-btn
                  unelevated
                  no-caps
                  color="secondary"
                  label="Let's Verify"
                  class="full-width"
                  :class="$q.screen.lt.md ? '' : 'q-btn-md'"
                  @click="triggerEAIVFlow"
                  :loading="loading"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-action pb-0">
                <q-btn
                  unelevated
                  no-caps
                  outline
                  color="tertiary"
                  label="Sign In"
                  class="full-width"
                  :class="$q.screen.lt.md ? '' : 'q-btn-md'"
                  @click="actionLogin(false)"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-action pb-0">
                <q-btn
                  unelevated
                  no-caps
                  v-close-popup
                  flat
                  color="secondary"
                  label="Skip"
                  padding="4px 15px"
                  class="text-underline no-hover"
                  :class="$q.screen.lt.md ? '' : 'q-btn-md'"
                  @click="hidePage()"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-12">
              <div class="form-action pb-0">
                <q-btn
                  unelevated
                  no-caps
                  color="secondary"
                  label="Let's Verify"
                  class="full-width"
                  :class="$q.screen.lt.md ? '' : 'q-btn-md'"
                  @click="triggerEAIVFlow"
                  :loading="loading"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-action pb-0">
                <q-btn
                  unelevated
                  no-caps
                  v-close-popup
                  flat
                  color="secondary"
                  label="Skip"
                  padding="4px 15px"
                  class="text-underline no-hover"
                  :class="$q.screen.lt.md ? '' : 'q-btn-md'"
                  @click="hidePage()"
                />
              </div>
            </div>
          </template>
        </div>
        <div
          v-else
          class="row"
          :class="$q.screen.lt.md ? 'q-col-gutter-sm' : 'q-col-gutter-md'"
        >
          <div class="col-6">
            <div class="form-action pb-0">
              <q-btn
                unelevated
                no-caps
                color="secondary"
                class="full-width"
                :padding="!hideAgeRestrictedText ? '8px 15px' : '23px 15px'"
                :class="[
                  $q.screen.lt.md ? '' : 'q-btn-md',
                  !hideAgeRestrictedText ? '' : 'age21plus',
                ]"
                @click="hidePage(false)"
              >
                <!--   -->
                I am 21+
                <span
                  class="text-caption line-height-normal"
                  v-if="!hideAgeRestrictedText"
                >
                  {{ restrictedFulfillmentText }}
                </span>
              </q-btn>
            </div>
          </div>
          <div class="col-6">
            <div class="form-action pb-0">
              <q-btn
                unelevated
                no-caps
                outline
                color="tertiary"
                class="full-width"
                padding="8px 15px"
                :class="$q.screen.lt.md ? '' : 'q-btn-md'"
                @click="hidePage(true)"
                v-close-popup
              >
                Skip, I am not 21+
                <span class="text-caption line-height-normal">
                  I understand I can't see age-restricted products.
                </span>
              </q-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IdentityMixin from 'src/utils/IdentityMixin'
import { reloadPage } from 'src/utils'

export default {
  name: 'AgeVerificationInfo',
  mixins: [IdentityMixin],
  props: ['toggleDialog'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('customer', ['customer']),
    ...mapGetters('address', ['getAddressByCustomerAddressID']),
    ...mapGetters('persisted', ['eaivPersistedSessionId']),
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
    hideAgeRestrictedText() {
      //Enable Age Verification
      return (
        this.businessConfigurations?.isStoreAgeRestricted &&
        !this.businessConfigurations?.enableAgeRestrictedBrowsing &&
        !this.businessConfigurations?.enableAgeRestrictedCheckout &&
        !this.businessConfigurations?.enableAgeRestrictedFulfillment
      )
    },
    restrictedFulfillmentText() {
      let result =
        this.businessConfigurations?.isStoreAgeRestricted &&
        !this.businessConfigurations?.enableAgeRestrictedBrowsing &&
        !this.businessConfigurations?.enableAgeRestrictedCheckout &&
        this.businessConfigurations?.enableAgeRestrictedFulfillment

      return `I understand my age will be verified during ${
        result ? 'fulfillment' : 'checkout'
      }.`
    },
  },
  mounted() {
    this.setHeaderHeight()
  },
  methods: {
    triggerEAIVFlow() {
      let reqData = {
        collectPersonalInfo: true,
        redirectUri: this.getRedirectUri,
        verificationDuring: 0,
        customerID: null,
      }
      if (this.isLoggedIn && !this.customer?.isVerifiedOnline) {
        reqData.preFillInformation = this.personalInfoObj()
      }

      this.generateSession(reqData, !!!this.isCordova)
    },
    hidePage(skipBrowsingAgeRestrictedProd = null) {
      if (skipBrowsingAgeRestrictedProd !== null) {
        this.$store.commit('ageVerification/SET_FIELD', {
          field: 'skipBrowsingAgeRestrictedProd',
          value: !!skipBrowsingAgeRestrictedProd,
        })

        if (!skipBrowsingAgeRestrictedProd && this.toggleDialog) {
          reloadPage()
        }
      }

      this.$store.commit('persisted/SET_SHOW_EAIV_PAGE', false)
      if (this.toggleDialog) this.toggleDialog()
    },
    navigateToSignup() {
      this.$router.push({ name: 'SignUp' }).finally(() => this.hidePage())
    },
    actionLogin(isSignup) {
      this.$store
        .dispatch('auth/signIn', {
          isSignup,
        })
        .then((user) => {
          if (this.isCordova && user) {
            this.$router.push({ name: 'Callback' })
          }
        })
        .catch((error) => console.log(error))
      // .finally(() => this.hidePage())
    },
    openIdentityURL(url) {
      if (this.isCordova) {
        window.open(url, '_system')
      } else {
        window.open(url, '_blank')
      }
    },
    openURL(url) {
      if (this.isCordova) {
        window.open(url, '_system')
      } else {
        window.location = url
      }
    },
    generateSession(reqData, isPopup = false) {
      this.loading = true
      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (isPopup || (!this.isCordova && 'FTXIdentityLite' in window)) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: this.identitySuccessHandler,
                onError: this.identityErrorHandler,
                styleOptions: {
                  height: this.showEAIVPage
                    ? '100dvh'
                    : `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: this.showEAIVPage ? '0px' : `${this.getHeaderHeight}px`,
                },
              }
              this.initEAIVPopup(payloadObj)
            } else if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    personalInfoObj() {
      let address = null,
        dob = '',
        personalInfo = {}
      if (this.isLoggedIn) {
        address = this.getAddressByCustomerAddressID(
          this.customer.defaultAddressID
        )

        dob = this.currentUser.dob
          ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
          : ''
        personalInfo = {
          firstName: this.currentUser.firstName || '',
          lastName: this.currentUser.lastName || '',
          dob: dob,
          addressLine1: address?.line1 || '',
          addressLine2: address?.line2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zip: address?.zip || '',
          phone: this.currentUser.phoneNumber || '',
          email: this.currentUser.email || '',
          countryCode: this.currentUser.countryCode || '+1',
        }
      }
      return personalInfo
    },
  },
}
</script>

<style lang="scss">
.avHighlight {
  position: relative;
  padding-left: 3px;
  padding-right: 3px;
  display: inline-block;
  font-weight: bold;
  border-bottom: 2px solid $secondary;
  border-bottom-color: var(--q-color-secondary);
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.15;
    background: $secondary;
    background: var(--q-color-secondary);
  }
}

.avForm {
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  padding: 30px;
}
</style>
