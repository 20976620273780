<template>
  <div class="cartItemRemove">
    <q-btn
      no-caps
      @click="deleteCartItem()"
      unelevated
      class="no-hover"
      :loading="deleting"
      :padding="!isText ? '' : '0'"
    >
      <SvgIcon icon="trash" size="1rem" v-if="!isText" />
      <template v-else>Delete</template>
    </q-btn>
  </div>
</template>

<script>
export default {
  name: 'TrashCart',
  props: {
    orderItemId: {
      type: String,
      default: null,
    },
    isText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleting: false,
    }
  },
  methods: {
    deleteCartItem() {
      this.deleting = true

      this.$store
        .dispatch('cart/deleteCartItem', {
          orderItemId: this.orderItemId,
        })
        .finally(() => {
          this.deleting = false
        })
    },
  },
}
</script>
