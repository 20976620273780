<template>
  <q-dialog v-model="dialogState" position="right" maximized persistent>
    <q-card class="addPaymentDialogue">
      <q-card-section>
        <q-form @submit="handleAddressAdd" ref="addressForm">
          <div class="topHeader">
            <h3> Add Billing Address </h3>
            <q-btn
              unelevated
              flat
              round
              icon="close"
              @click="closeDialog"
              class="close"
            />
          </div>
          <div class="middle">
            <q-scroll-area
              class="addPaymentHeight"
              :thumb-style="scrollAreaThumbStyle"
              :bar-style="scrollAreaBarStyle"
              :visible="scrollAreaVisible"
              ref="paymentScrollArea"
              :style="[{ maxWidth: '100%' }]"
            >
              <div class="row q-col-gutter-lg">
                <div class="col-xs-12 col-md-12">
                  <AddressField
                    :addressFieldChange="addressFieldChange"
                    :addressObj="billingAddress"
                  />
                </div>
              </div>
            </q-scroll-area>
          </div>
          <div class="bottom">
            <q-btn
              unelevated
              no-caps
              rounded
              outline
              color="tertiary"
              label="Cancel"
              @click="closeDialog"
              class="q-ml-sm q-mr-sm q-px-md q-py-xs"
            />
            <q-btn
              unelevated
              no-caps
              rounded
              color="secondaryOnBody"
              label="Save"
              class="q-ml-sm q-mr-sm q-px-md q-py-xs"
              type="submit"
              :loading="loading"
            />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
function initialState() {
  return {
    loading: false,
    billingAddress: {
      firstName: '',
      lastName: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: 'US',
      zip: '',
      phone: '',
      type: 1,
    },
    isChangesUnsaved: false,
  }
}
import AddressField from 'components/address/AddressField'

export default {
  name: 'AddBillingAddress',
  components: { AddressField },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState('AddBillingAddress')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'AddBillingAddress',
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties('AddBillingAddress')
    },
  },
  data: function () {
    return initialState()
  },
  methods: {
    async handleAddressAdd() {
      this.loading = true

      let reqData = { ...this.billingAddress }

      if (reqData.phone)
        reqData.phone = this.unmask(reqData.phone, this.phoneMask)
      if (this.properties.customerID)
        reqData.customerID = this.properties.customerID

      let data = await this.$store.dispatch(
        'address/addOrUpdateAddress',
        reqData
      )

      if (data) {
        if (this.$root)
          this.$root.$emit('address-added-or-modified', data.address || data)
      }

      this.dialogState = false

      Object.assign(this.$data, initialState())

      setTimeout(() => {
        this.isChangesUnsaved = false
      }, 200)
    },
    addressFieldChange(field, val) {
      this.billingAddress[field] = val
    },
    openConfirmationPopup() {
      const confirmationDialogTitle = 'Save Before Closing ?'
      const confirmationDialogDescription = `It looks like you're trying to close the tab before saving your changes.<br />Would you like to save or discard these changes?`
      const cancelBtnLabel = 'Discard and Close'
      const confirmBtnLabel = 'Save and Close'
      const closeBtn = false
      const outsideClickCloseDisable = true

      this.ftxConfirm(
        confirmationDialogTitle,
        confirmationDialogDescription,
        cancelBtnLabel,
        confirmBtnLabel,
        closeBtn,
        outsideClickCloseDisable
      )
        .onOk(() => {
          this.$refs.addressForm.validate().then((success) => {
            if (success) this.handleAddressAdd()
          })
        })
        .onCancel(() => {
          Object.assign(this.$data, initialState())

          this.dialogState = false

          setTimeout(() => {
            this.isChangesUnsaved = false
          }, 200)
        })
    },
    closeDialog() {
      if (!this.isChangesUnsaved) this.dialogState = false
      else this.openConfirmationPopup()
    },
  },
  watch: {
    billingAddress: {
      handler(newVal) {
        if (newVal) {
          this.isChangesUnsaved = true
        }
      },
      deep: true,
    },
  },
}
</script>
